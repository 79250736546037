import axios from 'axios'

const auth = localStorage.getItem('token')

export const axiosInstance = axios.create({
    baseURL:
        process.env.REACT_APP_MODE === 'development'
            ? process.env.REACT_APP_LOCAL_URL
            : process.env.REACT_APP_PROD_URL,
    headers: { 'Content-Type': 'multipart/form-data' },
})

axiosInstance.interceptors.request.use((config) => {
    if (auth) {
        config.headers.Authorization = `Bearer ${JSON.parse(auth)}`
    }
    return config
})

axiosInstance.interceptors.response.use((response) => response)
