import React, { Fragment, useRef, useState } from 'react'
import NexusLogo from '../../assets/NexusBrain.png'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import ChatroomExport from './KIQSExportComponents/ChatroomExport'
import MyKIQSExport from './KIQSExportComponents/MyKIQSExport'
import SnapIntelExport from './KIQSExportComponents/SnapIntelExport'
import { DownloadProgress } from 'components/DownloadingComponent'

const ExportCompaniesKIQs = ({ event, data, type, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()
    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }

    const makeUrlsClickable = (text) => {
        if (!text) {
            return { __html: '' } // Return an empty string or handle the null case accordingly
        }

        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g

        // Replace matched URLs with clickable <a> tags
        const replacedText = text.replace(
            urlRegex,
            (url) =>
                `<a class="hover:text-blue-600 hover:underline font-bold whitespace-normal break-all  max-w-[100px]" href="${url}" target="_blank"><br/> <br/> ${url} <br/> <br/></a>`
        )

        return { __html: replacedText }
    }

    return (
        <div className=" top-0 bottom-0  right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex items-center justify-between ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                        >
                            {' '}
                            Download PDF{' '}
                        </button>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={() => onClose()}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                fileName={`Export Companies - ${event?.event_name} - ${
                    type === 0
                        ? 'Chatroom'
                        : type === 1
                        ? 'My KIQs'
                        : type === 2
                        ? 'SnapIntel'
                        : null
                }`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                <div className="w-full flex flex-col px-4 py-2 ">
                    <div className=" pt-2 px-5 text-main flex gap-5 items-center">
                        <div>
                            <img src={NexusLogo} alt="NexusLogo" className="w-10" />
                        </div>
                        <div>
                            <p className="text-md h-fit font-bold">{event?.event_name}</p>
                            <div>
                                {type === 0 ? (
                                    <p>Chatroom Export</p>
                                ) : type === 1 ? (
                                    <p>My KIQs Export</p>
                                ) : type === 2 ? (
                                    <p>SnapIntel Export</p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full">
                        {type === 0 ? (
                            <ChatroomExport convo={data} />
                        ) : type === 1 ? (
                            <MyKIQSExport convo={data} />
                        ) : type === 2 ? (
                            <SnapIntelExport convo={data} />
                        ) : null}
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

export default ExportCompaniesKIQs
