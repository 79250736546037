import React, { useEffect } from 'react'
import { IoCloseCircleOutline } from 'react-icons/io5'

const ImageView = ({ image, onClose }) => {
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        const handleClickOutside = (event) => {
            const modal = document.getElementById('image-modal')
            if (modal && !modal.contains(event.target)) {
                onClose()
            }
        }

        // Attach event listeners when the component mounts
        window.addEventListener('keydown', handleKeyPress)
        window.addEventListener('click', handleClickOutside)

        // Detach event listeners when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
            window.removeEventListener('click', handleClickOutside)
        }
    }, [onClose])

    return (
        <div className="z-50 w-screen h-screen fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center animate_zoom_in">
            <div className="fixed top-10 right-10">
                <button
                    onClick={onClose}
                    className=" transition-all duration-300 text-4xl text-white hover:scale-105"
                >
                    <IoCloseCircleOutline />
                </button>
            </div>
            <div className=" w-4/6 flex justify-center items-center">
                <img
                    className="cursor-pointer hover:scale-105 transition-all duration-300 h-fit max-h-[70dvh] shadow-md"
                    src={image}
                    alt={image}
                />
            </div>
            <div className="fixed bottom-10 right-10 ">
                <p className="w-fit whitespace-normal flex justify-center items-center text-darkgrey">
                    Note: Click{' '}
                    <span className="mx-2">
                        <IoCloseCircleOutline />
                    </span>{' '}
                    or [ESC] key to close.
                </p>
            </div>
        </div>
    )
}

export default ImageView
