import React, { useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import LoadingImage from '../../../assets/NexusBrain.png'
import DataTable from 'react-data-table-component'
import { LoadingComponent } from 'components/LoadingComponent'

export const Users = ({ searchUsers, setUsersContainer, setTabs }) => {
    const [display, setDisplay] = useState(false)
    const [userHolder, setUserHolder] = useState()
    const columns = [
        {
            name: 'Name',
            selector: (row) =>
                row.firstname + ` ${row.lastname && row.lastname != null ? row.lastname : ''}`,
            cell: (row) => (
                <div className="cursor-pointer" onClick={() => handleRowClicked(row)}>
                    <span
                        className={`${
                            userHolder === row && 'font-bold underline text-main '
                        } hover:underline `}
                    >
                        {' '}
                        {row.firstname +
                            ` ${row.lastname && row.lastname != null ? row.lastname : ''}`}
                    </span>
                </div>
            ),
        },
        {
            name: 'Email Address',
            selector: (row) => row.email,
            cell: (row) => (
                <div className="cursor-pointer" onClick={() => handleRowClicked(row)}>
                    <span
                        className={`${
                            userHolder === row && 'font-bold underline text-main'
                        } hover:underline `}
                    >
                        {' '}
                        {row.email}
                    </span>
                </div>
            ),
        },
    ]

    const handleRowClicked = (row) => {
        setUsersContainer(row)
        setUserHolder(row)
        setTabs(6)
    }

    return (
        <div className="w-full">
            <button
                onClick={() => {
                    setDisplay(!display)
                    setUsersContainer(searchUsers[0])
                    setTabs(6)
                }}
                disabled={searchUsers?.length == 0 && true}
                className="transition-all duration-200 cursor-pointer flex justify-between p-4 w-full bg-[#397637] text-white rounded-md shadow-md animate_fade_up relative hover:bg-main z-10"
            >
                <div className="flex items-center">
                    <span className="text-xl">
                        <BiChevronDown />
                    </span>
                    <p>Users</p>
                </div>
                {searchUsers ? (
                    <p className="border-white border px-2 rounded-full font-bold shadow-md">
                        <React.Fragment>{searchUsers?.length ?? 0} </React.Fragment>
                    </p>
                ) : (
                    <img
                        src={LoadingImage}
                        className="w-[25px] animate_slow_spin brightness-200 "
                    />
                )}
            </button>
            {display && (
                <React.Fragment>
                    <div className="transition-all duration-200  w-full bg-white shadow-md pt-4 pb-2 px-4 -mt-5 rounded-md animate_fade_up">
                        <DataTable
                            progressComponent={<LoadingComponent title={'Users'} />}
                            progressPending={!searchUsers}
                            data={searchUsers}
                            columns={columns}
                            fixedHeader
                            fixedHeaderScrollHeight="300px"
                            pagination
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export const UsersDisplay = ({ isLoading, dataContainer }) => {
    return (
        <div className="h-full bg-white rounded-b-lg grow flex flex-col min-h-[300px] p-10 ">
            <p className="text-4xl text-main font-bold">
                {dataContainer.firstname + ' ' + dataContainer.lastname}
            </p>
            <p className="text-lg text-main font-bold">Company : Nexbridge</p>
            <div className="gap-4 flex flex-col pt-5">
                <div className="w-full ">
                    <p className="text-xl text-main font-bold">
                        Groups of {dataContainer.firstname + ' ' + dataContainer.lastname}
                    </p>
                    <ul className="pl-10 grid grid-cols-3">
                        {/* <li className="text-darkgrey list-decimal">Microsoft</li> */}
                        {dataContainer.groups &&
                            dataContainer.groups.map((group, groupIndex) => (
                                <li key={groupIndex} className="text-darkgrey list-decimal">
                                    {group.group_name}
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="w-full">
                    <p className="text-xl text-main font-bold">Events Assigned</p>
                    <ul className="pl-10 grid grid-cols-2 w-full">
                        {/* <li className="text-darkgrey list-decimal flex-wrap px-4 hover:text-main hover:underline-offset-2 hover:underline cursor-pointer hover:font-semibold">
                            Microsoft's Acquisition of LinkedIn
                        </li> */}
                        {/* {dataContainer.groups[0].events &&
                            dataContainer.groups[0].events.map((event, eventIndex) => (
                                <li className="text-darkgrey list-decimal flex-wrap px-4 hover:text-main hover:underline-offset-2 hover:underline cursor-pointer hover:font-semibold">
                                    {event.event_name}
                                </li>
                            ))} */}
                        {dataContainer.groups &&
                            dataContainer.groups.map((group, groupIndex) => (
                                <div className="w-full " key={groupIndex}>
                                    {group.events &&
                                        group.events.map((event, eventIndex) => (
                                            <li
                                                className="list-decimal whitespace-normal px-5 text-darkgrey "
                                                key={eventIndex}
                                            >
                                                {event?.event_name}
                                            </li>
                                        ))}
                                </div>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
