import React, { useState } from 'react'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'

function QuestionChatroom({ dataContainer }) {
    const [collapse, setCollapse] = useState(true)
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        <div className="w-full flex flex-col ">
            <div className="w-full bg-main py-2 px-2 flex justify-between items-center rounded-t-md">
                <p className="font-semibold text-white">Chatroom</p>
                <p className="font-semibold text-white text-xs">Company : Company Name</p>
            </div>
            <div className="w-full h-full max-h-[350px] overflow-y-auto px-5 flex flex-col gap-3 bg-gray-200 py-5 rounded-b-md">
                <div className="flex w-full items-center gap-2 px-2 ">
                    <div className="bg-main text-center aspect-square rounded-full pt-1 w-8 h-8 text-white">
                        Q
                    </div>
                    <div className="text-xs bg-main bg-opacity-30 p-2 rounded-r-md rounded-tl-md shadow-sm">
                        {dataContainer.question}
                    </div>
                </div>

                <div className="w-full flex flex-col gap-2">
                    <div className="px-10 flex gap-2">
                        <span className="rotate-90 text-xl">
                            <MdOutlineSubdirectoryArrowLeft />
                        </span>
                        <p
                            className="font-bold text-sm cursor-pointer"
                            onClick={() => setCollapse(!collapse)}
                        >
                            Show ({dataContainer.responses.length}) responses
                        </p>
                    </div>
                    {collapse && (
                        <React.Fragment>
                            <div className="flex flex-col w-full items-end justify-end gap-2 ml-5 px-6 animate_fade_up">
                                {/* <p className="text-xs font-bold">John Doe</p> */}
                                <div className="flex flex-col gap-2 w-3/4 items-end justify-end">
                                    {dataContainer.responses &&
                                        dataContainer.responses.map((response, responseIndex) => (
                                            <React.Fragment>
                                                <p className="text-xs font-bold">
                                                    {response.user.fullname}
                                                </p>
                                                <p
                                                    className="text-sm text-justify break-words"
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {response.answer &&
                                                        makeUrlsClickable(response.answer).map(
                                                            (part, index) => (
                                                                <React.Fragment key={index}>
                                                                    {part}
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                </p>
                                            </React.Fragment>
                                        ))}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default QuestionChatroom
