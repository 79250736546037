import { ChartTab } from 'components/tabs/ChartTab'
import GeneralTab from 'components/tabs/GeneralTab'
import MapTab from 'components/tabs/MapTab'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

export const EventDetails = ({ selectedEvent, isEventLoading, result }) => {
    const [tab, setTab] = useState(0)

    const tabsButton = [
        {
            label: 'General',
        },
        {
            label: 'Map',
        },
        {
            label: 'Analytics',
        },
    ]

    const SwitchTabs = () => {
        switch (tab) {
            case 0:
                return <GeneralTab data={selectedEvent} noData={!selectedEvent} />
            case 1:
                return <MapTab dataContainer={selectedEvent} />
            case 2:
                return <ChartTab dataContainer={selectedEvent} noData={!selectedEvent} />

            default:
                return null
        }
    }

    return (
        <div className="w-full">
            <div className="flex w-full gap-1 text-xs">
                {tabsButton.map((tabButton, index) => (
                    <div className="w-fit">
                        <button
                            onClick={() => setTab(index)}
                            className={twMerge(
                                ' transition-all duration-500 bg-white min-w-[80px]  w-fit shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl',
                                tab === index && 'bg-main text-white'
                            )}
                        >
                            {tabButton.label}
                        </button>
                    </div>
                ))}
            </div>

            {SwitchTabs()}
        </div>
    )
}
