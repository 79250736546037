import React, { useEffect } from 'react'
import '../../index.scss'

const GoogleCustomSearch = () => {
    useEffect(() => {
        const script = document.createElement('script')
        script.async = true
        script.src = 'https://cse.google.com/cse.js?cx=a0a91ad9e43404c9f'
        document.body.appendChild(script)

        return () => {
            document?.body?.removeChild(script)
        }
    }, [])

    return <div className="gcse-search"></div>
}

export default GoogleCustomSearch
