import React, { useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaFile } from 'react-icons/fa'
import NexusLogo from '../../assets/NexusBrain.png'
import { Link } from 'react-router-dom'
import { DownloadProgress } from 'components/DownloadingComponent'

export const ExportSpacesEvents = ({ data, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }
        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [])

    const makeUrlsClickable = (text) => {
        if (!text) {
            return { __html: '' } // Return an empty string or handle the null case accordingly
        }

        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g

        // Replace matched URLs with clickable <a> tags
        const replacedText = text.replace(
            urlRegex,
            (url) =>
                `<a class="hover:text-blue-600 hover:underline break-all font-bold" href="${url}" target="_blank"><br/> <br/> ${url} <br/> <br/></a>`
        )

        return { __html: replacedText }
    }

    return (
        <div className=" top-0 bottom-0  right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex items-center justify-between ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                        >
                            Download PDF
                        </button>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                fileName={`Export Spaces and Events - ${data?.event_name}`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                <div className="w-full flex flex-col gap-4 p-4 ">
                    <div className=" pt-2 px-5 text-main flex gap-5 items-center">
                        <div>
                            <img src={NexusLogo} alt="NexusLogo" className="w-10" />
                        </div>
                        <div>
                            <p className="text-md h-fit font-bold">{data?.event_name}</p>
                        </div>
                    </div>
                    <p className="w-[90%] text-xs"> {data?.description}</p>
                    <div className="w-1/2 sm:flex-col md:flex-col pb-3 border-main border-opacity-30 text-xs">
                        <p className="font-bold">Agents:</p>
                        <div className="flex w-full flex-col mb-2">
                            {data?.groups?.map((group, groupIndex) => {
                                return (
                                    <div key={groupIndex}>
                                        <p className="font-semibold text-main space-x-9   whitespace-nowrap">
                                            Group: {group?.group_name}
                                        </p>
                                        <div className="flex-col w-fit">
                                            {group.users &&
                                                group.users.map((user, userIndex) => {
                                                    return (
                                                        <p key={userIndex}>
                                                            {' '}
                                                            &nbsp; {user?.fullname}
                                                            {group.users.length - 1 > userIndex &&
                                                                ''}{' '}
                                                        </p>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="w-full border-t border-main border-opacity-40 pt-5">
                        <p className="text-main font-bold text-md w-full  justify-between flex">
                            General Discussion
                        </p>
                        <div className="w-full flex flex-col px-10 py-2 text-[.7rem] leading-4 gap-1">
                            {data?.general_discussions &&
                                data?.general_discussions?.map((item, itemIndex) => (
                                    <>
                                        <div
                                            key={item}
                                            className=" w-full border rounded-md px-2 py-1 flex flex-col"
                                        >
                                            {item?.question?.question && (
                                                <p className="text-main font-bold">
                                                    Question: {item?.question?.question}
                                                </p>
                                            )}
                                            <p className="text-main font-bold">
                                                {item.user.fullname} -{' '}
                                                <span className="font-semibold text-darkgrey">
                                                    {item.created_at}
                                                </span>
                                            </p>

                                            {/* <p>{item.answer}</p> */}
                                            <p
                                                dangerouslySetInnerHTML={makeUrlsClickable(
                                                    item.answer
                                                )}
                                            />
                                            {item.attachments && item.attachments.length > 0 && (
                                                <div>
                                                    {item.attachments.map(
                                                        (attachment, attachmentIndex) => (
                                                            <div
                                                                className="flex items-start justify-start w-fit "
                                                                key={attachment.id}
                                                            >
                                                                {attachment.type === 'image' ? (
                                                                    <div>
                                                                        <img
                                                                            alt={
                                                                                attachment.filename
                                                                            }
                                                                            src={
                                                                                attachment.filename
                                                                            }
                                                                            className="max-h-60"
                                                                        />
                                                                        <Link
                                                                            to={attachment.filename}
                                                                            key={attachment.id}
                                                                            target="_blank"
                                                                            className="font-bold flex"
                                                                        >
                                                                            Image Link :{' '}
                                                                            <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                Click to view
                                                                            </p>
                                                                        </Link>
                                                                    </div>
                                                                ) : attachment.type === 'file' ? (
                                                                    <Link
                                                                        to={attachment.filename}
                                                                        target="_blank"
                                                                        className="font-bold "
                                                                    >
                                                                        PDF Link :{' '}
                                                                        <span className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                            <span>
                                                                                <FaFile />
                                                                            </span>
                                                                            Go to link
                                                                        </span>
                                                                    </Link>
                                                                ) : null}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                        <div>
                                            {item && item.responses?.length > 0 && (
                                                <div>
                                                    <p> Replies : </p>
                                                    <div className="flex flex-col w-full ml-5 px-6 gap-1">
                                                        {item.responses.map((responses) => (
                                                            <div className="border rounded-md pt-1 pl-2">
                                                                <span className=" font-bold">
                                                                    {responses?.user?.fullname}
                                                                </span>
                                                                <div
                                                                    className=" flex flex-col w-fit bg-opacity-30  my-1 rounded-l-md rounded-tr-md shadow-sm"
                                                                    key={responses.id}
                                                                >
                                                                    {/* {responses.answer} */}
                                                                    <p
                                                                        dangerouslySetInnerHTML={makeUrlsClickable(
                                                                            responses.answer
                                                                        )}
                                                                    />

                                                                    {responses.attachments &&
                                                                        responses.attachments
                                                                            ?.length > 0 && (
                                                                            <React.Fragment>
                                                                                {responses.attachments.map(
                                                                                    (
                                                                                        attachment
                                                                                    ) => (
                                                                                        <div
                                                                                            className="flex "
                                                                                            key={
                                                                                                attachment.id
                                                                                            }
                                                                                        >
                                                                                            {attachment.type ===
                                                                                            'image' ? (
                                                                                                <div>
                                                                                                    <img
                                                                                                        alt={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        src={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        className="max-h-60"
                                                                                                    />
                                                                                                    <Link
                                                                                                        to={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                        key={
                                                                                                            attachment.id
                                                                                                        }
                                                                                                        className="font-bold flex"
                                                                                                    >
                                                                                                        Image
                                                                                                        Link
                                                                                                        :{' '}
                                                                                                        <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                            Click
                                                                                                            to
                                                                                                            view
                                                                                                        </p>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            ) : attachment.type ===
                                                                                              'file' ? (
                                                                                                <Link
                                                                                                    to={
                                                                                                        attachment.filename
                                                                                                    }
                                                                                                    target="_blank"
                                                                                                    className="font-bold "
                                                                                                >
                                                                                                    PDF
                                                                                                    Link
                                                                                                    :{' '}
                                                                                                    <span className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                                                        <span>
                                                                                                            <FaFile />
                                                                                                        </span>
                                                                                                        Go
                                                                                                        to
                                                                                                        link
                                                                                                    </span>
                                                                                                </Link>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </React.Fragment>
                                                                        )}
                                                                    <span className="">
                                                                        <span>Date: </span>{' '}
                                                                        {responses.created_at}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className=" border-b-2 border-main border-opacity-50 h-1 my-1" />
                                    </>
                                ))}
                        </div>
                    </div>

                    <div className="w-full border-t border-main border-opacity-40 pt-5">
                        <p className="text-main font-bold text-md w-full  justify-between flex">
                            Chatroom KIQs
                        </p>
                        <div className="w-full flex flex-col px-10 py-5 text-[.7rem] leading-4 gap-1">
                            {data?.chatroom &&
                                data?.chatroom?.map((item) => (
                                    <>
                                        <div
                                            key={item}
                                            className=" w-full border rounded-md p-2 flex flex-col"
                                        >
                                            {item?.question?.question && (
                                                <p className="text-main font-bold">
                                                    Question: {item?.question?.question}
                                                </p>
                                            )}
                                            <p className="text-main font-bold">
                                                {item.user.fullname} -{' '}
                                                <span className="font-semibold text-darkgrey">
                                                    {item.created_at}
                                                </span>
                                            </p>
                                            {/* <p>{item.answer}</p> */}
                                            <p
                                                dangerouslySetInnerHTML={makeUrlsClickable(
                                                    item.answer
                                                )}
                                            />
                                            {item.attachments && item.attachments.length > 0 && (
                                                <div>
                                                    {item.attachments.map((attachment) => (
                                                        <div
                                                            className="flex items-start justify-start w-fit "
                                                            key={attachment.id}
                                                        >
                                                            {attachment.type === 'image' ? (
                                                                <div>
                                                                    <img
                                                                        alt={attachment.filename}
                                                                        src={attachment.filename}
                                                                        className="max-h-60"
                                                                    />
                                                                    <Link
                                                                        to={attachment.filename}
                                                                        target="_blank"
                                                                        key={attachment.id}
                                                                        className="font-bold flex"
                                                                    >
                                                                        Image Link :{' '}
                                                                        <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                            Click to view
                                                                        </p>
                                                                    </Link>
                                                                </div>
                                                            ) : attachment.type === 'file' ? (
                                                                <Link
                                                                    to={attachment.filename}
                                                                    target="_blank"
                                                                    className="font-bold "
                                                                >
                                                                    PDF Link :{' '}
                                                                    <span className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                        <span>
                                                                            <FaFile />
                                                                        </span>
                                                                        Go to link
                                                                    </span>
                                                                </Link>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {item && item.responses?.length > 0 && (
                                                <div>
                                                    <p> Replies : </p>
                                                    <div className="flex flex-col w-full ml-5 px-6 gap-2">
                                                        {item.responses.map((responses) => (
                                                            <div className="border rounded-md pt-2 pl-2">
                                                                <div className="">
                                                                    <span className=" font-bold">
                                                                        {responses?.user?.fullname}-
                                                                    </span>

                                                                    <span>
                                                                        {' '}
                                                                        {responses.created_at}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className=" flex flex-col w-fit bg-opacity-30 pb-1 rounded-l-md rounded-tr-md shadow-sm leading-4 gap-1"
                                                                    key={responses.id}
                                                                >
                                                                    {/* {responses.answer} */}
                                                                    <p
                                                                        dangerouslySetInnerHTML={makeUrlsClickable(
                                                                            responses.answer
                                                                        )}
                                                                    />

                                                                    {responses.attachments &&
                                                                        responses.attachments
                                                                            ?.length > 0 && (
                                                                            <React.Fragment>
                                                                                {responses.attachments.map(
                                                                                    (
                                                                                        attachment
                                                                                    ) => (
                                                                                        <div
                                                                                            className="flex "
                                                                                            key={
                                                                                                attachment.id
                                                                                            }
                                                                                        >
                                                                                            {attachment.type ===
                                                                                            'image' ? (
                                                                                                <div>
                                                                                                    <img
                                                                                                        alt={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        src={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        className="max-h-60"
                                                                                                    />
                                                                                                    <Link
                                                                                                        to={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                        key={
                                                                                                            attachment.id
                                                                                                        }
                                                                                                        className="font-bold flex"
                                                                                                    >
                                                                                                        Image
                                                                                                        Link
                                                                                                        :{' '}
                                                                                                        <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                            Click
                                                                                                            to
                                                                                                            view
                                                                                                        </p>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            ) : attachment.type ===
                                                                                              'file' ? (
                                                                                                <Link
                                                                                                    to={
                                                                                                        attachment.filename
                                                                                                    }
                                                                                                    target="_blank"
                                                                                                    className="font-bold "
                                                                                                >
                                                                                                    PDF
                                                                                                    Link
                                                                                                    :{' '}
                                                                                                    <span className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                                                        <span>
                                                                                                            <FaFile />
                                                                                                        </span>
                                                                                                        Go
                                                                                                        to
                                                                                                        link
                                                                                                    </span>
                                                                                                </Link>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </React.Fragment>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className=" border-b-2 border-main border-opacity-50 h-1 my-1" />
                                    </>
                                ))}
                        </div>
                    </div>

                    <div className="w-full border-t border-main border-opacity-40 pt-5">
                        <p className="text-main font-bold text-md w-full  justify-between flex">
                            MY KIQs
                        </p>
                        <div className="w-full flex flex-col px-10 py-5 text-[.7rem] leading-4 gap-1">
                            {data?.mykiqs &&
                                data?.mykiqs?.map((item) => (
                                    <>
                                        <div
                                            key={item}
                                            className=" w-full border rounded-md p-2 flex flex-col "
                                        >
                                            {item?.question?.question && (
                                                <p className="text-main font-bold">
                                                    Question: {item?.question?.question}
                                                </p>
                                            )}
                                            <p className="text-main font-bold">
                                                {item.user.fullname} -{' '}
                                                <span className="font-semibold text-darkgrey">
                                                    {item.created_at}
                                                </span>
                                            </p>

                                            {/* <p>{item.answer}</p> */}
                                            <p
                                                dangerouslySetInnerHTML={makeUrlsClickable(
                                                    item.answer
                                                )}
                                            />
                                            {item.attachments && item.attachments.length > 0 && (
                                                <div>
                                                    {item.attachments.map((attachment) => (
                                                        <div
                                                            className="flex items-start justify-start w-fit "
                                                            key={attachment.id}
                                                        >
                                                            {attachment.type === 'image' ? (
                                                                <div>
                                                                    <img
                                                                        alt={attachment.filename}
                                                                        src={attachment.filename}
                                                                        className="max-h-60"
                                                                    />
                                                                    <Link
                                                                        to={attachment.filename}
                                                                        target="_blank"
                                                                        key={attachment.id}
                                                                        className="font-bold flex"
                                                                    >
                                                                        Image Link :{' '}
                                                                        <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                            Click to view
                                                                        </p>
                                                                    </Link>
                                                                </div>
                                                            ) : attachment.type === 'file' ? (
                                                                <Link
                                                                    to={attachment.filename}
                                                                    target="_blank"
                                                                    className="font-bold "
                                                                >
                                                                    PDF Link :{' '}
                                                                    <span className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                        <span>
                                                                            <FaFile />
                                                                        </span>
                                                                        Go to link
                                                                    </span>
                                                                </Link>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        <div className=" border-b-2 border-main border-opacity-50 h-1 my-1" />
                                    </>
                                ))}
                        </div>
                    </div>

                    <div className="w-full border-t border-main border-opacity-40 pt-5">
                        <p className="text-main font-bold text-md w-full  justify-between flex">
                            SnapIntel
                        </p>
                        <div className="w-full flex flex-col px-10 py-5 text-[.7rem] leading-4 gap-1">
                            {data?.snapintel &&
                                data?.snapintel.map((convo) => (
                                    <>
                                        {convo.snapintel_responses &&
                                            convo.snapintel_responses.map((item) => (
                                                <>
                                                    <div className=" w-full border rounded-md p-2 flex flex-col">
                                                        {item?.question?.question && (
                                                            <p className="text-main font-bold">
                                                                Question: {item?.question?.question}
                                                            </p>
                                                        )}
                                                        <p className="text-main font-bold">
                                                            {item.user.fullname} -{' '}
                                                            <span className="font-semibold text-darkgrey">
                                                                {item.created_at}
                                                            </span>
                                                        </p>

                                                        {/* <p>{item.answer}</p> */}
                                                        <p
                                                            dangerouslySetInnerHTML={makeUrlsClickable(
                                                                item.answer
                                                            )}
                                                        />
                                                        {item.attachments &&
                                                            item.attachments.length > 0 && (
                                                                <div>
                                                                    {item.attachments.map(
                                                                        (attachment) => (
                                                                            <div
                                                                                className="flex items-start justify-start w-fit "
                                                                                key={attachment.id}
                                                                            >
                                                                                {attachment.type ===
                                                                                'image' ? (
                                                                                    <div>
                                                                                        <img
                                                                                            alt={
                                                                                                attachment.filename
                                                                                            }
                                                                                            src={
                                                                                                attachment.filename
                                                                                            }
                                                                                            className="max-h-60"
                                                                                        />
                                                                                        <Link
                                                                                            to={
                                                                                                attachment.filename
                                                                                            }
                                                                                            target="_blank"
                                                                                            key={
                                                                                                attachment.id
                                                                                            }
                                                                                            className="font-bold flex"
                                                                                        >
                                                                                            Image
                                                                                            Link :{' '}
                                                                                            <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                Click
                                                                                                to
                                                                                                view
                                                                                            </p>
                                                                                        </Link>
                                                                                    </div>
                                                                                ) : attachment.type ===
                                                                                  'file' ? (
                                                                                    <Link
                                                                                        to={
                                                                                            attachment.filename
                                                                                        }
                                                                                        target="_blank"
                                                                                        className="font-bold "
                                                                                    >
                                                                                        PDF Link :{' '}
                                                                                        <span className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                                            <span>
                                                                                                <FaFile />
                                                                                            </span>
                                                                                            Go to
                                                                                            link
                                                                                        </span>
                                                                                    </Link>
                                                                                ) : null}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                    </div>

                                                    <div className=" border-b-2 border-main border-opacity-50 h-1 my-1" />
                                                </>
                                            ))}
                                    </>
                                ))}
                        </div>
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

export default ExportSpacesEvents
