import Companies from 'pages/SubPages/Companies'
import { Container } from './container'

export const AdminPrivateRoutes = {
    element: <Container />,
    children: [
        {
            path: '/companies',
            element: <Companies />,
        },
    ],
}
