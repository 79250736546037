import axios from 'axios'

const user_token = localStorage.getItem('__auth_nexus')
const token = JSON.parse(user_token) ?? { access_token: '' }

export const axiosAuthInstance = axios.create({
    baseURL: process.env.REACT_APP_LOCAL_AUTH_SERVER,
    withCredentials: true,
})

axios.defaults.withCredentials = true

axiosAuthInstance.interceptors.request.use((config) => {
    if (token.access_token) {
        config.headers.Authorization = `Bearer ${token.access_token}`
    }
    return config
})

axiosAuthInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        // Handle errors here
        console.error('Axios request error:', error)
        return Promise.reject(error)
    }
)

export default axiosAuthInstance
