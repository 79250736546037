import { CheckboxFilter } from 'components/CheckboxFilter'
import ExportGlobalSearch from 'components/Exports/ExportGlobalSearch'
import GoogleCustomSearch from 'components/GoogleSearch/GCE'
import React, { useState } from 'react'
import { FaFileDownload } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { IoCloseOutline } from 'react-icons/io5'
import { MdKeyboardBackspace } from 'react-icons/md'

export const GlobalSearchBar = ({ handleSearch, searchValue, clearSearch }) => {
    const [isGoogle, setIsGoogle] = useState(false)
    const [searchBar, setSearchBar] = useState(0)
    const [searchDl, setSearchDl] = useState(false)
    const googleSearch = () => {
        setSearchBar(1)
    }

    const globalSearch = () => {
        setSearchBar(0)
    }
    return (
        <div className="w-full h-fit flex flex-col  justify-evenly items-center p-4 gap-2 pt-2">
            {searchDl && (
                <div className="w-[40%] sm:w-full  fixed right-0 top-8 bottom-0 z-30 h-screen overflo overflow-y-auto bg-white animate_move_to_Left">
                    <ExportGlobalSearch word={searchValue} onClose={() => setSearchDl(false)} />
                </div>
            )}
            {searchBar == 1 ? (
                <div className="w-2/3  md:w-11/12 sm:w-11/12">
                    <span key={1}>
                        <h1 className="font-bold text-lg -pt-9">Google Search</h1>
                        <GoogleCustomSearch />
                    </span>
                    <span
                        onClick={() => setSearchBar(0)}
                        className="flex hover:underline text-[#7cc576] hover:text-[#51b148] justify-end hover:cursor-pointer"
                    >
                        <MdKeyboardBackspace className="mt-1 gap-1" />
                        <p>Go back to Global Search</p>
                    </span>
                </div>
            ) : (
                <div className="w-2/3 gap-2 md:w-11/12 sm:w-11/12">
                    <span className="flex flex-col">
                        <p className="w-full font-bold text-lg">Global Search</p>
                        <div className="w-full h-fit flex  items-center justify-center">
                            <input
                                onChange={(e) => handleSearch(e.target.value)}
                                type="text"
                                value={searchValue}
                                className="border w-1/2 grow h-8 border-main outline-none rounded-l-lg rounded-y-lg px-4 py-6 flex bg-white bg-opacity-60 "
                                placeholder="Search: Events, General SnapIntel, Questions, Responses, Users..."
                            />
                            {searchValue?.length > 0 && (
                                <>
                                    <div
                                        onClick={() => clearSearch()}
                                        className="w-fit  px-4 py-4 flex bg-main text-white font-bold scale-105 bg-opacity-60 rounded-r-lg border-main cursor-pointer"
                                    >
                                        <IoCloseOutline />
                                    </div>
                                    <button
                                        // disabled={userID == null}
                                        onClick={() => setSearchDl(true)}
                                        className=" cursor-pointer  flex text-[#f7f7f7c0] bg-main ml-1 border-main p-2 rounded-md justify-center flex-col items-center disabled:hidden hover:text-xl transition-all duration-300"
                                    >
                                        <FaFileDownload />
                                        <p className="text-xs text-white">
                                            <span className="sm:hidden">Download</span> PDF
                                        </p>
                                    </button>
                                </>
                            )}
                            <span
                                onClick={() => setSearchBar(1)}
                                className="cursor-pointer hover:bg-opacity-100 border ml-2 flex border-gray-400 rounded-md py-4 px-4 bg-white shadow-inner text-main bg-opacity-50"
                            >
                                <FcGoogle />
                            </span>
                            <span className="ml-2 z-20 ">
                                <CheckboxFilter />
                            </span>
                        </div>
                    </span>
                </div>
            )}
        </div>
    )
}
