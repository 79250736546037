import React from 'react'

const ButtonGroup = ({
    event,
    handleGDKIQSClicks,
    handleKIQSClicks,
    selectedButton,
    selectedEvent,
}) => {
    return (
        <div className="flex gap-2 sm:gap-1 sm:flex-col sm:w-full">
            <button
                onClick={() => handleGDKIQSClicks(event, 'General Discussion', null)}
                className={`w-1/4 sm:w-full text-xs p-2 leading-3 rounded-sm bg-white text-main hover:bg-[#d6f5d4] border border-main disabled:saturate-0 disabled:bg-gray-200 ${
                    selectedButton === null && selectedEvent === event ? 'underline font-bold' : ''
                }`}
            >
                General Discussion
            </button>
            <button
                onClick={() => handleKIQSClicks(event, 'Chatroom', 0)}
                className={`w-1/4 sm:w-full text-xs leading-3 p-2 border rounded-sm bg-[#7cc576] text-white hover:bg-[#87d680] ${
                    selectedButton === 0 && selectedEvent === event ? 'underline font-bold' : ''
                }`}
            >
                Chatroom KIQs
            </button>
            <button
                onClick={() => handleKIQSClicks(event, 'My KIQS', 1)}
                className={`w-1/4 sm:w-full text-xs leading-3 p-2 border rounded-sm bg-[#333333] text-white hover:bg-[#484848] ${
                    selectedButton === 1 && selectedEvent === event ? 'underline font-bold' : ''
                }`}
            >
                My KIQs
            </button>
            <button
                onClick={() => handleKIQSClicks(event, 'SnapIntel', 2)}
                className={`w-1/4 sm:w-full text-xs leading-3 p-2 border rounded-sm bg-main bg-opacity-25 text-main hover:bg-[#e0f7de] ${
                    selectedButton === 2 && selectedEvent === event ? 'underline font-bold' : ''
                }`}
            >
                SnapIntel
            </button>
        </div>
    )
}

export default ButtonGroup
