import React, { useEffect, useState } from 'react'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { MdOutlineFileDownload, MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import PDFIcon from '../../assets/pdf.png'
import { FaFileDownload } from 'react-icons/fa'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { Divider } from '@tremor/react'
import ExportNumberofEntries from 'components/Exports/ExportNumberofEntries'
import {
    getExportNumEntriesGDResponses,
    getExportNumEntriesResponses,
} from 'services/request/Exports'
import { getUserGenDiscussionApi } from 'services/request/NumberOfEntries'
import { NoData } from 'components/NoData'
import { useMutation } from 'react-query'
import { LoadingComponent } from 'components/LoadingComponent'

const ChatConvo = ({ userConvos, onClose, selectedEvent, isLoading, agent, selectedType }) => {
    const [selectedResponse, setSelectedResponse] = useState(null)
    const [selectedImage, setSelectedImage] = useState()
    const [generalDiscussion, setGeneralDiscussion] = useState()
    const [openGenDiscussion, setOpenGenDiscussion] = useState(false)
    const [questionState, setQuestionState] = useState({})
    const [toggleFilter, setToggleFilter] = useState(false)

    const { mutate: GenDisFetch, isLoading: GDLoading } = useMutation(
        (data) => getUserGenDiscussionApi(data),
        {
            onSuccess: (res) => {
                setGeneralDiscussion(res.data)
            },
        }
    )

    const handleGenDis = (selectedEvent, agent, type, is_gd, is_csv) => {
        setOpenGenDiscussion(!openGenDiscussion)
        GenDisFetch({ selectedEvent, agent, type, is_gd, is_csv })
    }

    const [answersContainer, setAnswerContainer] = useState(() => {
        if (!userConvos) {
            return [] // or any default value you prefer
        }

        return (
            userConvos &&
            userConvos.map((response) => {
                return (
                    response.kiq.questions &&
                    response.kiq.questions.map((question) => {
                        const responseData = `Question: \n ${question.question} \nResponse : 
    ${
        question.responses &&
        question.responses.map((response) => {
            const attachmentStrings =
                response.attachments &&
                response.attachments.length > 0 &&
                response.attachments.map((attach) => attach.filename)
            const repliesStrings =
                response.responses &&
                response.responses.map(
                    (reply) => reply.answer + ' replied by: ' + reply.user.fullname
                )

            return ` ${response.answer + '\n by: ' + response.user.fullname} \n ${
                attachmentStrings ? `Attachments: \n ${attachmentStrings.join('\n ')}` : ''
            }
                \n ${repliesStrings ? `Replies: \n ${repliesStrings.join('\n ')}` : ''}
                `
        })
    }`
                        return `${responseData}\n`
                    })
                )
            })
        )
    })

    useEffect(() => {
        setAnswerContainer(
            userConvos &&
                userConvos.map((response) => {
                    return (
                        response.kiq.questions &&
                        response.kiq.questions.map((question) => {
                            const responseData = `Question: \n ${question.question} \nResponse : 
    ${
        question.responses &&
        question.responses.map((response) => {
            const attachmentStrings =
                response.attachments &&
                response.attachments.length > 0 &&
                response.attachments.map((attach) => attach.filename)
            const repliesStrings =
                response.responses &&
                response.responses.map(
                    (reply) => reply.answer + ' replied by: ' + reply.user.fullname
                )

            return ` ${response.answer + '\n by: ' + response.user.fullname} \n ${
                attachmentStrings ? `Attachments: \n ${attachmentStrings.join('\n ')}` : ''
            }
            \n ${repliesStrings ? `Replies: \n ${repliesStrings.join('\n ')}` : ''}
            `
        })
    }`
                            return `${responseData}\n`
                        })
                    )
                })
        )
    }, [userConvos])

    const toggleResponses = (questionId) => {
        setQuestionState((prevStates) => ({
            ...prevStates,
            [questionId]: !prevStates[questionId],
        }))
    }

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    const exportCSVGDRecord = (data, agent) => {
        getExportNumEntriesGDResponses(data, agent, selectedEvent)
    }

    const exportCSVRecord = (data, agent) => {
        getExportNumEntriesResponses(data, agent, selectedEvent)
    }

    const [isDownload, setIsDownload] = useState(false)

    return (
        <div className=" rounded-lg w-full flex justify-center  items-start sm:h-fit md:h-fit">
            {isDownload && (
                <div className="absolute z-50 w-full animate_move_to_Left">
                    <ExportNumberofEntries
                        eventSelected={selectedEvent}
                        generalDiscussion={generalDiscussion}
                        data={userConvos}
                        onClose={() => setIsDownload(false)}
                    />
                </div>
            )}
            <div className="w-full py-4 px-4">
                {!isLoading && (
                    <div
                        // onClick={exportAllData}
                        className="w-full h-full p-2 text-lg sticky top-0 z-10 mt-4  flex justify-between items-center text-main font-bold  bg-white bg-opacity-80 backdrop-blur-sm"
                    >
                        <div className="flex w-1/2 hover:text-xl cursor-pointer" onClick={onClose}>
                            <IoIosCloseCircleOutline />
                        </div>

                        {selectedType === 0 ? (
                            <button
                                onClick={() => setToggleFilter(!toggleFilter)}
                                className="w-1/2 relative whitespace-nowrap hover:text-xl flex items-center h-fit cursor-pointer"
                            >
                                <FaFileDownload />
                                <p className="text-sm">Download CSV</p>{' '}
                                {toggleFilter && (
                                    <div className="animate_fade_up z-50 mt-[6.5rem] -ml-2 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() => exportCSVGDRecord(userConvos, agent)}
                                        >
                                            Download General Discussion CSV{' '}
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>

                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() =>
                                                userConvos.length > 0 &&
                                                exportCSVRecord(userConvos, agent)
                                            }
                                            disabled={userConvos.length === 0}
                                        >
                                            Download Chatroom CSV{' '}
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                    </div>
                                )}
                            </button>
                        ) : (
                            <div
                                onClick={() => exportCSVRecord(userConvos, agent)}
                                className="flex w-1/2 justify-end hover:text-xl cursor-pointer"
                            >
                                <FaFileDownload />
                                <p className="text-sm">Download CSV</p>
                            </div>
                        )}

                        <div
                            onClick={() => setIsDownload(true)}
                            className="flex w-1/2 justify-end hover:text-xl cursor-pointer"
                        >
                            <FaFileDownload />
                            <p className="text-sm">Download PDF</p>
                        </div>
                    </div>
                )}
                <div className="px-4 w-full text-xs">
                    {selectedType == 0 && (
                        <button
                            onClick={() => handleGenDis(selectedEvent, agent, 0, 1, 0)}
                            className="text-md w-full text-center bg-main rounded-md my-2 shadow-sm py-2 text-white font-semibold hover:bg-opacity-80"
                        >
                            General Discussion
                        </button>
                    )}

                    {openGenDiscussion ? (
                        GDLoading ? (
                            <LoadingComponent title="General Discussion" />
                        ) : generalDiscussion?.responses?.length > 0 ? (
                            generalDiscussion?.responses.map((response, responseIndex) => (
                                <div
                                    className="cursor-pointer text-sm  border-main px-2 pt-2"
                                    key={responseIndex}
                                >
                                    <span className="text-sm font-bold   ">
                                        {response?.user?.fullname}
                                    </span>

                                    <div className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-start text-left bg-gray-300  bg-opacity-30 py-2 pl-2 pr-4 rounded-bl-md rounded-r-md shadow-sm">
                                        <p
                                            className="text-sm w-full text-justify break-words"
                                            style={{ whiteSpace: 'pre-line' }}
                                        >
                                            {response.answer &&
                                                makeUrlsClickable(response.answer).map(
                                                    (part, index) => (
                                                        <React.Fragment key={index}>
                                                            {part}
                                                        </React.Fragment>
                                                    )
                                                )}
                                        </p>

                                        {response?.attachments &&
                                            response?.attachments.length > 0 && (
                                                <React.Fragment>
                                                    {response?.attachments.filter(
                                                        (attachment) => attachment.type === 'image'
                                                    ).length > 1 ? (
                                                        <div className="grid grid-cols-4 gap-1">
                                                            {response?.attachments
                                                                .filter(
                                                                    (attachment) =>
                                                                        attachment.type === 'image'
                                                                )
                                                                .map((attachment) => (
                                                                    <div
                                                                        className="flex items-start justify-start w-full"
                                                                        key={attachment.id}
                                                                    >
                                                                        <img
                                                                            className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                                            src={
                                                                                attachment.filename
                                                                            }
                                                                            alt="Attachment"
                                                                            onClick={() =>
                                                                                setSelectedImage(
                                                                                    attachment.filename
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    ) : (
                                                        response?.attachments
                                                            .filter(
                                                                (attachment) =>
                                                                    attachment.type === 'image'
                                                            )
                                                            .map((attachment) => (
                                                                <div
                                                                    className="flex items-start justify-start w-full"
                                                                    key={attachment.id}
                                                                >
                                                                    <img
                                                                        className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                        src={attachment.filename}
                                                                        alt="Attachment"
                                                                        onClick={() =>
                                                                            setSelectedImage(
                                                                                attachment.filename
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            ))
                                                    )}
                                                    <div>
                                                        {response?.attachments
                                                            .filter(
                                                                (attachment) =>
                                                                    attachment.type !== 'image'
                                                            )
                                                            .map((attachment) => (
                                                                <div
                                                                    className="flex items-start justify-start w-full"
                                                                    key={attachment.id}
                                                                >
                                                                    {attachment.type === 'file' ? (
                                                                        <NavLink
                                                                            to={attachment.filename}
                                                                            target="__blank"
                                                                            className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                        >
                                                                            <img
                                                                                className="w-8"
                                                                                src={PDFIcon}
                                                                                alt="TEXT_FILE"
                                                                            />
                                                                            <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                {
                                                                                    attachment.filename
                                                                                }
                                                                            </p>
                                                                        </NavLink>
                                                                    ) : null}
                                                                </div>
                                                            ))}
                                                    </div>
                                                </React.Fragment>
                                            )}

                                        <div className="w-full flex gap-4 justify-between">
                                            {response.type === 0 && (
                                                <div className="flex gap-2">
                                                    <div className="flex">
                                                        <span className="text-main">
                                                            <BiSolidLike />
                                                        </span>
                                                        <span>{response.total_likes}</span>
                                                    </div>
                                                    <div className="flex">
                                                        <span className="text-grey">
                                                            <BiSolidDislike />
                                                        </span>
                                                        <span>{response.total_dislikes}</span>
                                                    </div>
                                                </div>
                                            )}
                                            <div>
                                                <span>{response.created_at}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full flex flex-col gap-2">
                                        <div className="px-10 flex gap-2">
                                            {response.responses?.length > 0 && (
                                                <div className="flex">
                                                    <span className="rotate-90 text-base justify-center items-center ">
                                                        <MdOutlineSubdirectoryArrowLeft />
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            setSelectedResponse(
                                                                selectedResponse === response
                                                                    ? null
                                                                    : response
                                                            )
                                                        }
                                                        className="cursor-pointer "
                                                    >
                                                        {selectedResponse === response
                                                            ? 'Hide'
                                                            : 'Show'}{' '}
                                                        Replies
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {selectedResponse === response &&
                                            response.responses?.length > 0 && (
                                                <React.Fragment>
                                                    <div className="flex flex-col w-full ml-5 px-6 animate_fade_up">
                                                        {response.responses.map((responses) => (
                                                            <div>
                                                                <span className="text-xs font-bold">
                                                                    {responses?.user?.fullname}
                                                                </span>
                                                                <div
                                                                    className="text-[14px] flex flex-col w-fit gap-3 bg-main  bg-opacity-30 py-2 p-4 my-1 rounded-l-md rounded-tr-md shadow-sm"
                                                                    key={responses.id}
                                                                >
                                                                    {responses.answer &&
                                                                        responses.answer
                                                                            .split('\n')
                                                                            .map((line, index) => (
                                                                                <React.Fragment
                                                                                    key={index}
                                                                                >
                                                                                    {line}
                                                                                    <br />
                                                                                </React.Fragment>
                                                                            ))}

                                                                    {responses?.attachments &&
                                                                        responses?.attachments
                                                                            .length > 0 && (
                                                                            <React.Fragment>
                                                                                {responses?.attachments.filter(
                                                                                    (attachment) =>
                                                                                        attachment.type ===
                                                                                        'image'
                                                                                ).length > 1 ? (
                                                                                    <div className="grid grid-cols-4 gap-1">
                                                                                        {responses?.attachments
                                                                                            .filter(
                                                                                                (
                                                                                                    attachment
                                                                                                ) =>
                                                                                                    attachment.type ===
                                                                                                    'image'
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    attachment
                                                                                                ) => (
                                                                                                    <div
                                                                                                        className="flex items-start justify-start w-full"
                                                                                                        key={
                                                                                                            attachment.id
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                                                                            src={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            alt="Attachment"
                                                                                                            onClick={() =>
                                                                                                                setSelectedImage(
                                                                                                                    attachment.filename
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                    </div>
                                                                                ) : (
                                                                                    responses?.attachments
                                                                                        .filter(
                                                                                            (
                                                                                                attachment
                                                                                            ) =>
                                                                                                attachment.type ===
                                                                                                'image'
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                attachment
                                                                                            ) => (
                                                                                                <div
                                                                                                    className="flex items-start justify-start w-full"
                                                                                                    key={
                                                                                                        attachment.id
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                                                        src={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        alt="Attachment"
                                                                                                        onClick={() =>
                                                                                                            setSelectedImage(
                                                                                                                attachment.filename
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        )
                                                                                )}
                                                                                <div>
                                                                                    {responses?.attachments
                                                                                        .filter(
                                                                                            (
                                                                                                attachment
                                                                                            ) =>
                                                                                                attachment.type !==
                                                                                                'image'
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                attachment
                                                                                            ) => (
                                                                                                <div
                                                                                                    className="flex items-start justify-start w-full"
                                                                                                    key={
                                                                                                        attachment.id
                                                                                                    }
                                                                                                >
                                                                                                    {attachment.type ===
                                                                                                    'file' ? (
                                                                                                        <NavLink
                                                                                                            to={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            target="__blank"
                                                                                                            className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                        >
                                                                                                            <img
                                                                                                                className="w-8"
                                                                                                                src={
                                                                                                                    PDFIcon
                                                                                                                }
                                                                                                                alt="TEXT_FILE"
                                                                                                            />
                                                                                                            <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                                                {
                                                                                                                    attachment.filename
                                                                                                                }
                                                                                                            </p>
                                                                                                        </NavLink>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    <div className="w-full flex gap-4 justify-between">
                                                                        {responses.type === 0 && (
                                                                            <div className="flex gap-2">
                                                                                <div className="flex">
                                                                                    <span className="text-main">
                                                                                        <BiSolidLike />
                                                                                    </span>
                                                                                    <span>
                                                                                        {
                                                                                            responses.total_likes
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="flex">
                                                                                    <span className="text-grey">
                                                                                        <BiSolidDislike />
                                                                                    </span>
                                                                                    <span>
                                                                                        {
                                                                                            responses.total_dislikes
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div>
                                                                            <span>
                                                                                {
                                                                                    responses.created_at
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <NoData />
                        )
                    ) : null}

                    {openGenDiscussion && generalDiscussion?.responses.length > 0 && (
                        <Divider className="font-bold text-md text-main text-center">
                            End of General Discussions
                        </Divider>
                    )}

                    {userConvos &&
                        userConvos.map((userConvo, index) => {
                            const isType2 = userConvo.type === 2
                            return userConvo.kiq?.questions
                                .slice(isType2 ? 0 : undefined, isType2 ? 7 : undefined)
                                .map((question, index) => {
                                    return (
                                        <div className="flex flex-col gap-2 text-xs" key={index}>
                                            <div className="text-left text-[.8em] self-start bg-main text-base py-2 px-4  rounded-t-lg rounded-br-lg">
                                                {question.question}
                                            </div>

                                            <span
                                                className="transition-all duration-300 mb-2 cursor-pointer hover:font-bold"
                                                onClick={() => toggleResponses(question.id)}
                                            >
                                                {questionState[question.id]
                                                    ? `Hide (${question?.responses?.length}) response`
                                                    : `Show (${question?.responses?.length}) response`}
                                            </span>

                                            {questionState[question.id] && (
                                                <div>
                                                    {question &&
                                                        question.responses.map(
                                                            (response, responseIndex) => {
                                                                return (
                                                                    <div>
                                                                        <div
                                                                            className="animate_fade_up border-b  bg-main  bg-opacity-30 py-2 self-end px-4 mb-2 rounded-t-lg rounded-bl-lg"
                                                                            key={responseIndex}
                                                                        >
                                                                            <span className=" font-bold text-black">
                                                                                {
                                                                                    response?.user
                                                                                        ?.fullname
                                                                                }
                                                                            </span>
                                                                            <div>
                                                                                <p className="text-base break-all ">
                                                                                    <p
                                                                                        className="text-sm text-justify break-words"
                                                                                        style={{
                                                                                            whiteSpace:
                                                                                                'pre-line',
                                                                                        }}
                                                                                    >
                                                                                        {response.answer &&
                                                                                            makeUrlsClickable(
                                                                                                response.answer
                                                                                            ).map(
                                                                                                (
                                                                                                    part,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <React.Fragment
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            part
                                                                                                        }
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            )}
                                                                                    </p>

                                                                                    {response?.attachments &&
                                                                                        response
                                                                                            ?.attachments
                                                                                            .length >
                                                                                            0 && (
                                                                                            <React.Fragment>
                                                                                                {response?.attachments.filter(
                                                                                                    (
                                                                                                        attachment
                                                                                                    ) =>
                                                                                                        attachment.type ===
                                                                                                        'image'
                                                                                                )
                                                                                                    .length >
                                                                                                1 ? (
                                                                                                    <div className="grid grid-cols-4 gap-1">
                                                                                                        {response?.attachments
                                                                                                            .filter(
                                                                                                                (
                                                                                                                    attachment
                                                                                                                ) =>
                                                                                                                    attachment.type ===
                                                                                                                    'image'
                                                                                                            )
                                                                                                            .map(
                                                                                                                (
                                                                                                                    attachment
                                                                                                                ) => (
                                                                                                                    <div
                                                                                                                        className="flex items-start justify-start w-full"
                                                                                                                        key={
                                                                                                                            attachment.id
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <img
                                                                                                                            className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                                                                                            src={
                                                                                                                                attachment.filename
                                                                                                                            }
                                                                                                                            alt="Attachment"
                                                                                                                            onClick={() =>
                                                                                                                                setSelectedImage(
                                                                                                                                    attachment.filename
                                                                                                                                )
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                )
                                                                                                            )}
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    response?.attachments
                                                                                                        .filter(
                                                                                                            (
                                                                                                                attachment
                                                                                                            ) =>
                                                                                                                attachment.type ===
                                                                                                                'image'
                                                                                                        )
                                                                                                        .map(
                                                                                                            (
                                                                                                                attachment
                                                                                                            ) => (
                                                                                                                <div
                                                                                                                    className="flex items-start justify-start w-full"
                                                                                                                    key={
                                                                                                                        attachment.id
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                                                                        src={
                                                                                                                            attachment.filename
                                                                                                                        }
                                                                                                                        alt="Attachment"
                                                                                                                        onClick={() =>
                                                                                                                            setSelectedImage(
                                                                                                                                attachment.filename
                                                                                                                            )
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            )
                                                                                                        )
                                                                                                )}
                                                                                                <div>
                                                                                                    {response?.attachments
                                                                                                        .filter(
                                                                                                            (
                                                                                                                attachment
                                                                                                            ) =>
                                                                                                                attachment.type !==
                                                                                                                'image'
                                                                                                        )
                                                                                                        .map(
                                                                                                            (
                                                                                                                attachment
                                                                                                            ) => (
                                                                                                                <div
                                                                                                                    className="flex items-start justify-start w-full"
                                                                                                                    key={
                                                                                                                        attachment.id
                                                                                                                    }
                                                                                                                >
                                                                                                                    {attachment.type ===
                                                                                                                    'file' ? (
                                                                                                                        <NavLink
                                                                                                                            to={
                                                                                                                                attachment.filename
                                                                                                                            }
                                                                                                                            target="__blank"
                                                                                                                            className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                                        >
                                                                                                                            <img
                                                                                                                                className="w-8"
                                                                                                                                src={
                                                                                                                                    PDFIcon
                                                                                                                                }
                                                                                                                                alt="TEXT_FILE"
                                                                                                                            />
                                                                                                                            <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                                                                {
                                                                                                                                    attachment.filename
                                                                                                                                }
                                                                                                                            </p>
                                                                                                                        </NavLink>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            )
                                                                                                        )}
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                </p>
                                                                                <div className="w-full flex gap-4 justify-between mt-3">
                                                                                    {response.type ===
                                                                                        0 && (
                                                                                        <div className="flex gap-2 ">
                                                                                            <div className="flex">
                                                                                                <span className="text-main text-opacity-70">
                                                                                                    <BiSolidLike />
                                                                                                </span>
                                                                                                <span>
                                                                                                    {
                                                                                                        response.total_likes
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="flex">
                                                                                                <span className="text-grey">
                                                                                                    <BiSolidDislike />
                                                                                                </span>
                                                                                                <span>
                                                                                                    {
                                                                                                        response.total_dislikes
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                    <div>
                                                                                        <span>
                                                                                            {
                                                                                                response.created_at
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="px-10 flex gap-2">
                                                                            {response.responses
                                                                                ?.length > 0 && (
                                                                                <div className="flex mb-3 gap-2">
                                                                                    <span className="rotate-90 text-base justify-center items-center ">
                                                                                        <MdOutlineSubdirectoryArrowLeft />
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            setSelectedResponse(
                                                                                                selectedResponse ===
                                                                                                    response
                                                                                                    ? null
                                                                                                    : response
                                                                                            )
                                                                                        }
                                                                                        className="cursor-pointer "
                                                                                    >
                                                                                        {selectedResponse ===
                                                                                        response
                                                                                            ? ' Hide'
                                                                                            : ' Show'}{' '}
                                                                                        Replies
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {selectedResponse ===
                                                                            response &&
                                                                            response.responses
                                                                                ?.length > 0 && (
                                                                                <React.Fragment>
                                                                                    <div className="flex flex-col w-full ml-5 px-6 animate_fade_up mb-2">
                                                                                        {response.responses.map(
                                                                                            (
                                                                                                responses
                                                                                            ) => (
                                                                                                <div>
                                                                                                    <span className="text-xs font-bold">
                                                                                                        {
                                                                                                            responses
                                                                                                                ?.user
                                                                                                                ?.fullname
                                                                                                        }
                                                                                                    </span>
                                                                                                    <div
                                                                                                        className=" flex flex-col w-[100%] gap-3 bg-main  bg-opacity-30 py-2 p-4 my-1 rounded-l-md rounded-tr-md shadow-sm"
                                                                                                        key={
                                                                                                            responses.id
                                                                                                        }
                                                                                                    >
                                                                                                        <p
                                                                                                            className="text-sm text-justify break-words"
                                                                                                            style={{
                                                                                                                whiteSpace:
                                                                                                                    'pre-line',
                                                                                                            }}
                                                                                                        >
                                                                                                            {responses.answer &&
                                                                                                                makeUrlsClickable(
                                                                                                                    responses.answer
                                                                                                                ).map(
                                                                                                                    (
                                                                                                                        part,
                                                                                                                        index
                                                                                                                    ) => (
                                                                                                                        <React.Fragment
                                                                                                                            key={
                                                                                                                                index
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {
                                                                                                                                part
                                                                                                                            }
                                                                                                                        </React.Fragment>
                                                                                                                    )
                                                                                                                )}
                                                                                                        </p>

                                                                                                        {responses?.attachments &&
                                                                                                            responses
                                                                                                                ?.attachments
                                                                                                                .length >
                                                                                                                0 && (
                                                                                                                <React.Fragment>
                                                                                                                    {responses?.attachments.filter(
                                                                                                                        (
                                                                                                                            attachment
                                                                                                                        ) =>
                                                                                                                            attachment.type ===
                                                                                                                            'image'
                                                                                                                    )
                                                                                                                        .length >
                                                                                                                    1 ? (
                                                                                                                        <div className="grid grid-cols-4 gap-1">
                                                                                                                            {responses?.attachments
                                                                                                                                .filter(
                                                                                                                                    (
                                                                                                                                        attachment
                                                                                                                                    ) =>
                                                                                                                                        attachment.type ===
                                                                                                                                        'image'
                                                                                                                                )
                                                                                                                                .map(
                                                                                                                                    (
                                                                                                                                        attachment
                                                                                                                                    ) => (
                                                                                                                                        <div
                                                                                                                                            className="flex items-start justify-start w-full"
                                                                                                                                            key={
                                                                                                                                                attachment.id
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <img
                                                                                                                                                className="transition-all duration-300 w-[200px] h-[100px] cursor-pointer hover:brightness-50"
                                                                                                                                                src={
                                                                                                                                                    attachment.filename
                                                                                                                                                }
                                                                                                                                                alt="Attachment"
                                                                                                                                                onClick={() =>
                                                                                                                                                    setSelectedImage(
                                                                                                                                                        attachment.filename
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                )}
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        responses?.attachments
                                                                                                                            .filter(
                                                                                                                                (
                                                                                                                                    attachment
                                                                                                                                ) =>
                                                                                                                                    attachment.type ===
                                                                                                                                    'image'
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (
                                                                                                                                    attachment
                                                                                                                                ) => (
                                                                                                                                    <div
                                                                                                                                        className="flex items-start justify-start w-full"
                                                                                                                                        key={
                                                                                                                                            attachment.id
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <img
                                                                                                                                            className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                                                                                            src={
                                                                                                                                                attachment.filename
                                                                                                                                            }
                                                                                                                                            alt="Attachment"
                                                                                                                                            onClick={() =>
                                                                                                                                                setSelectedImage(
                                                                                                                                                    attachment.filename
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            )
                                                                                                                    )}
                                                                                                                    <div>
                                                                                                                        {responses?.attachments
                                                                                                                            .filter(
                                                                                                                                (
                                                                                                                                    attachment
                                                                                                                                ) =>
                                                                                                                                    attachment.type !==
                                                                                                                                    'image'
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (
                                                                                                                                    attachment
                                                                                                                                ) => (
                                                                                                                                    <div
                                                                                                                                        className="flex items-start justify-start w-full"
                                                                                                                                        key={
                                                                                                                                            attachment.id
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        {attachment.type ===
                                                                                                                                        'file' ? (
                                                                                                                                            <NavLink
                                                                                                                                                to={
                                                                                                                                                    attachment.filename
                                                                                                                                                }
                                                                                                                                                target="__blank"
                                                                                                                                                className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                                                            >
                                                                                                                                                <img
                                                                                                                                                    className="w-8"
                                                                                                                                                    src={
                                                                                                                                                        PDFIcon
                                                                                                                                                    }
                                                                                                                                                    alt="TEXT_FILE"
                                                                                                                                                />
                                                                                                                                                <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                                                                                    {
                                                                                                                                                        attachment.filename
                                                                                                                                                    }
                                                                                                                                                </p>
                                                                                                                                            </NavLink>
                                                                                                                                        ) : null}
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            )}
                                                                                                                    </div>
                                                                                                                </React.Fragment>
                                                                                                            )}
                                                                                                        <div className="w-full flex gap-4 justify-between">
                                                                                                            {responses.type ===
                                                                                                                0 && (
                                                                                                                <div className="flex gap-2">
                                                                                                                    <div className="flex">
                                                                                                                        <span className="text-main">
                                                                                                                            <BiSolidLike />
                                                                                                                        </span>
                                                                                                                        <span>
                                                                                                                            {
                                                                                                                                responses.total_likes
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    <div className="flex">
                                                                                                                        <span className="text-grey">
                                                                                                                            <BiSolidDislike />
                                                                                                                        </span>
                                                                                                                        <span>
                                                                                                                            {
                                                                                                                                responses.total_dislikes
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <div>
                                                                                                                <span>
                                                                                                                    {
                                                                                                                        responses.created_at
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })
                        })}
                </div>
            </div>
        </div>
    )
}

export default ChatConvo
