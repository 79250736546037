import React, { Component } from 'react'
import { renderToString } from 'react-dom/server'
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'
import L from 'leaflet'
import { MdLocationOn } from 'react-icons/md'
import CountriesJSON from '../data/countries.json'

class MapComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            position: [51.505, -0.09], // Set a default position
        }
        this.mapRef = React.createRef()
    }

    componentDidMount() {
        // Update the map center when the component mounts
        this.updateMapCenter()
    }

    componentDidUpdate(prevProps) {
        // Update the map center when props change (e.g., after a search)
        if (prevProps.center !== this.props.center) {
            this.updateMapCenter()
        }
    }

    updateMapCenter() {
        const { lat, lng, zoom } = this.props.center || {}
        if (lat !== undefined && lng !== undefined && zoom !== undefined && this.mapRef.current) {
            this.mapRef.current.leafletElement.setView([lat, lng], zoom)
        }
    }

    getCountryCoordinates(country) {
        // Your JSON data
        const jsonData = CountriesJSON

        // Find the country in the JSON data
        const countryData = jsonData.ref_country_codes.find(
            (countryData) => countryData.country === country
        )

        return countryData ? [countryData.latitude, countryData.longitude] : null
    }

    render() {
        const polylineCoordinates = []

        const customIcon = L.divIcon({
            className: 'custom-icon-class',
            html: renderToString(
                <div className="text-4xl text-red-600">
                    <MdLocationOn />
                </div>
            ),
        })

        const maxBounds = [
            [90, -180], // North West
            [-90, 180], // South East
        ]

        return (
            <div className="w-full h-fit">
                <MapContainer
                    style={{ height: '300px' }}
                    center={this.state.position}
                    zoom={1}
                    minZoom={1}
                    worldCopyJump={false}
                    ref={this.mapRef}
                    maxBounds={maxBounds}
                    maxBoundsViscosity={10.0}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        continuousWorld={false}
                    />
                    {this.props.location &&
                        this.props.location.map((location) => {
                            const country = location.location

                            // Get coordinates for the country from the JSON file
                            const countryCoordinates = this.getCountryCoordinates(country)

                            if (countryCoordinates) {
                                const [lat, lng] = countryCoordinates

                                // Add larger random offsets to the coordinates
                                const offsetLat = lat + (Math.random() - 0.5) / 50 // Adjust the divisor for the desired offset
                                const offsetLng = lng + (Math.random() - 0.5) / 50

                                // Use the original coordinates for the polyline
                                const coordinates = [lat, lng]
                                polylineCoordinates.push(coordinates)

                                return (
                                    <Marker
                                        key={location.id}
                                        position={{ lat: offsetLat, lng: offsetLng }}
                                        icon={customIcon}
                                    >
                                        <Popup>
                                            <div className="flex flex-col max-w-[250px]">
                                                <p className="no-margin">
                                                    Group Name : {location.group_name}
                                                </p>
                                                <p className="no-margin">
                                                    Location: {location.location}
                                                </p>
                                                <p className="no-margin">
                                                    Date Created: {location.created_at}
                                                </p>
                                                <p className="no-margin">
                                                    Members:
                                                    {location.users &&
                                                        location.users.map((agent) => (
                                                            <span key={agent.id}>
                                                                {' '}
                                                                {agent.fullname} /
                                                            </span>
                                                        ))}
                                                </p>
                                            </div>
                                        </Popup>
                                    </Marker>
                                )
                            } else {
                                // console.warn(`Coordinates not found for ${country}`)
                                return null
                            }
                        })}
                    {/* <Polyline positions={polylineCoordinates} color="#7cc576" weight={2} /> */}
                </MapContainer>
            </div>
        )
    }
}

export default MapComponent
