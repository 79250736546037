import React from 'react'

export const Checkbox = ({ title = 'Checkbox', isChecked, setValue, name }) => {
    // const [isChecked, setChecked] = useState(true)

    // const handleValue = () => {
    //     setChecked(!isChecked)
    //     // setValue(isChecked, name)
    // }

    // useEffect(() => {
    //     setValue(isChecked, name)
    // }, [isChecked])

    return (
        <div className="flex h-fit gap-2 justify-start items-center">
            <label className="container">
                <input
                    name={name}
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setValue(!isChecked, name)}
                />
                <svg viewBox="0 0 64 64" height="1em" width="1em">
                    <path
                        d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                        pathLength="575.0541381835938"
                        className="path"
                    ></path>
                </svg>
            </label>
            <label className="leading-4"> {title} </label>
        </div>
    )
}
