import React, { useMemo, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import LoadingImage from '../../../assets/NexusBrain.png'

import DataTable from 'react-data-table-component'
import { LoadingComponent } from 'components/LoadingComponent'
import { twMerge } from 'tailwind-merge'
import DashboardQuestion from 'components/ChatSelection/DashboardQuestion'

export const Questions = ({ searchQuestions, setTabs, setQuestionsContainer }) => {
    const [display, setDisplay] = useState(false)
    const [questionsHolder, setQuestionsHolder] = useState()

    const columns = [
        {
            // id: 1,
            name: 'Question',
            selector: (row) => row.event_name,
            cell: (row) => (
                <div
                    onClick={() => handleRowClicked(row)}
                    className="w-full flex justify-between items-center"
                >
                    <span
                        className={`${
                            row.event_name === questionsHolder && 'font-bold underline text-main'
                        }`}
                    >
                        {row.event_name}
                    </span>
                </div>
            ),
        },
    ]

    const handleRowClicked = (row) => {
        setQuestionsContainer(row)
        setTabs(4)
        setQuestionsHolder(row.event_name)
    }

    return (
        <div className="w-full">
            <button
                onClick={() => {
                    setDisplay(!display)
                    setQuestionsContainer(searchQuestions[0])
                    setTabs(4)
                    setQuestionsHolder(searchQuestions[0].event_name)
                }}
                disabled={searchQuestions?.length == 0 && true}
                className="transition-all duration-200 cursor-pointer flex justify-between p-4 w-full bg-[#5b9e5b] text-white rounded-md shadow-md animate_fade_up relative hover:bg-opacity-100 hover:text-white z-10"
            >
                <div className="flex items-center">
                    <span className="text-xl">
                        <BiChevronDown />
                    </span>
                    <p>Questions</p>
                </div>
                {searchQuestions ? (
                    <p className="border-white border px-2 rounded-full font-bold shadow-md">
                        <React.Fragment>{searchQuestions?.length ?? 0} </React.Fragment>
                    </p>
                ) : (
                    <img
                        src={LoadingImage}
                        className="w-[25px] animate_slow_spin brightness-200 "
                    />
                )}
            </button>
            {display && (
                <React.Fragment>
                    <div className=" Card-Styling transition-all duration-200 cursor-pointer w-full bg-white shadow-md pt-4 pb-2 px-4 -mt-5 rounded-md animate_fade_up">
                        <DataTable
                            progressComponent={<LoadingComponent title={'Questions'} />}
                            progressPending={!searchQuestions}
                            data={searchQuestions}
                            columns={columns}
                            fixedHeader
                            fixedHeaderScrollHeight="300px"
                            pagination
                            //                      defaultSortFieldId={1}
                            // sortIcon={<SortIcon />}
                            // paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                            onRowClicked={handleRowClicked}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export const QuestionsDisplay = ({ isLoading, dataContainer }) => {
    const [chatSelection, setChatSelection] = useState(0)
    const [kiqsType, setKiqsType] = useState()
    const [selectedQuestion, setSelectedQuestion] = useState()

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            compact: true,
            center: true,
            grow: 0,
        },
        {
            name: 'Question',
            selector: (row) => row.question,
            cell: (row) => (
                <p
                    onClick={() => setSelectedQuestion(row)}
                    className={`${
                        selectedQuestion?.question === row.question &&
                        'font-bold text-main underline'
                    } cursor-pointer text-sm`}
                >
                    {row.question}
                </p>
            ),
        },
        {
            name: (
                <div>
                    {kiqsType === 0 && 'Chatroom KIQs Count'}
                    {kiqsType === 1 && 'My Kiqs Count'}
                    {kiqsType === 2 && 'SnapIntel Count'}
                </div>
            ),

            selector: (row) => row?.responses?.length,
            compact: true,
            cell: (row) => row?.responses?.length,
            center: true,
        },
    ]

    const eventsWithResponsesCount = dataContainer?.event_kiqs?.map((event_kiq) => {
        const responsesCountByType = {
            0: 0,
            1: 0,
            2: 0,
        }

        event_kiq?.kiq.questions?.forEach((question) => {
            question?.responses?.forEach((response) => {
                if (response.type !== null) {
                    responsesCountByType[response.type]++
                }
            })
        })

        return {
            event_id: event_kiq?.event_id,
            responsesCountByType,
        }
    })

    const sortedDataContainer = () => {
        return dataContainer?.event_kiqs?.sort((a, b) => a.type - b.type)
    }

    const filterByKiqsType = useMemo(() => {
        return (
            dataContainer?.event_kiqs?.filter((event_kiq) => event_kiq?.type === kiqsType) || null
        )
    }, [kiqsType, dataContainer])

    useMemo(() => {
        setSelectedQuestion(null)
        if (sortedDataContainer()) setKiqsType(sortedDataContainer()[0]?.type)
    }, [dataContainer])

    return (
        <div className="h-full bg-white rounded-b-lg grow flex flex-col min-h-[300px] p-10 gap-4">
            <div>
                <p className="text-4xl text-main font-bold">{dataContainer?.event_name}</p>

                <div className="w-full">
                    <p className="text-xl text-main font-bold">Description</p>
                    <p>{dataContainer?.description}</p>
                </div>
            </div>

            <div className="flex w-full gap-2">
                {sortedDataContainer().map((event_kiq, index) => (
                    <button
                        key={index}
                        onClick={() => setKiqsType(event_kiq?.type)}
                        className={twMerge(
                            'w-1/3 text-left hover:scale-105 transition-all duration-300 text-white p-3 rounded-md',
                            event_kiq.type === 0 && 'block bg-main ',
                            event_kiq.type === 1 && 'block bg-[#333333]',
                            event_kiq.type === 2 && 'block bg-main bg-opacity-25 text-main'
                        )}
                    >
                        {event_kiq.type === 0 && 'Chatroom KIQs'}
                        {event_kiq.type === 1 && 'My Kiqs'}
                        {event_kiq.type === 2 && 'SnapIntel'}
                    </button>
                ))}
            </div>

            <div className="w-full h-full">
                <div className="animate_fade_up rounded-md overflow-hidden  h-full border  block z-10">
                    <DataTable
                        fixedHeader
                        data={filterByKiqsType[0]?.kiq?.questions}
                        columns={columns}
                    />
                    {selectedQuestion && (
                        <div>
                            <DashboardQuestion dataContainer={selectedQuestion} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
