import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import PDFIcon from '../../assets/pdf.png'
import ImageView from 'components/ImageView'
import { LoadingComponent } from 'components/LoadingComponent'
import { NoData } from 'components/NoData'
import { useMutation } from 'react-query'
import { getExportResponses } from 'services/request/Exports'

function EventsChat({
    arrayRes,
    kiqsSelected,
    eventQuestions,
    handleQuestionSelect,
    selectedQuestion,
    questionResponses,
}) {
    const [selectedResponse, setSelectedResponse] = useState(null)
    const [selectedImage, setSelectedImage] = useState()

    const { mutate: ExportData } = useMutation(['GetExportApi'], (data) => {
        getExportResponses(data)
    })

    const exportAllData = (event, question) => {
        const data = {
            event_id: event.event_id,
            question_id: question.id,
            type: question.type,
            event: event,
            question: question,
        }
        ExportData(data)
    }
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        // <div className=" w-3/4 bg-white min-h-full h-[100%] overflow-y-auto border-l border-opacity-50 border-gray-400 grow">

        <div className="flex flex-row justify-between sm:flex-col ">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}

            <div className="w-1/3 border-r-4 sm:border-none sm:w-full p-2">
                <p className="font-bold">Questions</p>
                {arrayRes[1].isLoading ? (
                    <span>
                        <LoadingComponent title="Questions" />
                    </span>
                ) : (
                    kiqsSelected === 'Chatroom' &&
                    eventQuestions?.chatroom?.length > 0 &&
                    eventQuestions?.chatroom?.map((question, questionIndex) => {
                        return (
                            <li
                                onClick={() => handleQuestionSelect(question)}
                                className={`${
                                    selectedQuestion.question === question.question &&
                                    'bg-main text-white'
                                } hover:bg-main hover:text-white cursor-pointer text-xs border-b p-2 list-decimal`}
                                key={questionIndex}
                            >
                                {question.question}
                            </li>
                        )
                    })
                )}

                {kiqsSelected === 'My KIQS' &&
                    eventQuestions.mykiqs &&
                    eventQuestions.mykiqs.map((question, questionIndex) => (
                        <li
                            onClick={() => handleQuestionSelect(question)}
                            className={`${
                                selectedQuestion.question === question.question &&
                                'bg-main text-white'
                            } hover:bg-main hover:text-white cursor-pointer text-xs border-b p-2 list-decimal`}
                            key={questionIndex}
                        >
                            {question.question}
                        </li>
                    ))}

                {kiqsSelected === 'SnapIntel' &&
                    eventQuestions.snapintel &&
                    eventQuestions.snapintel.slice(0, 7).map((question, questionIndex) => (
                        <li
                            onClick={() => handleQuestionSelect(question)}
                            className={`${
                                selectedQuestion.question === question.question &&
                                'bg-main text-white'
                            } hover:bg-main hover:text-white cursor-pointer text-xs border-b p-2 list-decimal`}
                            key={questionIndex}
                        >
                            {question.question}
                        </li>
                    ))}
            </div>
            <div className="w-1/3 border-r-4 sm:border-r-0 sm:border-b sm:w-full overflow-y-auto p-2">
                <p className="font-bold pl-2">Responses</p>
                {arrayRes[2].isLoading ? (
                    <span>
                        <LoadingComponent title="Responses" />
                    </span>
                ) : questionResponses && questionResponses.length == 0 ? (
                    <div className="w-full flex justify-center">
                        <div className="w-1/2 object-contain">
                            <NoData />
                        </div>
                    </div>
                ) : (
                    questionResponses &&
                    questionResponses.map((response, responseIndex) => (
                        <>
                            {response?.answer?.length > 0 && (
                                <>
                                    <div
                                        className="text-sm border-b p-2 border-l-4 border-l-main mt-4 mb-2 cursor-default"
                                        key={responseIndex}
                                    >
                                        <span className="text-sm font-bold pl-4 text-main">
                                            {response?.user?.fullname}
                                        </span>
                                        <div className="px-4">
                                            <p
                                                className="text-sm text-justify break-words"
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {response.answer &&
                                                    makeUrlsClickable(response?.answer).map(
                                                        (part, index) => (
                                                            <React.Fragment key={index}>
                                                                {part}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                            </p>
                                            <p className="text-xs">
                                                Date Created: {response.created_at}
                                            </p>
                                        </div>
                                    </div>

                                    {response.responses.length > 0 &&
                                        response.responses.map((response, index) => (
                                            <div
                                                className="w-3/4 ml-5 text-sm border-b py-1 border-l-4 border-l-main mb-1 cursor-default"
                                                key={responseIndex}
                                            >
                                                <span className="text-xs font-bold pl-4 text-main">
                                                    <span>Reply of : </span>
                                                    {response?.user?.fullname}
                                                </span>
                                                <div className="px-4 ">
                                                    <p
                                                        className="text-sm text-justify break-words"
                                                        style={{
                                                            whiteSpace: 'pre-line',
                                                        }}
                                                    >
                                                        {response.answer &&
                                                            makeUrlsClickable(response?.answer).map(
                                                                (part, index) => (
                                                                    <p
                                                                        className=" leading-4"
                                                                        key={index}
                                                                    >
                                                                        {part}
                                                                    </p>
                                                                )
                                                            )}
                                                    </p>
                                                    <p className="text-xs pt-1">
                                                        Date Created: {response.user.created_at}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                </>
                            )}
                        </>
                    ))
                )}
            </div>
            <div className="w-1/3 sm:w-full p-2">
                <p className="font-bold pl-2">Media and Files</p>
                {arrayRes[2].isLoading ? (
                    <span>
                        <LoadingComponent title="Media and Files" />
                    </span>
                ) : questionResponses && questionResponses.length == 0 ? (
                    <div className="w-full flex justify-center">
                        <div className="w-1/2 object-contain">
                            <NoData />
                        </div>
                    </div>
                ) : (
                    questionResponses &&
                    questionResponses.map(
                        (response, responseIndex) =>
                            response.attachments &&
                            response.attachments?.length > 0 && (
                                <>
                                    <div
                                        key={responseIndex}
                                        className="flex flex-col gap-2 border-b p-2"
                                    >
                                        <span className="text-sm font-bold pl-4 text-main">
                                            {response?.user?.fullname}
                                        </span>
                                        {response.attachments.map((attachment, attachIndex) => (
                                            <div className="flex " key={attachIndex}>
                                                {attachment.type === 'image' ? (
                                                    <img
                                                        key={attachment.id}
                                                        src={attachment.filename}
                                                        alt="Attachment"
                                                        className="h-[150px] cursor-pointer hover:scale-105 transition-all duration-300"
                                                        onClick={() =>
                                                            setSelectedImage(attachment.filename)
                                                        }
                                                    />
                                                ) : attachment.type === 'file' ? (
                                                    <NavLink
                                                        key={attachIndex}
                                                        to={attachment.filename}
                                                        target="__blank"
                                                        className="transition-all duration-300 flex gap-4 h-fit w-1/2 items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                    >
                                                        <img
                                                            className="w-8"
                                                            src={PDFIcon}
                                                            alt="TEXT_FILE"
                                                        />
                                                        <p className=" text-left text-xs break-all truncate-multiline-2">
                                                            {attachment.filename}
                                                        </p>
                                                    </NavLink>
                                                ) : null}
                                            </div>
                                        ))}
                                        <p className="text-xs">
                                            Date Created: {response.created_at}
                                        </p>
                                    </div>
                                    {response.responses.length > 0 &&
                                        response.responses.map((response, index) => (
                                            <div
                                                className="w-3/4 ml-5 text-sm border-b py-1 border-l-4 border-l-main mb-1 cursor-default"
                                                key={responseIndex}
                                            >
                                                <span className="text-xs font-bold pl-4 text-main">
                                                    <span>Reply of : </span>
                                                    {response?.user?.fullname}
                                                </span>
                                                {response.attachments.map(
                                                    (attachment, attachIndex) => (
                                                        <div
                                                            className="flex pl-4 pb-2"
                                                            key={attachIndex}
                                                        >
                                                            {attachment.type === 'image' ? (
                                                                <img
                                                                    key={attachment.id}
                                                                    src={attachment.filename}
                                                                    alt="Attachment"
                                                                    className="h-[150px] cursor-pointer hover:scale-105 transition-all duration-300"
                                                                    onClick={() =>
                                                                        setSelectedImage(
                                                                            attachment.filename
                                                                        )
                                                                    }
                                                                />
                                                            ) : attachment.type === 'file' ? (
                                                                <NavLink
                                                                    key={attachIndex}
                                                                    to={attachment.filename}
                                                                    target="__blank"
                                                                    className="transition-all duration-300 flex gap-4 h-fit w-1/2 items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                >
                                                                    <img
                                                                        className="w-8"
                                                                        src={PDFIcon}
                                                                        alt="TEXT_FILE"
                                                                    />
                                                                    <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                        {attachment.filename}
                                                                    </p>
                                                                </NavLink>
                                                            ) : null}
                                                        </div>
                                                    )
                                                )}
                                                <p className="text-xs">
                                                    Date Created: {response.user.created_at}
                                                </p>
                                            </div>
                                        ))}
                                </>
                            )
                    )
                )}
            </div>
        </div>
        // </div>
    )
}

export default EventsChat
