import React, { useEffect, useRef, useState } from 'react'
import PDFIcon from '../../assets/pdf.png'
import { NavLink } from 'react-router-dom'
import ImageView from 'components/ImageView'

function SnapIntelUpdate({ dataContainer }) {
    const [selectedImage, setSelectedImage] = useState()
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline break-all font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    // console.log('dtaaaaaa', dataContainer)

    const scrollContainerRef = useRef(null)

    const scrollToBottom = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight
        }
    }

    useEffect(() => {
        scrollToBottom()
    }, [dataContainer.responses]) // Scroll to bottom whenever dataContainer.responses changes

    const allAnswers = dataContainer.responses
        ?.filter((response) => response.type === 2)
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at)) // Sort oldest to newest

    return (
        <div className="w-full flex flex-col ">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            <div className="w-full bg-main py-2 px-2 flex justify-between items-center rounded-t-md">
                <p className="font-semibold text-white">
                    {dataContainer.type === 0
                        ? 'Chatroom KIQs'
                        : dataContainer.type === 1
                        ? 'My KIQS'
                        : 'SnapIntel'}
                </p>
            </div>
            <div
                ref={scrollContainerRef}
                className="w-full h-full max-h-[435px] overflow-y-auto px-5 flex flex-col gap-3 bg-gray-200 py-5 rounded-b-md"
            >
                {allAnswers?.length > 0 ? (
                    allAnswers.map((answer) => (
                        <div key={answer.id} className="w-full flex flex-col gap-3">
                            <div className="flex w-full items-center gap-2 px-2 ">
                                <div className="bg-main text-center aspect-square rounded-full pt-1 w-8 h-8 text-white">
                                    Q
                                </div>
                                <div className="text-xs bg-main bg-opacity-30 p-2 rounded-r-md rounded-tl-md shadow-sm">
                                    {answer?.question?.question}
                                </div>
                            </div>

                            <div className="flex flex-col w-full items-end justify-end gap-2 ml-5 px-6 animate_fade_up">
                                <div className="flex flex-col gap-2 w-3/4 items-end justify-end">
                                    <div className="flex ">
                                        <span className="flex flex-col gap-2">
                                            <div className="flex w-full items-center justify-end ">
                                                <span className="rotate-90 mr-10 text-xl"></span>
                                                <span className="text-xs gap-2 flex flex-col w-fit justify-end items-end text-left bg-white bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                    <span className="text-xs font-bold">
                                                        {answer?.user?.fullname}
                                                    </span>
                                                    <p
                                                        className="text-sm text-justify break-words"
                                                        style={{
                                                            whiteSpace: 'pre-line',
                                                        }}
                                                    >
                                                        {answer.answer &&
                                                            makeUrlsClickable(answer.answer).map(
                                                                (part, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {part}
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </p>

                                                    {answer?.attachments &&
                                                        answer?.attachments.length > 0 && (
                                                            <React.Fragment>
                                                                {answer?.attachments.filter(
                                                                    (attachment) =>
                                                                        attachment.type === 'image'
                                                                ).length > 1 ? (
                                                                    <div className="grid grid-cols-4 gap-1">
                                                                        {answer?.attachments
                                                                            .filter(
                                                                                (attachment) =>
                                                                                    attachment.type ===
                                                                                    'image'
                                                                            )
                                                                            .map((attachment) => (
                                                                                <div
                                                                                    className="flex items-start justify-start w-full"
                                                                                    key={
                                                                                        attachment.id
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        className="transition-all duration-300 w-[200px] h-[90px] cursor-pointer hover:brightness-50"
                                                                                        src={
                                                                                            attachment.filename
                                                                                        }
                                                                                        alt="Attachment"
                                                                                        onClick={() =>
                                                                                            setSelectedImage(
                                                                                                attachment.filename
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                ) : (
                                                                    answer?.attachments
                                                                        .filter(
                                                                            (attachment) =>
                                                                                attachment.type ===
                                                                                'image'
                                                                        )
                                                                        .map((attachment) => (
                                                                            <div
                                                                                className="flex items-start justify-start w-full"
                                                                                key={attachment.id}
                                                                            >
                                                                                <img
                                                                                    className="transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                                    src={
                                                                                        attachment.filename
                                                                                    }
                                                                                    alt="Attachment"
                                                                                    onClick={() =>
                                                                                        setSelectedImage(
                                                                                            attachment.filename
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        ))
                                                                )}
                                                                <div>
                                                                    {answer?.attachments
                                                                        .filter(
                                                                            (attachment) =>
                                                                                attachment.type !==
                                                                                'image'
                                                                        )
                                                                        .map((attachment) => (
                                                                            <div
                                                                                className="flex items-start justify-start w-full"
                                                                                key={attachment.id}
                                                                            >
                                                                                {attachment.type ===
                                                                                'file' ? (
                                                                                    <NavLink
                                                                                        to={
                                                                                            attachment.filename
                                                                                        }
                                                                                        target="__blank"
                                                                                        className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                    >
                                                                                        <img
                                                                                            className="w-8"
                                                                                            src={
                                                                                                PDFIcon
                                                                                            }
                                                                                            alt="TEXT_FILE"
                                                                                        />
                                                                                        <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                            {
                                                                                                attachment.filename
                                                                                            }
                                                                                        </p>
                                                                                    </NavLink>
                                                                                ) : null}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </React.Fragment>
                                                        )}

                                                    <span className="text-xs text-grey">
                                                        <span>Date: </span> {answer.created_at}
                                                    </span>
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="flex justify-center items-center h-full">
                        There's no recent update
                    </div>
                )}
            </div>
        </div>
    )
}

export default SnapIntelUpdate
