import React from 'react'
import NBLogo from 'assets/NexusBrain.png'
import ErrorFolder from 'assets/401.png'
import { useNavigate } from 'react-router-dom'

export const UnauthorizedUser = () => {
    const navigate = useNavigate()

    const handleRedirection = () => {
        localStorage.clear()
        window.location.replace(process.env.REACT_APP_NEXUSBRAIN_WEB_URL)
    }

    return (
        <div className="w-screen h-screen flex overflow-hidden md:flex-col sm:flex-col">
            <div className=" absolute w-full h-full bg-error bg-[length:600px_300px] bg-center"></div>
            <div className="w-7/12 h-full bg-main flex justify-center items-center md:w-full sm:w-full">
                <div className="text-white w-full pl-28 flex flex-col gap-8 md:pl-0 sm:pl-0 md:justify-center md:items-center sm:justify-center sm:items-center">
                    <div className=" md:flex-col md:justify-center md:items-center  sm:flex-col sm:justify-center sm:items-center">
                        <img
                            src={NBLogo}
                            alt="NexusBrain Logo"
                            className="brightness-200 w-16 md:pl-8 sm:pl-8 "
                        />
                        <p className="text-xl">NexusBrain</p>
                    </div>
                    <div className="w-full md:w-fit sm:w-fit">
                        <p className="text-4xl">Error 401</p>
                        <p className="text-5xl font-extrabold">Unauthorized Access</p>
                    </div>
                </div>
            </div>
            <div className="w-5/12 h-full bg-[#eeffec] md:w-full sm:w-full">
                <div className="flex flex-col justify-center items-center w-full h-full">
                    <img src={ErrorFolder} alt="404 Error" className=" w-72 md:w-36 sm:w-36" />
                    <button
                        onClick={handleRedirection}
                        className=" p-3 px-4 z-[9999] hover:shadow-md hover:shadow-main rounded bg-main text-white text-xl mt-5"
                    >
                        Go to <span className="font-bold">NEXUSBRAiN WEB</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
