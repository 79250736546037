import Dashboard from 'pages/Dashboard'
import SelectedCard from 'components/SelectedCard'
import SpacesEvents from 'pages/SubPages/SpacesEvents'
import NumberOfEntries from 'pages/SubPages/NumberOfEntries'
import { Container } from './container'
import { Contributors } from 'pages/SubPages/Contributors'
import { Restricted } from 'pages/RestrictedPage'
import { MainDashboard } from 'pages/MainDashboard'
import GenSnapIntel from 'pages/SubPages/GenSnapIntel'

export const PrivateRoutes = {
    element: <Container />,
    children: [
        // {
        //     path: '/dashboard',
        //     element: <Dashboard />,
        // },
        {
            path: '/dashboard',
            element: <MainDashboard />,
        },
        {
            path: '/chatrooms/:roomId',
            element: <SelectedCard />,
        },

        {
            path: '/spaces_and_events',
            element: <SpacesEvents />,
        },

        {
            path: '/number_of_entries',
            element: <NumberOfEntries />,
        },

        {
            path: '/contributors',
            element: <Contributors />,
        },
        {
            path: '/general_snapintel',
            element: <GenSnapIntel />,
        },
        {
            path: '*',
            element: <Restricted />,
        },
    ],
}
