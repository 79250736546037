import { axiosInstance } from '../../axios'

export const getSpacesEventsApi = async () => {
    const response = await axiosInstance.post(process.env.REACT_APP_SPACES_EVENTS)

    return response.data
}

export const getPDFEventsApi = async (data) => {
    const response = await axiosInstance.post('/api/v1/events/convo_export', {
        event_id: data.event,
        type: data.type,
    })
    return response.data
}
