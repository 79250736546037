import React, { Fragment, useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaWindowMinimize } from 'react-icons/fa'
import PDFIcon from '../../assets/pdf.png'
import { Link, NavLink } from 'react-router-dom'
import { DownloadProgress } from 'components/DownloadingComponent'
import ImageView from 'components/ImageView'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'

export const ReactExport = ({ data, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        // Attach the event listener when the component mounts
        window.addEventListener('keydown', handleKeyPress)

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])
    const [selectedImage, setSelectedImage] = useState()

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    return (
        <div className=" top-0 bottom-0  right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex items-center justify-between ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                        >
                            Download PDF
                        </button>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                fileName={`Export Most React Responses - ${data?.event_name}`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                {selectedImage != null && (
                    <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
                )}
                <div className="w-full bg-white rounded-md items-center justify-center h-full ">
                    <span className="w-full p-3 justify-center text-center h-fit text-main flex font-bold mt-2">
                        <p className="text-lg">Most Liked and Disliked Responses</p>
                    </span>
                    <div className="w-full h-5/6  flex gap-2 px-4 pb-5 flex-col">
                        <div className="w-full h-full rounded-md border border-opacity-50 ">
                            <div className="w-full h-fit p-2 flex text-center font-bold items-center justify-center text-main border-b border-opacity-50">
                                {' '}
                                <span className="-scale-x-100 ">
                                    <BiSolidLike />{' '}
                                </span>
                                Most Likes
                            </div>
                            <div className="w-full h-fit overflow-y-auto p-5 flex flex-col gap-4">
                                {data?.most_liked &&
                                    data?.most_liked.map((like) => (
                                        <div
                                            className="flex flex-col w-full items-end justify-end"
                                            key={like.id}
                                        >
                                            <span className="text-xs font-bold mb-2">
                                                {like?.user?.fullname}
                                            </span>
                                            <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-[#e1e1e1] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                {/* {like.answer} */}
                                                <p
                                                    className="text-sm text-justify break-words"
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {like.answer &&
                                                        makeUrlsClickable(like.answer).map(
                                                            (part, index) => (
                                                                <React.Fragment key={index}>
                                                                    {part}
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                </p>
                                                {like.attachments &&
                                                    like.attachments.length > 0 && (
                                                        <React.Fragment>
                                                            {like.attachments.map((attachment) => (
                                                                <div
                                                                    className="flex items-end justify-end w-full"
                                                                    key={attachment.id}
                                                                >
                                                                    {attachment.type === 'image' ? (
                                                                        <img
                                                                            className=" transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                            src={
                                                                                attachment.filename
                                                                            }
                                                                            alt="Attachment"
                                                                            onClick={() =>
                                                                                setSelectedImage(
                                                                                    attachment.filename
                                                                                )
                                                                            }
                                                                            // className="h-[150px]"
                                                                        />
                                                                    ) : attachment.type ===
                                                                      'file' ? (
                                                                        <NavLink
                                                                            to={attachment.filename}
                                                                            target="__blank"
                                                                            className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                        >
                                                                            <img
                                                                                className="w-8"
                                                                                src={PDFIcon}
                                                                                alt="TEXT_FILE"
                                                                            />
                                                                            <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                                {
                                                                                    attachment.filename
                                                                                }
                                                                            </p>
                                                                        </NavLink>
                                                                    ) : null}
                                                                </div>
                                                            ))}
                                                        </React.Fragment>
                                                    )}
                                                <div className="w-full flex gap-4 justify-between">
                                                    {like.type === 0 && (
                                                        <div className="flex gap-2">
                                                            <div className="flex">
                                                                <span className="text-main">
                                                                    <BiSolidLike />
                                                                </span>
                                                                <span>{like.likes_count}</span>
                                                            </div>
                                                            <div className="flex">
                                                                <span className="text-grey">
                                                                    <BiSolidDislike />
                                                                </span>
                                                                <span>{like.dislikes_count}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <span>{like.created_at}</span>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="w-full h-full rounded-md mb-4 border border-opacity-50 ">
                            <div className="w-full h-fit p-2 flex text-center font-bold items-center justify-center text-darkgrey border-b border-opacity-50">
                                {' '}
                                <span className="-scale-x-100 ">
                                    <BiSolidDislike />{' '}
                                </span>
                                Most Dislikes
                            </div>
                            <div className="w-full h-fit overflow-y-auto p-5 flex flex-col gap-4">
                                {data?.most_disliked &&
                                    data?.most_disliked.map((dislike) => (
                                        <div
                                            className="flex flex-col w-full items-end justify-end"
                                            key={dislike.id}
                                        >
                                            <span className="text-xs font-bold mb-2">
                                                {dislike?.user?.fullname}
                                            </span>
                                            <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-[#e1e1e1] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                {/* {dislike.answer} */}
                                                <p
                                                    className="text-sm text-justify break-words"
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {dislike.answer &&
                                                        makeUrlsClickable(dislike.answer).map(
                                                            (part, index) => (
                                                                <React.Fragment key={index}>
                                                                    {part}
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                </p>
                                                {dislike.attachments &&
                                                    dislike.attachments.length > 0 && (
                                                        <React.Fragment>
                                                            {dislike.attachments.map(
                                                                (attachment) => (
                                                                    <div
                                                                        className="flex items-end justify-end w-full"
                                                                        key={attachment.id}
                                                                    >
                                                                        {attachment.type ===
                                                                        'image' ? (
                                                                            <img
                                                                                className=" transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                                src={
                                                                                    attachment.filename
                                                                                }
                                                                                alt="Attachment"
                                                                                onClick={() =>
                                                                                    setSelectedImage(
                                                                                        attachment.filename
                                                                                    )
                                                                                }
                                                                                // className="h-[150px]"
                                                                            />
                                                                        ) : attachment.type ===
                                                                          'file' ? (
                                                                            <NavLink
                                                                                to={
                                                                                    attachment.filename
                                                                                }
                                                                                target="__blank"
                                                                                className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                            >
                                                                                <img
                                                                                    className="w-8"
                                                                                    src={PDFIcon}
                                                                                    alt="TEXT_FILE"
                                                                                />
                                                                                <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                                    {
                                                                                        attachment.filename
                                                                                    }
                                                                                </p>
                                                                            </NavLink>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                <div className="w-full flex gap-4 justify-between">
                                                    {dislike.type === 0 && (
                                                        <div className="flex gap-2">
                                                            <div className="flex">
                                                                <span className="text-main">
                                                                    <BiSolidLike />
                                                                </span>
                                                                <span>{dislike.likes_count}</span>
                                                            </div>
                                                            <div className="flex">
                                                                <span className="text-grey">
                                                                    <BiSolidDislike />
                                                                </span>
                                                                <span>
                                                                    {dislike.dislikes_count}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <span>{dislike.created_at}</span>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

export default ReactExport
