import React, { useMemo, useState } from 'react'
import { useQueries, useQuery } from 'react-query'
import { Link, useLocation } from 'react-router-dom'
import { getDataCountsApi } from 'services/request/DataCounts'
import { useAtomValue } from 'jotai'
import { authAtom } from 'store/authAtom'
import { getGeneralSnapIntelApi } from 'services/request/Dashboard'

const MainNav = ({ onDisplay }) => {
    const auth = useAtomValue(authAtom)
    const [countContainer, setCountContainer] = useState()
    const [generalSnapIntelCount, setGeneralSnapIntelCount] = useState(false)
    const { data } = useQuery(['getDataCountsApi'], getDataCountsApi)
    const location = useLocation()

    const result = useQueries([
        {
            queryKey: ['getDataCountsApi'],
            queryFn: () => getDataCountsApi(),
        },
        {
            queryKey: ['getGeneralSnapIntelCountApi'],
            queryFn: () => getGeneralSnapIntelApi(),
        },
    ])

    const isLoading = result.some((res) => res.isLoading)

    useMemo(() => {
        setCountContainer({
            ...result[0]?.data?.result?.data_count,
            generalSnapIntelCount: result[1]?.data?.result.length,
        })
    }, [isLoading])

    const handleLinkClick = (title) => {
        document.title = title
    }

    return (
        <div className="h-fit w-full gap-2 flex md:flex  sm:grid sm:grid-cols-2 mb-2">
            <Link
                to="/general_snapintel"
                onClick={() => handleLinkClick('NexusBrain Dashboard - General SnapIntel')}
                className={`transition-all duration-300 w-full hover:w-[120%] bg-analysts bg-no-repeat bg-right bg-contain bg-main h-[70px] rounded-lg shadow-md font-semibold text-white text-left px-5 flex flex-col justify-center items-start ${
                    location.pathname === '/general_snapintel' ? 'active' : ''
                }`}
            >
                <span
                    className={`w-full text-left lg:text-sm leading-3 line-height flex  ${
                        location.pathname === '/general_snapintel' ? 'underline font-bold' : ''
                    }`}
                >
                    General SnapIntel
                </span>
                <span className="text-gray-700 font-bold text-2xl">
                    {countContainer?.generalSnapIntelCount}
                </span>
            </Link>
            <Link
                to="/spaces_and_events"
                onClick={() => handleLinkClick('NexusBrain Dashboard - Events')}
                className={`transition-all duration-300 w-full hover:w-[110%] bg-projects bg-no-repeat bg-right bg-contain bg-main h-[70px] rounded-lg shadow-md font-semibold text-white text-left flex flex-col pl-5 justify-center items-start ${
                    location.pathname === '/spaces_and_events' ? 'active' : ''
                }`}
            >
                <span
                    className={`w-full text-left lg:text-sm ${
                        location.pathname === '/spaces_and_events' ? 'underline font-bold' : ''
                    }`}
                >
                    Events
                </span>
                <span className="text-gray-700 font-bold text-2xl">
                    {countContainer?.events_count}
                </span>
            </Link>
            {auth?.user?.role === 'admin' && (
                <Link
                    to="/companies"
                    onClick={() => handleLinkClick('NexusBrain Dashboard - Companies')}
                    className={`transition-all duration-300 w-full hover:w-[110%] bg-products bg-no-repeat bg-right bg-contain bg-main h-[70px] rounded-lg shadow-md font-semibold text-white text-left pl-5 flex flex-col justify-center items-start ${
                        location.pathname === '/companies' ? 'active' : ''
                    }`}
                >
                    <span
                        className={`w-full text-left lg:text-sm ${
                            location.pathname === '/companies' ? 'underline font-bold' : ''
                        }`}
                    >
                        Companies
                    </span>
                    <span className="text-gray-700 font-bold text-2xl">
                        {countContainer?.companies_counts}
                    </span>
                </Link>
            )}
            <Link
                to="/number_of_entries"
                onClick={() => handleLinkClick('NexusBrain Dashboard - Number Of Entries')}
                className={`transition-all duration-300 w-full hover:w-[110%] bg-intelcount bg-no-repeat bg-right bg-contain bg-main h-[70px] rounded-lg shadow-md font-semibold text-white text-left pl-5 flex flex-col justify-center items-start ${
                    location.pathname === '/number_of_entries' ? 'active' : ''
                }`}
            >
                <span
                    className={`w-full text-left lg:text-sm  line-height  ${
                        location.pathname === '/number_of_entries' ? 'underline font-bold' : ''
                    }`}
                >
                    Number of Entries
                </span>
                <span className="text-gray-700 font-bold text-2xl">
                    {countContainer?.responses_count}
                </span>
            </Link>
            <Link
                to="/contributors"
                onClick={() => handleLinkClick('NexusBrain Dashboard - Contributors')}
                className={`transition-all duration-300 w-full hover:w-[120%] bg-analysts bg-no-repeat bg-right bg-contain bg-main h-[70px] rounded-lg shadow-md font-semibold text-white text-left pl-5 flex flex-col justify-center items-start ${
                    location.pathname === '/contributors' ? 'active' : ''
                }`}
            >
                <span
                    className={`w-full text-left lg:text-sm ${
                        location.pathname === '/contributors' ? 'underline font-bold' : ''
                    }`}
                >
                    Contributors
                </span>
                <span className="text-gray-700 font-bold text-2xl">
                    {countContainer?.contributors_count}
                </span>
            </Link>
        </div>
    )
}

export default MainNav
