import React, { Fragment, useState } from 'react'
import { RiMessageFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import PDFIcon from '../../../assets/pdf.png'
import { GoFile } from 'react-icons/go'
import { FaWindowMinimize } from 'react-icons/fa'
import { FaRegWindowMinimize } from 'react-icons/fa6'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { Divider } from '@mui/material'

const ChatroomExport = ({ convo }) => {
    const [displayGD, setDisplayGD] = useState(true)
    const makeUrlsClickable = (text) => {
        if (!text) {
            return { __html: '' } // Return an empty string or handle the null case accordingly
        }

        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g

        // Replace matched URLs with clickable <a> tags
        const replacedText = text.replace(
            urlRegex,
            (url) =>
                `<a class="hover:text-blue-600 break-all hover:underline  font-bold" href="${url}" target="_blank"><br/> <br/> ${url} <br/> <br/></a>`
        )

        return { __html: replacedText }
    }

    return (
        <div className="w-full">
            <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                <span className="flex">GENERAL DISCUSSION </span>

                <span
                    onClick={() => setDisplayGD(!displayGD)}
                    className="font-normal cursor-pointer hover:scale-110"
                >
                    <FaWindowMinimize style={{ color: '#fff' }} />
                </span>
            </p>
            {!displayGD && (
                <p className="text-[.6rem] w-full text-center italic">
                    General Discussion is Hidden - Response/s Count -{' '}
                    {convo?.general_discussion?.length}
                </p>
            )}
            {displayGD && (
                <div className="h-full overflow-y-auto leading-3  pb-10 w-full">
                    {convo?.general_discussion && convo?.general_discussion?.length > 0 ? (
                        convo?.general_discussion &&
                        convo?.general_discussion.map((convo, index) => (
                            <div className="w-full ">
                                <div className="px-2">
                                    <div className="w-full flex flex-col justify-end items-end my-2">
                                        <p className=" max-w-[80%] w-fit self-end text-[.7em] bg-white border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                            <span className="flex gap-2 mb-2">
                                                <p className="text-main font-bold">
                                                    {convo?.user?.fullname}
                                                </p>
                                            </span>
                                            <p
                                                className="whitespace-break-spaces "
                                                dangerouslySetInnerHTML={makeUrlsClickable(
                                                    convo?.answer
                                                )}
                                            />

                                            {convo?.attachments &&
                                                convo?.attachments.length > 0 && (
                                                    <Fragment>
                                                        {convo?.attachments.map((attachment) => (
                                                            <div
                                                                className="flex items-start justify-start w-full"
                                                                key={attachment.id}
                                                            >
                                                                {attachment.type === 'image' ? (
                                                                    <div>
                                                                        <img
                                                                            alt={
                                                                                attachment.filename
                                                                            }
                                                                            src={
                                                                                attachment.filename
                                                                            }
                                                                            className="max-h-60"
                                                                        />
                                                                        <Link
                                                                            to={attachment.filename}
                                                                            target="_blank"
                                                                            key={attachment.id}
                                                                            className="flex mt-2"
                                                                        >
                                                                            Image Link : &nbsp;
                                                                            <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                Click to view
                                                                            </p>
                                                                        </Link>
                                                                    </div>
                                                                ) : attachment.type === 'file' ? (
                                                                    <Link
                                                                        to={attachment.filename}
                                                                        target="__blank"
                                                                        className=" flex flex-col items-end justify-center w-full mt-2"
                                                                    >
                                                                        PDF Link :{' '}
                                                                        <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold bg-gray-100 p-2 rounded-md">
                                                                            <span className="flex items-center gap-2">
                                                                                <img
                                                                                    alt="PDF Display"
                                                                                    className="h-4 w-4"
                                                                                    src={PDFIcon}
                                                                                />
                                                                                <p className="truncate">
                                                                                    View PDF
                                                                                </p>
                                                                            </span>
                                                                            <span className="text-main ml-4">
                                                                                <GoFile
                                                                                    style={{
                                                                                        color: '#7cc576',
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        </span>
                                                                    </Link>
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                    </Fragment>
                                                )}
                                            <p className="mt-2 text-[.6rem] font-bold text-right text-darkgrey">
                                                Date: {convo?.created_at}
                                            </p>
                                        </p>
                                        {convo?.responses &&
                                            convo?.responses.map((response, index) => (
                                                <div className="w-3/4 flex flex-col justify-end items-end my-2">
                                                    <div
                                                        className={` justify-end flex gap-2 items-center mr-4-mt-1 w-1/3 mb-1`}
                                                    >
                                                        {' '}
                                                        <div
                                                            style={{ color: '#777777' }}
                                                            className={`rotate-90 text-left w-fit text-[.8rem] -mt-1`}
                                                        >
                                                            <MdOutlineSubdirectoryArrowLeft />{' '}
                                                        </div>
                                                        <div className="w-fit text-[.7rem]">
                                                            Replied to
                                                        </div>
                                                    </div>
                                                    <p className=" max-w-[80%] w-fit self-end text-[.7em] bg-green-100 border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                        <span className="flex gap-2 mb-2 items-center">
                                                            <p className="text-main font-bold">
                                                                {response?.user?.fullname}
                                                            </p>
                                                        </span>
                                                        <p
                                                            className="whitespace-break-spaces "
                                                            dangerouslySetInnerHTML={makeUrlsClickable(
                                                                response?.answer
                                                            )}
                                                        />

                                                        {response?.attachments &&
                                                            response?.attachments.length > 0 && (
                                                                <Fragment>
                                                                    {response?.attachments.map(
                                                                        (attachment) => (
                                                                            <div
                                                                                className="flex items-start justify-start w-full"
                                                                                key={attachment.id}
                                                                            >
                                                                                {attachment.type ===
                                                                                'image' ? (
                                                                                    <div>
                                                                                        <img
                                                                                            alt={
                                                                                                attachment.filename
                                                                                            }
                                                                                            src={
                                                                                                attachment.filename
                                                                                            }
                                                                                            className="max-h-60"
                                                                                        />
                                                                                        <Link
                                                                                            to={
                                                                                                attachment.filename
                                                                                            }
                                                                                            key={
                                                                                                attachment.id
                                                                                            }
                                                                                            target="_blank"
                                                                                            className="flex mt-2"
                                                                                        >
                                                                                            Image
                                                                                            Link :
                                                                                            &nbsp;
                                                                                            <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                Click
                                                                                                to
                                                                                                view
                                                                                            </p>
                                                                                        </Link>
                                                                                    </div>
                                                                                ) : attachment.type ===
                                                                                  'file' ? (
                                                                                    <Link
                                                                                        to={
                                                                                            attachment.filename
                                                                                        }
                                                                                        target="__blank"
                                                                                        className=" flex flex-col items-start justify-center"
                                                                                    >
                                                                                        PDF Link :{' '}
                                                                                        <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold bg-gray-100 p-2 rounded-md">
                                                                                            <span className="flex items-center gap-2">
                                                                                                <img
                                                                                                    alt="PDF Display"
                                                                                                    className="h-4 w-4"
                                                                                                    src={
                                                                                                        PDFIcon
                                                                                                    }
                                                                                                />
                                                                                                <p className="truncate">
                                                                                                    View
                                                                                                    PDF
                                                                                                </p>
                                                                                            </span>
                                                                                            <span className="text-main ml-4">
                                                                                                <GoFile
                                                                                                    style={{
                                                                                                        color: '#7cc576',
                                                                                                    }}
                                                                                                />
                                                                                            </span>
                                                                                        </span>
                                                                                    </Link>
                                                                                ) : null}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                        <p className="mt-2 text-[.6rem] font-bold text-right text-darkgrey">
                                                            Date: {response?.created_at}
                                                        </p>
                                                    </p>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="w-full h-full flex flex-col justify-center items-center ">
                            <p className="text-xl text-main">
                                <RiMessageFill color="#7cc576" />
                            </p>
                            <p className="text-lg text-gray-600">No Record</p>
                        </div>
                    )}
                </div>
            )}
            <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                <span>CHATROOM KIQs</span>
            </p>
            <div className="h-full overflow-y-auto leading-3 pb-10 ">
                {convo?.chatroom && convo?.chatroom?.length > 0 ? (
                    convo?.chatroom &&
                    convo?.chatroom.map((convo, index) => (
                        <div className="w-full">
                            <div className="px-2">
                                <p className="text-[.7em] transition-all duration-300 px-4 py-2 my-2 w-90% text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    {convo?.question}
                                </p>
                                {convo?.responses &&
                                    convo?.responses.map((response) => (
                                        <div className="w-full flex flex-col justify-end items-end my-2">
                                            <p className=" max-w-[80%] w-fit self-end text-[.7em] bg-white border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                <span className="flex gap-2 mb-2">
                                                    <p className="text-main font-bold">
                                                        {response?.user?.fullname}
                                                    </p>
                                                </span>
                                                <p
                                                    className="whitespace-break-spaces "
                                                    dangerouslySetInnerHTML={makeUrlsClickable(
                                                        response?.answer
                                                    )}
                                                />

                                                {response?.attachments &&
                                                    response?.attachments.length > 0 && (
                                                        <Fragment>
                                                            {response?.attachments.map(
                                                                (attachment) => (
                                                                    <div
                                                                        className="flex items-start justify-start w-full"
                                                                        key={attachment.id}
                                                                    >
                                                                        {attachment.type ===
                                                                        'image' ? (
                                                                            <div>
                                                                                <img
                                                                                    alt={
                                                                                        attachment.filename
                                                                                    }
                                                                                    src={
                                                                                        attachment.filename
                                                                                    }
                                                                                    className="max-h-60"
                                                                                />
                                                                                <Link
                                                                                    to={
                                                                                        attachment.filename
                                                                                    }
                                                                                    key={
                                                                                        attachment.id
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="flex mt-2"
                                                                                >
                                                                                    Image Link :
                                                                                    &nbsp;
                                                                                    <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                        Click to
                                                                                        view
                                                                                    </p>
                                                                                </Link>
                                                                            </div>
                                                                        ) : attachment.type ===
                                                                          'file' ? (
                                                                            <Link
                                                                                to={
                                                                                    attachment.filename
                                                                                }
                                                                                target="__blank"
                                                                                className=" flex flex-col items-start justify-center"
                                                                            >
                                                                                PDF Link :{' '}
                                                                                <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold bg-gray-100 p-2 rounded-md">
                                                                                    <span className="flex items-center gap-2">
                                                                                        <img
                                                                                            alt="PDF Display"
                                                                                            className="h-4 w-4"
                                                                                            src={
                                                                                                PDFIcon
                                                                                            }
                                                                                        />
                                                                                        <p className="truncate">
                                                                                            View PDF
                                                                                        </p>
                                                                                    </span>
                                                                                    <span className="text-main ml-4">
                                                                                        <GoFile
                                                                                            style={{
                                                                                                color: '#7cc576',
                                                                                            }}
                                                                                        />
                                                                                    </span>
                                                                                </span>
                                                                            </Link>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                        </Fragment>
                                                    )}
                                                <p className="mt-2 text-[.6rem] font-bold text-right text-darkgrey">
                                                    Date: {response?.created_at}
                                                </p>
                                            </p>
                                            {response?.responses &&
                                                response?.responses.map((response, index) => (
                                                    <div className="w-3/4 flex flex-col justify-end items-end my-2">
                                                        <div
                                                            className={`justify-end flex gap-2 items-center mr-4-mt-1 w-1/3 mb-1`}
                                                        >
                                                            {' '}
                                                            <div
                                                                style={{ color: '#777777' }}
                                                                className="rotate-90 -z-0 text-left w-fit text-[.8rem] -mt-1"
                                                            >
                                                                <MdOutlineSubdirectoryArrowLeft />{' '}
                                                            </div>
                                                            <div className="w-fit text-[.7rem]">
                                                                Replied to
                                                            </div>
                                                        </div>
                                                        <p className=" max-w-[80%] w-fit self-end text-[.7em] bg-green-100 border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                            <span className="flex gap-2 mb-2 items-center">
                                                                <p className="text-main font-bold">
                                                                    {response?.user?.fullname}
                                                                </p>
                                                            </span>
                                                            <p
                                                                className="whitespace-break-spaces "
                                                                dangerouslySetInnerHTML={makeUrlsClickable(
                                                                    response?.answer
                                                                )}
                                                            />

                                                            {response?.attachments &&
                                                                response?.attachments.length >
                                                                    0 && (
                                                                    <Fragment>
                                                                        {response?.attachments.map(
                                                                            (attachment) => (
                                                                                <div
                                                                                    className="flex break-all items-start justify-start w-full"
                                                                                    key={
                                                                                        attachment.id
                                                                                    }
                                                                                >
                                                                                    {attachment.type ===
                                                                                    'image' ? (
                                                                                        <div>
                                                                                            <img
                                                                                                alt={
                                                                                                    attachment.filename
                                                                                                }
                                                                                                src={
                                                                                                    attachment.filename
                                                                                                }
                                                                                                className="max-h-60"
                                                                                            />
                                                                                            <Link
                                                                                                to={
                                                                                                    attachment.filename
                                                                                                }
                                                                                                key={
                                                                                                    attachment.id
                                                                                                }
                                                                                                target="_blank"
                                                                                                className="flex mt-2"
                                                                                            >
                                                                                                Image
                                                                                                Link
                                                                                                :
                                                                                                &nbsp;
                                                                                                <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                    Click
                                                                                                    to
                                                                                                    view
                                                                                                </p>
                                                                                            </Link>
                                                                                        </div>
                                                                                    ) : attachment.type ===
                                                                                      'file' ? (
                                                                                        <Link
                                                                                            to={
                                                                                                attachment.filename
                                                                                            }
                                                                                            target="__blank"
                                                                                            className=" flex flex-col items-start justify-center"
                                                                                        >
                                                                                            PDF Link
                                                                                            :{' '}
                                                                                            <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold bg-gray-100 p-2 rounded-md">
                                                                                                <span className="flex items-center gap-2">
                                                                                                    <img
                                                                                                        alt="PDF Display"
                                                                                                        className="h-4 w-4"
                                                                                                        src={
                                                                                                            PDFIcon
                                                                                                        }
                                                                                                    />
                                                                                                    <p className="truncate">
                                                                                                        View
                                                                                                        PDF
                                                                                                    </p>
                                                                                                </span>
                                                                                                <span className="text-main ml-4">
                                                                                                    <GoFile
                                                                                                        style={{
                                                                                                            color: '#7cc576',
                                                                                                        }}
                                                                                                    />
                                                                                                </span>
                                                                                            </span>
                                                                                        </Link>
                                                                                    ) : null}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Fragment>
                                                                )}
                                                            <p className="mt-2 text-[.6rem] font-bold text-right text-darkgrey">
                                                                Date: {response?.created_at}
                                                            </p>
                                                        </p>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="w-full h-full flex flex-col justify-center items-center ">
                        <p className="text-xl text-main">
                            <RiMessageFill color="#7cc576" />
                        </p>
                        <p className="text-lg text-gray-600">No Record</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ChatroomExport
