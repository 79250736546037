import React, { useState } from 'react';
import { Chart } from 'react-google-charts';

// import products from './data/products.json'

function DonutChartComponent({ data, ...props }) {
  // const valueFormatter = (number) => ` ${new Intl.NumberFormat("us").format(number).toString()}`;
  const options = {
    // title: "My Daily Activities",
    is3D: true,
    slices: [
      { color: '#7cc756' }, // Color for the first slice
      { color: '#777777' }, // Color for the second slice
      { color: '#e6fcda' }, // Color for the third slice
    ],
    sliceVisibilityThreshold: 0.05, // Hide labels for small slices
    pieSliceTextStyle: {
      color: '#333333', // Change the text color to white
    },
  };

  const chartData = [
    ['Data Type', 'Count'], // Header row
    ['Chatroom', data.chatroom_count],
    ['Mykiqs', data.mykiqs_count],
    ['Snapintel', data.snapintel_count],
  ];

  return (
    <div className='w-full flex flex-col justify-center'>
      <h1 className='text-center'>User Responses</h1>
      <Chart
        chartType='PieChart'
        data={chartData}
        options={options}
        width={'100%'}
        height={'fit-content'}
      />
    </div>
  );
}

export default DonutChartComponent;
