import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const SkeletonLoader = ({ count, isLoading, children, ...rest }) => {
  return (
    <React.Fragment>
      {!isLoading ? (
        children
      ) : (
        <Skeleton className='opacity-40' count={count} {...rest} />
      )}
    </React.Fragment>
  );
};
