import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import PDFIcon from '../../assets/pdf.png'
import nodata from '../../assets/nodata.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AiOutlineCaretDown, AiOutlineEye } from 'react-icons/ai'
import ImageGalleryComponent from '../ImageGallery'
import { useMutation, useQueries } from 'react-query'
import { getChatroomApi, getMyKIQSApi, getSnapIntelApi } from 'services/request/Dashboard/index'
import { SkeletonLoader } from 'components/SkeletonLoader'
import DashboardQuestion from 'components/ChatSelection/DashboardQuestion'
import ImageView from 'components/ImageView'
import GeneralDiscussion from 'components/ChatSelection/GeneralDiscussion'
import { MdOutlineFileDownload } from 'react-icons/md'
import { getPDFEventsApi } from 'services/request/SpacesAndEvents'
import ExportCompaniesKIQs from 'components/Exports/ExportCompaniesKIQs'
import { getAllPDFCompaniesApi } from 'services/request/Companies'
import CompaniesExport from 'components/Exports/ExportCompanies'

function GeneralTab({ data, isLoading, noData }) {
    const [showGroups, setShowGroups] = useState(false)
    const [kiqsView, setKiqsView] = useState(0)
    const [imageGal, setImageGal] = useState(false)
    const [tab, setTab] = useState(0)
    const [dataExportEvent, setDataExportEvent] = useState()
    const [kiqsDownload, setKiqsDownload] = useState(false)
    const checkboxRef = useRef(null)
    const [attachments, setAttachments] = useState()
    const [toggleFilter, setToggleFilter] = useState(false)
    const [questionToggle, setQuestionToggle] = useState(false)
    const [questionContainer, setQuestionContainer] = useState()
    const [selectedImage, setSelectedImage] = useState()
    const [selectedButton, setSelectedButton] = useState(null)
    const [pdfAllKIQS, setPdfAllKIQS] = useState(null)
    const [isDownloadCompany, setIsDownloadCompany] = useState(false)
    const [isMobileView, setIsMobileView] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768)
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
                setToggleFilter(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])
    const results = useQueries([
        {
            queryKey: ['getChatroomApi', kiqsView, data],
            queryFn: () => getChatroomApi(data.id),
            onSuccess: (data) => {
                setAttachments(data.attachments)
            },
            enabled: kiqsView === 0 || kiqsView === 3,
        },
        {
            queryKey: ['getMyKIQSApi', kiqsView, data],
            queryFn: () => getMyKIQSApi(data.id),
            onSuccess: (data) => {
                setAttachments(data.attachments)
            },
            enabled: kiqsView === 1,
        },

        {
            queryKey: ['getSnapIntelApi', kiqsView, data],
            queryFn: () => getSnapIntelApi(data.id),
            onSuccess: (data) => setAttachments(data.attachments),
            enabled: kiqsView === 2,
        },
    ])

    const columnsChatroom = [
        {
            name: 'ID',
            selector: (row) => row.id,
            compact: true,
            center: true,
            grow: 0,
        },
        {
            name: 'Question',
            selector: (row) => row.question,
            cell: (row) => (
                <p
                    onClick={() => handleQuestionClick(row)}
                    className={`${
                        questionContainer?.question === row.question && 'underline font-bold'
                    } cursor-pointer text-sm leading-4 py-2`}
                >
                    {row.question}
                </p>
            ),
        },
        {
            name: 'Chatroom KIQs Count',
            selector: (row) => row.responses.length,
            compact: true,
            cell: (row) => row.responses.length,
            center: true,
        },
    ]

    const columnsMyKiqs = [
        {
            name: 'ID',
            selector: (row) => row.id,
            compact: true,
            center: true,
            grow: 0,
        },
        {
            name: 'Question',
            selector: (row) => row.question,
            cell: (row) => (
                <p
                    onClick={() => handleQuestionClick(row)}
                    className={`${
                        questionContainer?.question === row.question && 'underline font-bold'
                    } cursor-pointer text-sm leading-4 py-2`}
                >
                    {row.question}
                </p>
            ),
        },
        {
            name: 'My KIQS Count',
            selector: (row) => row.responses.length,
            compact: true,
            cell: (row) => row.responses.length,
            center: true,
        },
    ]
    const columnsSnapIntel = [
        {
            name: 'ID',
            selector: (row) => row.id,
            compact: true,
            center: true,
            grow: 0,
        },
        {
            name: 'Question',
            selector: (row) => row.question,
            cell: (row) => (
                <p
                    onClick={() => handleQuestionClick(row)}
                    className={`${
                        questionContainer?.question === row.question && 'underline font-bold'
                    } cursor-pointer text-sm leading-4 py-2`}
                >
                    {row.question}
                </p>
            ),
        },
        {
            name: 'SnapIntel Count',
            selector: (row) => row.responses.length,
            compact: true,
            cell: (row) => row.responses.length,
            center: true,
        },
    ]

    const handleQuestionClick = (row) => {
        setQuestionToggle(true)
        setQuestionContainer(row)
    }

    const handleKiqsChange = (type) => {
        setKiqsView(type)
        setSelectedButton(type)
    }

    useEffect(() => {
        if (kiqsView === 0) {
            setQuestionContainer(results[0]?.data?.result[0])
            setQuestionToggle(true)
        } else if (kiqsView === 1) {
            setQuestionContainer(results[1]?.data?.result[0])
            setQuestionToggle(true)
        } else if (kiqsView === 2) {
            setQuestionContainer(results[2]?.data?.result[0])
            setQuestionToggle(true)
        } else {
            return
        }
    }, [results[0].isLoading, results[1].isLoading, results[2].isLoading])

    const { mutate: PDFConvo } = useMutation((data) => getPDFEventsApi(data), {
        onSuccess: (res) => setDataExportEvent(res.result),
        onError: (res) => console.log(res),
    })
    const exportsPDFEventsKIQS = (event, type) => {
        PDFConvo({ event, type })
        setKiqsDownload(true)
    }
    const { mutate: PDFAllKIQS } = useMutation((data) => getAllPDFCompaniesApi(data), {
        onSuccess: (res) => setPdfAllKIQS(res.result),
        onError: (res) => console.log(res),
    })
    const exportPDFAllKIQS = (data) => {
        PDFAllKIQS(data)
        setIsDownloadCompany(true)
    }

    return (
        <React.Fragment>
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            {kiqsDownload && (
                <div className="w-[40%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
                    <ExportCompaniesKIQs
                        event={data}
                        data={dataExportEvent}
                        type={kiqsView}
                        onClose={() => setKiqsDownload(false)}
                    />
                </div>
            )}
            {isDownloadCompany && (
                <div className="w-[40%] fixed right-0 top-8 bottom-0 z-30 h-screen custom-scroll bg-white animate_move_to_Left shadow-md">
                    <CompaniesExport
                        dataCon={pdfAllKIQS}
                        onClose={() => setIsDownloadCompany(false)}
                    />
                </div>
            )}
            {noData === false ? (
                <div className="h-full bg-white rounded-b-lg grow flex flex-col min-h-[300px]">
                    <div className="w-fit h-fit">
                        {imageGal ? (
                            <ImageGalleryComponent
                                data={attachments}
                                onClose={() => setImageGal(false)}
                                isLoading={isLoading}
                            />
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="w-full h-fit max-h-[75vh] flex flex-col items-left justify-start gap-1 px-8 pt-5 pb-4 overflow-y-auto">
                        <div className="flex">
                            <h5 className="w-full text-left font-bold text-main text-xl">
                                <SkeletonLoader isLoading={isLoading} height="35px" width="400px">
                                    <p className="w-full">{data.event_name}</p>
                                </SkeletonLoader>
                            </h5>
                            <button
                                ref={checkboxRef}
                                onClick={() => setToggleFilter(!toggleFilter)}
                                className="text-xs relative text-main whitespace-nowrap flex items-center h-fit cursor-pointer sm:my-0"
                            >
                                PDF{' '}
                                <span className="text-xl">
                                    <MdOutlineFileDownload />
                                </span>
                                {toggleFilter && (
                                    <div className="animate_fade_up z-50 mt-[6.5rem] -ml-14 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left  rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() => {
                                                exportsPDFEventsKIQS(data?.id, 0)
                                                setKiqsView(0)
                                            }}
                                        >
                                            Chatroom PDF{' '}
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() => {
                                                exportsPDFEventsKIQS(data?.id, 1)
                                                setKiqsView(1)
                                            }}
                                        >
                                            KIQs PDF{' '}
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() => {
                                                exportsPDFEventsKIQS(data?.id, 2)
                                                setKiqsView(2)
                                            }}
                                        >
                                            SnapIntel PDF{' '}
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                        <button
                                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                                            onClick={() => {
                                                exportPDFAllKIQS(data)
                                                setIsDownloadCompany(true)
                                            }}
                                        >
                                            Event PDF{' '}
                                            <span className="text-lg">
                                                <MdOutlineFileDownload />
                                            </span>
                                        </button>
                                    </div>
                                )}
                            </button>
                        </div>

                        <div className="w-full justify-evenly gap-1 py-2  flex sm:flex-col ">
                            <SkeletonLoader isLoading={isLoading} height="70px" width="15vw">
                                <button
                                    onClick={() => {
                                        setKiqsView(3)
                                        setSelectedButton(3)
                                    }}
                                    className={`w-1/4 sm:w-full text-center hover:scale-105 transition-all duration-300  p-3 text-main border border-main bg-white-25 rounded-md leading-5 ${
                                        selectedButton === 3 ? 'underline font-bold' : ''
                                    }`}
                                >
                                    General Discussion
                                </button>
                            </SkeletonLoader>
                            <SkeletonLoader isLoading={isLoading} height="70px" width="15vw">
                                <button
                                    onClick={() => handleKiqsChange(0)}
                                    className={`w-1/4 sm:w-full text-center hover:scale-105 transition-all duration-300  p-3 text-white bg-main rounded-md ${
                                        kiqsView === 0 ? 'underline font-bold' : ''
                                    }`}
                                >
                                    Chatroom KIQs
                                </button>
                            </SkeletonLoader>
                            <SkeletonLoader isLoading={isLoading} height="70px" width="15vw">
                                <button
                                    onClick={() => handleKiqsChange(1)}
                                    className={`w-1/4 sm:w-full text-center hover:scale-105 transition-all duration-300  p-3 text-white bg-[#333333] rounded-md ${
                                        kiqsView === 1 ? 'underline font-bold' : ''
                                    }`}
                                >
                                    My KIQs
                                </button>
                            </SkeletonLoader>
                            <SkeletonLoader isLoading={isLoading} height="70px" width="15vw">
                                <button
                                    onClick={() => handleKiqsChange(2)}
                                    className={`w-1/4 sm:w-full text-center hover:scale-105 transition-all duration-300  p-3 text-main bg-main bg-opacity-25 rounded-md ${
                                        kiqsView === 2 ? 'underline font-bold' : ''
                                    }`}
                                >
                                    SnapIntel
                                </button>
                            </SkeletonLoader>
                        </div>
                        <div className="w-full flex flex-col justify-center items-center">
                            {kiqsView === 4 ? (
                                <div
                                    onClick={() => setKiqsView(0)}
                                    className="text-sm text-[#777777] w-full text-right cursor-pointer hover:underline"
                                >
                                    Unhide
                                </div>
                            ) : (
                                <div
                                    onClick={() => setKiqsView(4)}
                                    className="text-sm text-[#777777] w-full text-right  cursor-pointer hover:underline"
                                >
                                    Hide
                                </div>
                            )}
                            {kiqsView === 0 ? (
                                <SkeletonLoader
                                    isLoading={isLoading || results[0].isLoading}
                                    height="70px"
                                    width="40vw"
                                >
                                    <div className="animate_fade_up custom-scroll custom-pagination mt-3 w-full border  border-main/70 rounded-md block z-10">
                                        <DataTable
                                            fixedHeader
                                            fixedHeaderScrollHeight="400px"
                                            data={results[0]?.data?.result}
                                            pagination
                                            columns={columnsChatroom}

                                            // onRowClicked={handleQuestionClick}
                                        />
                                        {questionToggle && results[0]?.data?.result.length > 0 && (
                                            <div>
                                                <DashboardQuestion
                                                    dataContainer={questionContainer}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </SkeletonLoader>
                            ) : kiqsView === 1 ? (
                                <SkeletonLoader
                                    isLoading={isLoading || results[1].isLoading}
                                    height="70px"
                                    width="40vw"
                                >
                                    <div className="animate_fade_up custom-scroll  custom-pagination mt-3 w-full border  border-main/70 rounded-md block z-10">
                                        <DataTable
                                            fixedHeader
                                            fixedHeaderScrollHeight="400px"
                                            data={results[1]?.data?.result}
                                            pagination
                                            columns={columnsMyKiqs}
                                        />
                                        {questionToggle && results[1]?.data?.result.length > 0 && (
                                            <div>
                                                <DashboardQuestion
                                                    dataContainer={questionContainer}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </SkeletonLoader>
                            ) : kiqsView === 2 ? (
                                <SkeletonLoader
                                    isLoading={isLoading || results[2].isLoading}
                                    height="70px"
                                    width="40vw"
                                >
                                    <div className="animate_fade_up custom-scroll  custom-pagination mt-3 w-full border border-main/70 rounded-md block z-10">
                                        <DataTable
                                            fixedHeader
                                            fixedHeaderScrollHeight="400px"
                                            data={results[2]?.data?.result.slice(0, 7)}
                                            pagination
                                            columns={columnsSnapIntel}
                                        />
                                        {questionToggle && results[2]?.data?.result.length > 0 && (
                                            <div>
                                                <DashboardQuestion
                                                    dataContainer={questionContainer}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </SkeletonLoader>
                            ) : kiqsView === 3 ? (
                                <div className="animate_fade_up custom-scroll max-h-[700px] h-fit custom-pagination mt-3 w-full  rounded-md block z-10">
                                    <GeneralDiscussion dataContainer={results[0]?.data} />
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {kiqsView === 3 ? null : (
                        <React.Fragment>
                            <div className="px-5 mt-4 flex gap-1 border-b  border-opacity-50">
                                <button
                                    onClick={() => setTab(0)}
                                    className={
                                        tab === 0
                                            ? ' text-sm transition-all duration-500 bg-main text-white w-fit shadow-sm px-1 py-1 rounded-tl-md rounded-tr-xl min-w-[80px]'
                                            : 'text-sm bg-white w-fit border border-b-0 border-[#777777]/40 shadow-sm px-1 py-1 rounded-tl-md rounded-tr-xl min-w-[80px]'
                                    }
                                >
                                    Images
                                </button>
                                <button
                                    onClick={() => setTab(1)}
                                    className={
                                        tab === 1
                                            ? ' text-sm transition-all duration-500 bg-main text-white w-fit shadow-sm px-1 py-1 rounded-tl-md rounded-tr-xl min-w-[80px]'
                                            : 'text-sm bg-white w-fit border border-b-0 border-[#777777]/40 border-opacity-50 shadow-sm px-1 py-1 rounded-tl-md rounded-tr-xl min-w-[80px]'
                                    }
                                >
                                    Files
                                </button>
                            </div>

                            <div className="w-full flex flex-col items-center justify-center py-5 custom-scroll">
                                {tab === 0 ? (
                                    <div className="overflow-x-auto w-11/12 px-8 py-5 flex gap-2 justify-center items-center shadow-inner bg-gray-200 rounded-lg bg-opacity-50 my-5">
                                        {isLoading
                                            ? 'Loading'
                                            : attachments?.length === 0
                                            ? 'No Images'
                                            : attachments &&
                                              attachments
                                                  //   .slice(0, 10)
                                                  .slice(0, isMobileView ? 5 : 13)
                                                  .map((image, imageIndex) =>
                                                      image.type === 'image' ? (
                                                          <LazyLoadImage
                                                              key={imageIndex}
                                                              className="cursor-pointer hover:scale-110 transition-all duration-300 h-20 aspect-square object-cover rounded-sm"
                                                              alt={`Image ${imageIndex}`}
                                                              src={image.filename}
                                                              PlaceholderSrc={image.filename}
                                                              effect="blur"
                                                              onClick={() =>
                                                                  setSelectedImage(image.filename)
                                                              }
                                                          />
                                                      ) : null
                                                  )}

                                        {attachments?.length > 7 && (
                                            <div
                                                onClick={() => setImageGal(true)}
                                                className="cursor-pointer hover:scale-110 transition-all duration-300 h-20 aspect-square bg-[#777777] flex flex-col px-5 items-center justify-center text-white rounded-md leading-5"
                                            >
                                                <span>
                                                    <AiOutlineEye />
                                                </span>
                                                <p className="text-center">Show More</p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <SkeletonLoader
                                        isLoading={isLoading}
                                        height="50px"
                                        width="40vw"
                                    >
                                        <div className="w-full flex flex-col gap-1 py-7 px-5 max-h-[300px] overflow-y-auto">
                                            {attachments?.length === 0 ? (
                                                <div className="w-full text-center text-sm">
                                                    There are no files attachment in this event...
                                                </div>
                                            ) : (
                                                attachments &&
                                                attachments.map((file, fileIndex) =>
                                                    file.type === 'file' ? (
                                                        <div className="flex w-full gap-2 items-center shadow-inner p-2 border border-[#c2c2c2] rounded-md">
                                                            <span>
                                                                <img
                                                                    className="w-5"
                                                                    src={PDFIcon}
                                                                    alt="TEXT_FILE"
                                                                />
                                                            </span>
                                                            <a
                                                                href={file.filename}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                key={fileIndex}
                                                                className="text-xs text-ellipsis truncate break-all hover:underline"
                                                            >
                                                                {file.filename}
                                                            </a>
                                                        </div>
                                                    ) : null
                                                )
                                            )}
                                        </div>
                                    </SkeletonLoader>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                    <div className="p-5 text-justify border-t">
                        <SkeletonLoader isLoading={isLoading} height="35px" width="200px">
                            <p
                                onClick={() => setShowGroups(!showGroups)}
                                className="font-semibold text-main flex items-center cursor-pointer"
                            >
                                Groups
                                <span>
                                    <AiOutlineCaretDown />
                                </span>
                            </p>
                        </SkeletonLoader>

                        {showGroups && (
                            <div className="w-full text-left flex text-main text-sm gap-1 flex-wrap">
                                {data.groups &&
                                    data.groups.map((group, index) => (
                                        <p
                                            key={index}
                                            className="w-fit px-2 border-[.6px] border-main bg-opacity-40 rounded-sm"
                                        >
                                            {group.group_name}
                                        </p>
                                    ))}
                            </div>
                        )}
                        <SkeletonLoader isLoading={isLoading} height="20px" count={3}>
                            <div className="text-sm py-2">{data.description}</div>
                        </SkeletonLoader>
                    </div>
                </div>
            ) : (
                <div className="h-full bg-white rounded-b-lg grow flex flex-col min-h-[300px]">
                    <div className="w-full h-full flex flex-col justify-center items-center py-10">
                        <img className="w-1/3 h-full" alt="No Existing Data" src={nodata} />
                        <p className="font-bold text-lg text-grey">No Existing Data</p>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default GeneralTab
