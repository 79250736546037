import { UnauthorizedUser } from 'Routes/UnauthorizedUser'
import { Container } from './container'

export const UnauthorizedAccessRoute = {
    element: <Container />,
    children: [
        {
            path: '/unauthorized_access',
            element: <UnauthorizedUser />,
        },
    ],
}
