import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { BiChevronDown } from 'react-icons/bi'
import Default from '../../../assets/Default_White.png'
import LoadingImage from '../../../assets/NexusBrain.png'
import { LoadingComponent } from 'components/LoadingComponent'

export const Events = ({ searchEvent, setTabs, setDataCon, isLoading }) => {
    const [display, setDisplay] = useState(true)
    const [eventGetter, setEventGetter] = useState()
    const [initialEvent, setInitialEvent] = useState()

    const [viewChange, setViewChange] = useState(true)

    const handleClick = (row) => {
        setDataCon(row)
        setTabs(0)
    }

    // useEffect(() => {
    //     if (searchEvent) {
    //         setDataCon(searchEvent[0])
    //     }
    //     console.log('RUNNNNN')
    // }, [searchEvent])

    useEffect(() => {
        const local = localStorage.getItem('selectedData')
        if (local) {
            setEventGetter(JSON.parse(local))
        } else {
            console.log('Item not found')
        }
    }, [setDataCon])

    useEffect(() => {
        const local = searchEvent && searchEvent[0]
        if (local) {
            localStorage.setItem('selectedData', JSON.stringify(local))
            setDataCon(local)
        } else {
            console.log('Item not found')
        }
    }, [searchEvent])

    const columns = [
        // {
        //   name: 'Event Name',
        //   selector: (row) => row.event_name,
        // },
        {
            name: 'Event',
            selector: (row) => row?.event_name,
            cell: (row) => (
                <>
                    {viewChange ? (
                        <div
                            onClick={() => handleClick(row)}
                            className="w-full h-fit flex justify-start items-start gap-x-2 min-h-[100px]"
                        >
                            <div className="h-full w-1/4 p-2 ">
                                {row.responses && row.responses.length > 0 ? (
                                    <div>
                                        {row.responses[0].attachments &&
                                        row.responses[0].attachments.length > 0 &&
                                        row.responses[0].attachments[0].type === 'image' ? (
                                            <img
                                                src={row.responses[0].attachments[0].filename}
                                                alt={row.responses[0].attachments[0].filename}
                                                className="cursor-pointer transition-all duration-300 max-h-[200px] max-w-[300px] rounded-md hover:opacity-70 object-contain w-full hover:object-scale-down"
                                            />
                                        ) : (
                                            <div>
                                                <img
                                                    src={Default}
                                                    className="rounded-md"
                                                    alt=" Data"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <img src={Default} className="rounded-md" alt=" Data" />
                                    </div>
                                )}
                            </div>

                            <div className="w-3/4 p-2">
                                <span
                                    // onClick={() => setDataCon(row)}
                                    className={`cursor-pointer font-bold text-main text-lg ${
                                        eventGetter?.event_name === row?.event_name && 'underline'
                                    }`}
                                >
                                    {row?.event_name}
                                </span>
                                <p>
                                    {!viewChange ? (
                                        <div>{row.created_at}</div>
                                    ) : (
                                        <div>
                                            <b>Date: </b> {row.created_at}
                                        </div>
                                    )}
                                </p>

                                <div className="truncate-multiline">
                                    <div className="mt-3 truncate-multiline">{row.description}</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p
                            // onClick={() => setDataCon(row)}
                            className="cursor-pointer font-bold"
                        >
                            {row?.event_name}
                        </p>
                    )}
                </>
            ),
        },
    ]

    return (
        <div className="w-full">
            <button
                onClick={() => setDisplay(!display)}
                disabled={searchEvent?.length == 0 && true}
                className="transition-all duration-200 cursor-pointer flex justify-between p-4 w-full bg-white rounded-md shadow-md animate_fade_up relative hover:bg-main hover:text-white z-10"
            >
                <div className="flex items-center">
                    <span className="text-xl">
                        <BiChevronDown />
                    </span>
                    <p>Events</p>
                </div>
                {searchEvent ? (
                    <p className="border-white border px-2 rounded-full font-bold shadow-md">
                        <React.Fragment>{searchEvent?.length ?? 0} </React.Fragment>
                    </p>
                ) : (
                    <img src={LoadingImage} className="w-[25px] animate_slow_spin" alt="Loading" />
                )}
            </button>

            {display && (
                <React.Fragment>
                    <div className="Card-Styling transition-all duration-200 cursor-pointer w-full bg-white shadow-md pt-4 pb-2 px-4 -mt-5 rounded-md animate_fade_up">
                        <DataTable
                            progressComponent={<LoadingComponent title={'Events'} />}
                            progressPending={!searchEvent}
                            data={searchEvent}
                            columns={columns}
                            noHeader={true}
                            fixedHeader
                            fixedHeaderScrollHeight="300px"
                            pagination
                            // onRowClicked={handleClick}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}
