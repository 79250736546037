import { PDFExport } from '@progress/kendo-react-pdf'
import React, { useRef, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import NexusLogo from '../../assets/NexusBrain.png'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { Divider } from '@mui/material'
import DataTable from 'react-data-table-component'
import { DownloadProgress } from 'components/DownloadingComponent'

const ExportContributors = ({ eventSelected, data, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [nameSearchable, setNameSearchable] = useState(true)
    const [emailSearchable, setEmailSearchable] = useState(true)
    const [responseSearchable, setResponseSearchable] = useState(true)
    const [companySearchable, setCompanySearchable] = useState(true)

    const [loading, setLoading] = useState()
    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }

    const columns = [
        {
            name: <p className="w-24 text-center">Name</p>,
            selector: (row) => (nameSearchable ? row.fullname : null),
            cell: (row) => <p className="text-xs">{row.fullname}</p>,
            sortable: true,
            sortFunction: (a, b) => a.fullname.localeCompare(b.fullname),
        },
        {
            name: <p className="w-24 text-center">Email</p>,
            selector: (row) => (emailSearchable ? row.email : null),
            cell: (row) => (
                <a
                    href={`mailto:${row.email}`}
                    className="text-blue-500 hover:underline text-[10px] whitespace-nowrap"
                >
                    {row.email}
                </a>
            ),
        },
        {
            name: <p className="w-24 text-center">Responses Count</p>,
            selector: (row) => (responseSearchable ? row.responses_count : null),
            cell: (row) => <p className="text-xs text-center">{row.responses_count}</p>,
            center: true,
            sortable: true,
        },
        {
            name: <p className="w-24 text-center">Company</p>,
            selector: (row) =>
                companySearchable ? row.companybelong && row.companybelong.firstname : null,
            cell: (row) => {
                const firstname = row.companybelong && row.companybelong.firstname
                return <p className="text-xs">{firstname}</p>
            },
            sortable: true,
        },
        {
            name: <p className="w-24 text-center">Groups</p>,
            selector: 'groups',
            sortable: false,
            cell: (row) => (
                <div className="flex flex-col items-left text-xs">
                    {row.groups &&
                        row.groups.map((group, index) => <p key={index}>{group.group_name}</p>)}
                </div>
            ),
        },
    ]

    return (
        <div className="fixed top-0 bottom-0 sm:w-full  right-0  overflow-auto bg-white z-50">
            <div className="w-full flex flex-col px-4 py-2 bg-white shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex justify-between px-4 py-2  items-center ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main text-white px-4 py-2 rounded-md text-sm"
                        >
                            Download PDF
                        </button>
                        <p className="text-main cursor-pointer " onClick={() => onClose()}>
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-sm text-center text-main pt-1 opacity-70 font-semibold">
                    Preview
                </p>
            </div>
            <PDFExport
                fileName={`Export_Contributors_List.pdf`}
                ref={pdfExportComponent}
                paperSize="A4"
                landscape={true}
            >
                <div className="w-full p-4">
                    <div className="flex items-center justify-center">
                        <img src={NexusLogo} alt="NexusLogo" className="w-8 h-8" />
                        <p className="text-main font-bold text-sm ml-2">Contributors List</p>
                    </div>
                    <DataTable className="table-styling" columns={columns} data={data ?? []} />
                </div>
            </PDFExport>
        </div>
    )
}

export default ExportContributors
