import React, { useMemo, useState } from 'react'
import '../../index.scss'
import 'leaflet/dist/leaflet.css'

import MapComponent from 'components/MapComponent'
import { useQuery } from 'react-query'
import { getGroupsApi } from 'services/request/Dashboard'
import { MdLocationOn } from 'react-icons/md'

function MapTab({ dataContainer }) {
    const [groupContainer, setGroupContainer] = useState(dataContainer.groups)

    // const { data, isLoading } = useQuery(['getGroupsApi'], getGroupsApi)
    // const { result } = useMemo(() => data ?? [], [data])

    return (
        <div className="bg-white h-fit min-h-[300px] rounded-b-lg py-4 ">
            <h1 className="font-bold text-main text-2xl px-5 flex">
                <span className="text-3xl text-main -rotate-[20deg] animate-pulse">
                    <MdLocationOn />
                </span>
                Groups Location{' '}
            </h1>
            <div className="h-fit w-full flex px-4 mt-5">
                <div className="h-full w-full  bg-white px-5 py-2 rounded-lg inner-shadow ">
                    <div className="flex h-fit">
                        <div className="w-full flex flex-col  p-2 gap-2">
                            <div className="w-full pb-5 pt-2">
                                <h5 className="w-full text-left font-bold text-main text-xl">
                                    {dataContainer?.event_name}
                                </h5>
                            </div>

                            <div className="w-full h-fit block z-0 rounded-xl overflow-hidden">
                                <MapComponent location={dataContainer?.groups} />
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-2 mt-3">
                        <span className="text-main font-bold">Groups: </span>
                        <div className="flex gap-2">
                            {dataContainer.groups &&
                                dataContainer.groups.map((group, index) => (
                                    <div
                                        key={index}
                                        className="w-fit px-2 border-[.6px] text-main border-main text-xs bg-opacity-40 rounded-sm"
                                    >
                                        <p className="font-bold ">{group.group_name}</p>
                                        <p className="flex">
                                            {' '}
                                            <span className="text-xs text-main">
                                                <MdLocationOn />
                                            </span>
                                            {group.location}
                                        </p>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="py-2">
                        <p className="font-bold text-lg text-main">Event Description</p>
                        <p>{dataContainer?.description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MapTab
