import { OAuthConfimation } from 'pages/OAuthConfimation'
import { Container } from './container'
import Login from 'pages/Login'

export const PublicRoutes = {
    path: '/',
    element: <Container />,
    children: [
        {
            index: true,
            element: <Login />,
        },
        {
            path: 'oauth/callback',
            element: <OAuthConfimation />,
        },
    ],
}
