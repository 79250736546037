import React, { useEffect, useMemo, useRef, useState } from 'react'
import Header from '../../components/Header'
import Papa from 'papaparse'
import ChatConvo from 'components/NumberOfEntries/ChatConvo'
import DataTable from 'react-data-table-component'
import MainNav from 'components/MainNav'
import { useQueries } from 'react-query'
import { TbFileExport, TbTableExport } from 'react-icons/tb'
import {
    getEventOnEntriesApi,
    getUserConvosApi,
    getUserOnEventApi,
} from 'services/request/NumberOfEntries'
import { LoadingComponent } from 'components/LoadingComponent'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import GeneralSnapIntel from './GeneralSnapIntel'
import { Checkbox } from 'components/Checkbox'
import { FaFilter } from 'react-icons/fa'
import { BiChevronDown } from 'react-icons/bi'
import { IoCloseOutline } from 'react-icons/io5'
import { MdOutlineFileDownload } from 'react-icons/md'
import { getExportSelectedEvent } from 'services/request/Exports'

function NumberOfEntries() {
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState()
    const [eventUsers, setEventUsers] = useState([])
    const [expandedQuestion, setExpandedQuestion] = useState(null)
    const [selectedEvent, setSelectedEvent] = useState()
    const [showConversation, setShowConversation] = useState(false)
    const [selectedUser, setSelectedUser] = useState()
    const [selectedType, setSelectedType] = useState()
    const [userConvos, setUserConvos] = useState()
    const [selectedAgent, setSelectedAgent] = useState()
    const [selectedButton, setSelectedButton] = useState(null)
    const [totalResponse, setTotalReponse] = useState({
        kiqs_count: 0,
        chatroom_count: 0,
        snapIntel_count: 0,
    })
    const checkboxRef = useRef(null)

    const conversationRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (conversationRef.current && !conversationRef.current.contains(event.target)) {
                // Clicked outside the conversation, close it
                setShowConversation(false)
                setSelectedUser(null)
                setSelectedType(null)
                setUserConvos(null)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [conversationRef])

    const [toggle, setToggle] = useState(false)

    const [eventSearchable, setEventSearchable] = useState(true)
    const [companySearchable, setCompanySearchable] = useState(true)

    const toggleChat = (user, type) => {
        setShowConversation(true)
        setSelectedButton(type)
        setSelectedUser(user)
        setSelectedType(type)
        setUserConvos([])
    }
    const arrayRes = useQueries([
        {
            queryKey: ['getEventonEntries'],
            queryFn: () => getEventOnEntriesApi(),
            onSuccess: (data) => {
                // Set the original data
                setFilteredData(data?.result)

                // Selecting the first array
                // if (data.result && data.result?.length > 0) {
                //     setSelectedEvent(data?.result[0])
                // }
            },
            // enabled: !!
        },
        {
            queryKey: ['getUsersOnEvent', selectedEvent],
            queryFn: () => getUserOnEventApi(selectedEvent),
            onSuccess: (data) => setEventUsers(data.result),
            enabled: !!selectedEvent,
        },
        {
            queryKey: ['getUserConvosApi', selectedUser],
            queryFn: () => getUserConvosApi(selectedEvent, selectedUser, selectedType),
            onSuccess: (data) => setUserConvos(data.result),
            enabled: !!selectedEvent,
        },
    ])

    useMemo(() => {
        const kiqsCount = eventUsers.reduce((total, item) => total + item.kiqs_count, 0)
        const snapIntelCount = eventUsers.reduce((total, item) => total + item.snapIntel_count, 0)
        const chatroomCount = eventUsers.reduce((total, item) => total + item.chatroom_count, 0)

        setTotalReponse((prevState) => {
            prevState.chatroom_count = chatroomCount
            prevState.kiqs_count = kiqsCount
            prevState.snapIntel_count = snapIntelCount
            return { ...prevState }
        })
    }, [selectedEvent, eventUsers])

    const customStyles = {
        rows: {
            style: {
                width: 'fit',
            },
        },
        headCells: {
            style: {
                width: 'fit',
            },
        },
        cells: {
            style: {
                width: 'fit',
            },
        },
    }

    const columns = [
        {
            name: 'Event Name',
            sortable: true,
            selector: (row) => (eventSearchable ? row.event_name : null),
            cell: (row) => (
                <div
                    onClick={() => {
                        setSelectedEvent(row)
                    }}
                    className={`cursor-pointer ${
                        selectedEvent && selectedEvent.event_name === row.event_name
                            ? 'font-bold hover:font-bold'
                            : ''
                    }`}
                >
                    {row.event_name}
                </div>
            ),
            // center: true,
        },
        {
            name: 'Company',
            sortable: true,
            selector: (row) => (companySearchable ? row.company?.firstname : null),
            cell: (row) => <span>{row.company?.firstname}</span>,
        },
    ]
    const handleQuestionClick = (questionIndex) => {
        if (expandedQuestion === questionIndex) {
            setExpandedQuestion(null)
        } else {
            setExpandedQuestion(questionIndex)
        }
    }

    const handleSearchChange = (value) => {
        setSearch(value)

        // Ensure result is defined before attempting to filter
        if (arrayRes[0].data.result) {
            const newFilteredData = arrayRes[0].data.result.filter((user) => {
                const isSearchMatch =
                    value.trim() === '' ||
                    columns.some((column) => {
                        if (typeof column.selector === 'function') {
                            const selectedValue = column.selector(user)
                            if (typeof selectedValue === 'string') {
                                return selectedValue.toLowerCase().includes(value.toLowerCase())
                            }
                        }
                        return false
                    })

                const isGroupSearchMatch =
                    value.trim() === '' ||
                    (user.groups &&
                        user.groups
                            .map((group) => group.group_name.toLowerCase())
                            .some((groupName) => groupName.includes(value.toLowerCase())))

                return isSearchMatch || isGroupSearchMatch
            })

            setFilteredData(newFilteredData)
        } else {
            // Handle the case when result is undefined
            console.error('result is undefined')
        }
    }
    // const exportData = (data, fileName) => {
    //     const csv = Papa.unparse(data)
    //     const exportedDataBlob = new Blob([csv], {
    //         type: 'text/csv;charset=utf-8;',
    //     })
    //     const downloadLink = document.createElement('a')
    //     downloadLink.href = window.URL.createObjectURL(exportedDataBlob)
    //     downloadLink.download = fileName
    //     downloadLink.click()
    // }

    const exportData = (data, fileName) => {
        const transformedData = data.map((row) => ({
            ...row,
            company: row.company && row.company.firstname,
            responses:
                row.responses &&
                row.responses
                    .map((response) => {
                        const responderName = response.user && response.user.fullname
                        const responseText = response.answer
                        const createdAt = response.created_at
                        return `"${responseText}" by ${responderName} on ${createdAt}\n`
                    })
                    .join('- '),
        }))

        const csv = Papa.unparse(transformedData)

        const exportedDataBlob = new Blob([csv], {
            type: 'text/csv;charset=utf-8;',
        })

        const downloadLink = document.createElement('a')
        downloadLink.href = window.URL.createObjectURL(exportedDataBlob)
        downloadLink.download = fileName
        downloadLink.click()
    }

    const exportAllData = () => {
        exportData(filteredData, 'Number of Entries Event List.csv')
    }

    const exportFilteredData = () => {
        exportData(filteredData, 'Number of Entries Filtered Event List.csv')
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
                setToggle(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <div className=" flex flex-col w-screen h-fit  md:w-full overflow-x-hidden">
            <div className="opacity-50 fixed w-full h-full bg-dashboard bg-[length:600px_300px] bg-center -z-10 brightness-[.8]"></div>
            <div className="w-full h-full flex gap-5 py-5 mb-10">
                <div className="w-full h-fit rounded-lg custom-scroll flex flex-col gap-4">
                    <div className="w-full px-5">
                        <MainNav />
                    </div>

                    <div className="w-full h-fit flex justify-between px-5 items-center sm:flex-col ">
                        <p className="font-bold text-2xl sm:pb-3">Number of Entries</p>
                        <div className="flex sm:flex-col w-2/4 items-center justify-center h-full gap-2">
                            <div className="flex w-full sm:gap-[19.5px] items-center justify-center">
                                <div className="w-full h-fit flex items-center justify-center">
                                    <input
                                        onChange={(e) => handleSearchChange(e.target.value)}
                                        type="text"
                                        value={search}
                                        className="border border-gray-400 rounded-md placeholder:text-sm pl-3 p-1 flex grow bg-white bg-opacity-60 outline-none"
                                        placeholder="Search User..."
                                    />

                                    {search.length > 0 && (
                                        <div
                                            onClick={() => handleSearchChange('')}
                                            className="w-fit px-4 py-2 flex bg-main text-white font-bold scale-105 bg-opacity-60 rounded-r-lg border-main cursor-pointer"
                                        >
                                            <IoCloseOutline />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="flex flex-col justify-start items-end"
                                    ref={checkboxRef}
                                >
                                    <div
                                        onClick={() => setToggle(!toggle)}
                                        className="transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex border border-gray-400 rounded-md py-2 px-4 bg-white shadow-inner text-main bg-opacity-50"
                                    >
                                        {' '}
                                        <div className="flex">
                                            <FaFilter /> <BiChevronDown />
                                        </div>
                                    </div>
                                    {toggle && (
                                        <div className="animate_fade_up z-20 absolute mt-9 transition-all gap-2 duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col border border-gray-400 rounded-md h-fit pt-2 pb-4 px-4 bg-white shadow-inner text-main">
                                            <p className="text-sm">Filter by:</p>
                                            <Checkbox
                                                key="Events"
                                                name="Event"
                                                title="Event"
                                                isChecked={eventSearchable}
                                                setValue={() =>
                                                    setEventSearchable(!eventSearchable)
                                                }
                                            />
                                            <Checkbox
                                                key="Company"
                                                name="Company"
                                                title="Company"
                                                isChecked={companySearchable}
                                                setValue={() =>
                                                    setCompanySearchable(!companySearchable)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {search.length === 0 && (
                                <button
                                    className=" bg-main px-3 py-1 rounded-md flex items-center text-white whitespace-nowrap"
                                    onClick={exportAllData}
                                >
                                    CSV{' '}
                                    <span className="text-lg">
                                        <MdOutlineFileDownload />
                                    </span>{' '}
                                </button>
                            )}
                            <button
                                className={`${
                                    search.length === 0 ? 'hidden' : ''
                                } text-lg bg-main px-3 py-2 rounded-md flex items-center gap-2 text-white whitespace-nowrap`}
                                disabled={search.length === 0}
                                onClick={exportFilteredData}
                            >
                                <TbTableExport /> <span className="text-sm">Export Filtered</span>
                            </button>
                        </div>
                    </div>
                    <div className="flex md:flex-col-reverse sm:flex-col-reverse  md:gap-y-10 sm:gap-y-4 xs:gap-y-10">
                        <div className="w-1/3 h-full custom-header px-4 md:w-full sm:w-full xs:w-full rounded-md">
                            <DataTable
                                progressComponent={<LoadingComponent title={'Entries'} />}
                                progressPending={arrayRes[0].isLoading}
                                columns={columns}
                                data={filteredData ? filteredData : arrayRes[0]?.data?.result}
                                pagination
                                fixedHeader
                                // fixedHeaderScrollHeight='100%'
                                customStyles={customStyles}
                            />
                        </div>
                        <div className="w-[45%] h-fit custom-header px-4 md:w-full sm:w-full xs:w-full">
                            {selectedEvent && (
                                <div className="flex flex-col gap-2">
                                    {arrayRes[1].isLoading ? (
                                        <span>
                                            <LoadingComponent title="Users" />
                                        </span>
                                    ) : (
                                        <div className="bg-white rounded-md">
                                            <div className="w-full pl-5 p-2 flex justify-between">
                                                <div>
                                                    <h1 className="text-lg">
                                                        <strong>Event: </strong>

                                                        {selectedEvent.event_name}
                                                    </h1>
                                                    <h1>
                                                        <strong>Agent List:</strong>
                                                    </h1>
                                                </div>
                                                <button
                                                    onClick={() =>
                                                        getExportSelectedEvent(selectedEvent)
                                                    }
                                                    className="text-sm mr-1 flex items-center hover:scale-105 transition-all duration-300 cursor-pointer text-main"
                                                >
                                                    CSV{' '}
                                                    <span className="text-xl">
                                                        <MdOutlineFileDownload />
                                                    </span>
                                                </button>
                                            </div>
                                            {eventUsers.map((user, agentIndex) => (
                                                <div key={agentIndex}>
                                                    <div
                                                        onClick={() => {
                                                            handleQuestionClick(agentIndex)
                                                            setSelectedAgent(user)
                                                        }}
                                                        className="transition-all duration-300 animate_fade_up relative z-0  border-b-1 w-full px-2 py-4 rounded-lg cursor-pointer border-t border-[#777777] border-opacity-40"
                                                    >
                                                        <div className="w-full pl-5">
                                                            <strong>Name : </strong>
                                                            {user.fullname}
                                                        </div>
                                                        <div className="flex w-full pl-5  ">
                                                            <button
                                                                className="flex w-2/3 gap-2"
                                                                onClick={() => toggleChat(user, 0)}
                                                                disabled={
                                                                    user.chatroom_count == 0
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                <strong
                                                                    className={`text-main ${
                                                                        selectedButton === 0 &&
                                                                        selectedUser === user
                                                                            ? 'underline'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    Chatroom KIQs:
                                                                </strong>
                                                                {user.chatroom_count}
                                                            </button>
                                                            {/* <div className="w-2/3 flex "> */}
                                                            <button
                                                                className="flex w-2/3 pl-8 sm:pl-5 gap-2"
                                                                onClick={() => toggleChat(user, 1)}
                                                                disabled={
                                                                    user.kiqs_count == 0
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                <strong
                                                                    className={`text-main ${
                                                                        selectedButton === 1 &&
                                                                        selectedUser === user
                                                                            ? 'underline'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    My KIQs:
                                                                </strong>
                                                                {user.kiqs_count}
                                                            </button>
                                                            {/* </div>
                                                            <div className="w-3/3   flex"> */}
                                                            <button
                                                                className="flex w-2/3 gap-2"
                                                                onClick={() => toggleChat(user, 2)}
                                                                disabled={
                                                                    user.snapIntel_count == 0
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                <strong
                                                                    className={`text-main ${
                                                                        selectedButton === 2 &&
                                                                        selectedUser === user
                                                                            ? 'underline'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    SnapIntel:
                                                                </strong>
                                                                {user.snapIntel_count}
                                                            </button>
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}

                            {showConversation && (
                                <div
                                    className="transition-all duration-[3000] fixed top-8  right-0 bottom-0 z-30 w-[70%] max-w-[500px] sm:w-full sm:max-w-none bg-white overflow-y-auto  animate_move_to_Left"
                                    ref={conversationRef}
                                >
                                    <ChatConvo
                                        selectedEvent={selectedEvent}
                                        onClose={() => setShowConversation(false)}
                                        userConvos={userConvos}
                                        isLoading={arrayRes[2].isLoading}
                                        agent={selectedAgent}
                                        selectedType={selectedType}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="w-[30%] mr-7  h-fit sm:items-center sm:justify-center sm:w-full sm:px-14 custom-header px-4 flex flex-col gap-5 md:flex-row sm:flex-row xs:flex-row">
                            <div className="bg-main rounded-lg h-32 sm:h-20 flex flex-col justify-center items-center sm:px-7 px-4 md:px-14 sm:leading-3">
                                <h1 className="text-left sm:text-center w-full text-white font-semibold sm:text-sm">
                                    Chatroom KIQs Responses
                                </h1>
                                <p className="text-left sm:text-center w-full text-white font-semibold text-3xl sm:text-sm">
                                    {totalResponse.chatroom_count}
                                </p>
                            </div>
                            <div className="bg-[#333333] rounded-lg h-32 sm:h-20 flex flex-col justify-center items-center sm:px-7 md:px-14 px-4 sm:leading-3">
                                <h1 className="text-left sm:text-center w-full text-white font-semibold  sm:text-sm">
                                    My KIQs Responses
                                </h1>
                                <p className="text-left sm:text-center w-full text-white font-semibold text-3xl sm:text-sm">
                                    {totalResponse.kiqs_count}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NumberOfEntries
