import Header from 'components/Header'
import { useAtomValue } from 'jotai'
import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { authAtom } from 'store/authAtom'

export const Container = () => {
    const auth = useAtomValue(authAtom)

    if (auth?.user?.role === 'admin' && auth?.user?.access === 1) {
        return (
            <React.Fragment>
                <Header>
                    <Outlet />
                </Header>
            </React.Fragment>
        )
    }

    return <Navigate to={'/unauthorized_access'} />
}
