import { createBrowserRouter } from 'react-router-dom'
import { PublicRoutes } from './Public'
import { PrivateRoutes } from './PrivateRoutes'
import { AdminPrivateRoutes } from './AdminPrivateRoutes'
import { NotFoundRoute } from './404notfound'
import { UnauthorizedAccessRoute } from './UnauthorizedAccess'

export const router = createBrowserRouter([
    AdminPrivateRoutes,
    PublicRoutes,
    PrivateRoutes,
    UnauthorizedAccessRoute,
    NotFoundRoute,
])
