import React from 'react'
import NBLogo from '../../assets/NexusBrain.png'

export const DownloadProgress = ({ progress }) => {
    return (
        <div className="fixed inset-0 bottom-0 right-0 top-0 left-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white bottom-10 right-10 shadow-lg fixed p-4 rounded-md flex items-center">
                <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-main"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l1.507-1.507A5.992 5.992 0 016 12H2c0 2.21.895 4.21 2.344 5.657L6 17.29zm10 2.42c1.449-1.449 2.344-3.449 2.344-5.657h-4c0 1.657-.673 3.157-1.763 4.243L16 19.71zm-2-2.42l-1.507-1.507A5.992 5.992 0 0112 16h4c0-1.657.673-3.157 1.763-4.243L14 9.29zm2-2.42L14.243 10.536A5.992 5.992 0 0116 12h-4a8.001 8.001 0 00-1.763-4.243L10 5.29z"
                    ></path>
                </svg>
                <div className="flex justify-between items-center w-full">
                    <span className="ml-2 text-base text-main">Downloading...</span>
                    <span className="ml-2 text-base text-main">{progress}</span>
                </div>
            </div>
        </div>
    )
}

export const DownloadingComponent = () => {
    return (
        <div className=" animate-pulse h-full w-full bg-gray-300 z-50 bg-opacity-40 rounded-lg shadow-md backdrop-blur-sm flex justify-center items-center">
            <div className="w-1/2 h-fit flex justify-center items-center flex-col">
                <div className="items-center justify-center flex flex-col gap-2">
                    <img src={NBLogo} alt="" className="w-[50px]  h-[50px]" />
                    <p className="text-center animate-pulse">File Downloading...</p>
                </div>
            </div>
        </div>
    )
}

// import React, { useState } from 'react'
// import axios from 'axios'

// const FileDownloader = ({ fileUrl, fileName }) => {
//     const [downloadProgress, setDownloadProgress] = useState(0)
//     const [downloadSuccess, setDownloadSuccess] = useState(false)

//     const downloadFile = async () => {
//         try {
//             const response = await axios.get(fileUrl, {
//                 responseType: 'blob',
//                 onDownloadProgress: (progressEvent) => {
//                     const percentCompleted = Math.round(
//                         (progressEvent.loaded * 100) / progressEvent.total
//                     )
//                     setDownloadProgress(percentCompleted)
//                 },
//             })

//             // Create a blob URL and trigger download
//             const blob = new Blob([response.data])
//             const blobUrl = window.URL.createObjectURL(blob)
//             const link = document.createElement('a')
//             link.href = blobUrl
//             link.download = fileName // Use the fileName prop
//             link.click()

//             setDownloadSuccess(true)
//         } catch (error) {
//             console.error('Error downloading file:', error)
//         }
//     }

//     return (
//         <div className="file-downloader-box">
//             <h2 className="file-downloader-title">File Downloader</h2>
//             <button className="download-button" onClick={downloadFile}>
//                 Download File
//             </button>
//             {downloadProgress > 0 && (
//                 <div className="progress-bar">
//                     <div className="progress-fill" style={{ width: `${downloadProgress}%` }}>
//                         {downloadProgress}%
//                     </div>
//                 </div>
//             )}
//             {downloadSuccess && <p className="success-message">File downloaded successfully! 🎉</p>}
//         </div>
//     )
// }

// export default FileDownloader
