import { atom } from 'jotai'
import { jwtDecode } from 'jwt-decode'

const getAuthenticationToken = () => {
    const token = localStorage.getItem('token')
    if (token) {
        try {
            const decodedToken = jwtDecode(token)
            // console.log('decoded token', decodedToken)
            return decodedToken
        } catch (error) {
            console.error('Invalid Token Error:', error)
        }
    }

    return null
}

const currentAuthAtom = atom(getAuthenticationToken())

export const authAtom = atom(
    (get) => get(currentAuthAtom),
    (get, set, update) => {
        const decodedToken = jwtDecode(JSON.stringify(update))

        set(currentAuthAtom, decodedToken)
        localStorage.setItem('token', JSON.stringify(update))
    }
)
