import React from 'react';
import NBLogo from '../assets/NexusBrain.png';

export const LoadingComponent = ({ title }) => {
  return (
    <div className='w-full h-full flex flex-col items-center py-10 animate-pulse'>
      <img src={NBLogo} alt='' className='w-[50px] h-[50px]' />
      <p>Loading {title}</p>
    </div>
  );
};
