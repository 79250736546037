import { axiosInstance } from 'services/axios'

export const getEventOnEntriesApi = async () => {
    const response = await axiosInstance.post('/api/v1/entries/get')
    return response.data
}

export const getUserOnEventApi = async (event) => {
    const response = await axiosInstance.post('/api/v1/entries/get_users', {
        id: event.id,
    })
    return response.data
}

export const getUserConvosApi = async (event, user, type) => {
    const response = await axiosInstance.post('/api/v1/entries/get_user_convos', {
        event_id: event.id,
        user_id: user.id,
        type: type,
    })
    return response.data
}

export const getUserGenDiscussionApi = async (data) => {
    const response = await axiosInstance.post('/api/v1/export_no_of_entries', {
        event_id: data.selectedEvent.id,
        user_id: data.agent.id,
        type: data.type,
        is_gd: data.is_gd,
        is_csv: data.is_csv,
    })
    return response.data
}
