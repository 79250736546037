import { PDFExport } from '@progress/kendo-react-pdf'
import { DownloadProgress } from 'components/DownloadingComponent'
import NexusLogo from '../../assets/NexusBrain.png'
import { useAtomValue } from 'jotai'
import { GlobalSearchAtom } from 'pages/MainDashboard'
import React, { useRef, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaWindowMinimize } from 'react-icons/fa'

const ExportGlobalSearch = ({ onClose, word }) => {
    const storedGlobalExport = useAtomValue(GlobalSearchAtom)
    const [displayEvents, setDisplayEvents] = useState(true)
    const [displayCGS, setDisplayCGS] = useState(true)
    const [displayQuestion, setDisplayQuestion] = useState(true)
    const [displayRes, setDisplayRes] = useState(true)
    const [displayUser, setDisplayUser] = useState(true)

    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()
    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 break-all hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        <div className=" top-0 bottom-0  right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full  flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex items-center justify-between ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                        >
                            {' '}
                            Download PDF{' '}
                        </button>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={() => onClose()}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                fileName={`Export Global Search - ${word}`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                <div className="w-full items-center mb-7 flex flex-col gap-2 p-2 ">
                    <div className=" pt-2 px-5 text-main flex gap-5 items-center">
                        <div>
                            <img src={NexusLogo} alt="NexusLogo" className="w-10" />
                        </div>
                        <div>
                            <p className="text-lg text-center w-full h-fit font-bold">
                                Global Search "{word}" Result
                            </p>{' '}
                        </div>
                    </div>
                    <div className="w-[440px] sm:w-full md:w-full">
                        <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                            <span className="flex">Events </span>
                            <span
                                onClick={() => setDisplayEvents(!displayEvents)}
                                className="font-normal cursor-pointer hover:scale-110"
                            >
                                <FaWindowMinimize style={{ color: '#fff' }} />
                            </span>
                        </p>
                        {!displayEvents && (
                            <p className="text-[.6rem] w-full text-center italic">
                                Events is Hidden - Event/s Count -{' '}
                                {storedGlobalExport?.data?.result?.events?.length}
                            </p>
                        )}
                        {displayEvents && (
                            <div className="flex w-full flex-col  p-3">
                                {storedGlobalExport?.data?.result?.events?.map(
                                    (event, eventIndex) => {
                                        return (
                                            <div key={eventIndex}>
                                                <p
                                                    className="text-base w-full text-main h-fit font-bold"
                                                    // className={`text-base w-full text-main h-fit font-bold ${
                                                    //     event?.event_name ===
                                                    //         storedGlobalExport?.selectedData
                                                    //             ?.event_name &&
                                                    //     'font-bold underline'
                                                    // } `}
                                                >
                                                    {event.event_name}
                                                </p>

                                                <p className="text-xs w-full h-fit ">
                                                    <b> Date: </b>
                                                    {event.created_at}
                                                </p>
                                                <p className="text-xs w-full h-fit block ">
                                                    {event.description}
                                                </p>
                                                <p className="w-full border-t border-main border-opacity-40 pt-2"></p>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        )}
                    </div>
                    <div className="w-[440px] sm:w-full md:w-full">
                        <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                            <span className="flex">Companies General SnapIntel </span>
                            <span
                                onClick={() => setDisplayCGS(!displayCGS)}
                                className="font-normal cursor-pointer hover:scale-110"
                            >
                                <FaWindowMinimize style={{ color: '#fff' }} />
                            </span>
                        </p>
                        {!displayCGS && (
                            <p className="text-[.6rem] w-full text-center italic">
                                Company General SnapIntel is Hidden - Company Geeral SnapIntel Count
                                - {storedGlobalExport?.data?.result?.general_snapintel?.length}
                            </p>
                        )}
                        {displayCGS && (
                            <div className="flex w-full flex-col  p-3">
                                {storedGlobalExport?.data?.result?.general_snapintel?.map(
                                    (cgs, cgsIndex) => {
                                        return (
                                            <div key={cgsIndex}>
                                                <p className="text-[12px] w-full  h-fit ">
                                                    {cgs?.firstname}
                                                </p>

                                                <p className="w-full border-t border-main border-opacity-40 pt-2"></p>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        )}
                    </div>

                    <div className="w-[440px] sm:w-full md:w-full">
                        <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                            <span className="flex">Questions </span>
                            <span
                                onClick={() => setDisplayQuestion(!displayQuestion)}
                                className="font-normal cursor-pointer hover:scale-110"
                            >
                                <FaWindowMinimize style={{ color: '#fff' }} />
                            </span>
                        </p>
                        {!displayQuestion && (
                            <p className="text-[.6rem] w-full text-center italic">
                                Questions is Hidden - Question/s Count -{' '}
                                {storedGlobalExport?.data?.result?.questions?.length}
                            </p>
                        )}
                        {displayQuestion && (
                            <div className="flex w-full flex-col  p-3">
                                {storedGlobalExport?.data?.result?.questions?.map(
                                    (quest, questIndex) => {
                                        return (
                                            <div key={questIndex}>
                                                <p className="text-[12px] w-full  h-fit ">
                                                    {quest?.event_name}
                                                </p>

                                                <p className="w-full border-t border-main border-opacity-40 pt-2"></p>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        )}
                    </div>
                    <div className="w-[440px] sm:w-full md:w-full">
                        <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                            <span className="flex">Responses </span>
                            <span
                                onClick={() => setDisplayRes(!displayRes)}
                                className="font-normal cursor-pointer hover:scale-110"
                            >
                                <FaWindowMinimize style={{ color: '#fff' }} />
                            </span>
                        </p>
                        {!displayRes && (
                            <p className="text-[.6rem] w-full text-center italic">
                                Responses is Hidden - Responses Count -{' '}
                                {storedGlobalExport?.data?.result?.responses?.length}
                            </p>
                        )}
                        {displayRes && (
                            <div className="flex w-full flex-col  p-3">
                                {storedGlobalExport?.data?.result?.responses?.map(
                                    (res, resIndex) => {
                                        return (
                                            <div key={resIndex}>
                                                <p className="text-base w-full text-main h-fit font-bold">
                                                    {res?.user.fullname}
                                                </p>
                                                <p
                                                    className="text-xs text-justify break-words"
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {res.answer &&
                                                        makeUrlsClickable(res.answer).map(
                                                            (part, index) => (
                                                                <React.Fragment key={index}>
                                                                    {part}
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                </p>
                                                <p className="text-xs pt-1 w-full h-fit ">
                                                    <b> Date: </b>
                                                    {res.created_at}
                                                </p>

                                                <p className="w-full border-t border-main border-opacity-40 pt-2"></p>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        )}
                    </div>
                    <div className="w-[440px] sm:w-full md:w-full">
                        <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                            <span className="flex">Users </span>
                            <span
                                onClick={() => setDisplayUser(!displayUser)}
                                className="font-normal cursor-pointer hover:scale-110"
                            >
                                <FaWindowMinimize style={{ color: '#fff' }} />
                            </span>
                        </p>
                        {!displayUser && (
                            <p className="text-[.6rem] w-full text-center italic">
                                Users is Hidden - User/s Count -{' '}
                                {storedGlobalExport?.data?.result?.users?.length}
                            </p>
                        )}
                        {displayUser && (
                            <div className="flex w-full flex-col  p-3">
                                {storedGlobalExport?.data?.result?.users?.map((usr, userIndex) => {
                                    return (
                                        <div key={userIndex}>
                                            <p className="text-base w-full text-main h-fit font-bold">
                                                {usr?.firstname + ' ' + usr?.lastname}
                                            </p>

                                            <p className="text-xs w-full h-fit ">
                                                {/* <b> Date: </b> */}
                                                {usr.email}
                                            </p>

                                            <p className="w-full border-t border-main border-opacity-40 pt-2"></p>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

export default ExportGlobalSearch
