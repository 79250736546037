import { Restricted } from 'pages/RestrictedPage'
import { Outlet } from 'react-router-dom'

export const NotFoundRoute = {
    element: <Outlet />,
    children: [
        {
            path: '*',
            element: <Restricted />,
        },
    ],
}
