import React from 'react'
import { RouterProvider } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import 'react-loading-skeleton/dist/skeleton.css'

import { router } from 'Routes'
import { SkeletonTheme } from 'react-loading-skeleton'

const client = new QueryClient()

function App() {
    return (
        <div>
            <QueryClientProvider client={client}>
                <SkeletonTheme baseColor="#dbdfda" highlightColor="#b7b7b7">
                    <RouterProvider router={router} />
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
                </SkeletonTheme>
            </QueryClientProvider>
        </div>
    )
}

export default App
