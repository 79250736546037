import React, { useState, useEffect } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { IoCloseCircleOutline, IoImagesOutline } from 'react-icons/io5'
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi'
import { SkeletonLoader } from './SkeletonLoader'

function ImageGallery({ onClose, data, isLoading }) {
    const [selectedImage, setSelectedImage] = useState() // Initialize selectedImage as null
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])

    useEffect(() => {
        // Set initial selected image to the first image in data (if it exists)
        if (data.length > 0 && data[0].type === 'image') {
            setSelectedImage(data[0].filename)
            setCurrentIndex(0)
        }
    }, [data])

    const handleLeftArrowClick = () => {
        const newIndex = (currentIndex - 1 + data.length) % data.length
        if (newIndex >= 0) {
            setCurrentIndex(newIndex)
            setSelectedImage(data[newIndex].filename)
        }
    }

    const handleRightArrowClick = () => {
        const newIndex = (currentIndex + 1) % data.length
        if (newIndex < data.length) {
            setCurrentIndex(newIndex)
            setSelectedImage(data[newIndex].filename)
        }
    }

    return (
        <div className="z-50 w-screen h-screen fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 backdrop-blur-sm">
            <span
                onClick={onClose}
                className="cursor-pointer absolute top-5 right-10 text-3xl text-white"
            >
                <AiOutlineCloseCircle />
            </span>
            <div className="fixed bottom-10 right-10 ">
                <p className="w-fit whitespace-normal flex justify-center items-center text-darkgrey">
                    Note: Click{' '}
                    <span className="mx-2">
                        <IoCloseCircleOutline />
                    </span>{' '}
                    or [ESC] key to close.
                </p>
            </div>
            <div className="w-full flex md:flex-col-reverse sm:flex-col-reverse xs:flex-col-reverse">
                <div className="w-1/2 py-10 md:w-full sm:w-full xs:w-full">
                    <div className="w-full px-5">
                        <p className=" bg-main p-3 rounded-md flex items-center gap-5 md:hidden sm:hidden xs:hidden">
                            <p className="text-white text-lg font-semibold">
                                <IoImagesOutline />
                            </p>
                            <p className="text-white text-lg font-semibold">Image Gallery</p>
                        </p>
                    </div>

                    <div className=" overflow-y-auto justify-left px-5 py-5 items-center flex gap-2 flex-wrap md:flex-nowrap sm:flex-nowrap xs:flex-nowrap md:overflow-x-auto sm:overflow-x-auto xs:overflow-x-auto">
                        {data &&
                            data.map(
                                (image, imageIndex) =>
                                    image.type === 'image' && (
                                        <SkeletonLoader
                                            isLoading={isLoading}
                                            width="120px"
                                            height="80px"
                                        >
                                            <img
                                                onClick={() => {
                                                    setSelectedImage(image.filename)
                                                    setCurrentIndex(imageIndex)
                                                }}
                                                className={`cursor-pointer hover:scale-110 transition-all duration-300 w-fit min-w-[50px] sm:min-w-[50px] h-20 sm:h-[65px] object- aspect-auto ${
                                                    selectedImage === image.filename
                                                        ? 'selected-image'
                                                        : ''
                                                }`}
                                                alt={imageIndex}
                                                src={image.filename}
                                            />
                                        </SkeletonLoader>
                                    )
                            )}
                    </div>
                </div>

                <div className="w-1/2 h-screen justify-between px-5 items-center flex gap-2 py-10 md:w-full sm:w-full xs:w-full md:py-5 sm:py-5 xs:py-5 md:h-[70vh] sm:h-[70vh] xs:h-[70vh]">
                    <span
                        onClick={handleLeftArrowClick} // Handle left arrow click
                        className="text-4xl transition-all duration-300 text-white cursor-pointer"
                    >
                        <HiOutlineChevronLeft />
                    </span>
                    {selectedImage === null ? (
                        <div className="shadow-lg bg-gray-200 h-[200px] w-[300px] bg-opacity-50 rounded-lg flex justify-center items-center ">
                            <p>Select an Image</p>
                        </div>
                    ) : (
                        <div className=" h-4/6 flex justify-center items-center">
                            <img
                                className="cursor-pointer hover:scale-105 transition-all duration-300 w-full h-fit max-h-[600px] sm:max-h-[400px] md:max-h-[300px]"
                                src={selectedImage}
                                alt="Selected"
                            />
                        </div>
                    )}
                    <span
                        onClick={handleRightArrowClick} // Handle right arrow click
                        className="text-4xl transition-all duration-300 text-white cursor-pointer"
                    >
                        <HiOutlineChevronRight />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ImageGallery
