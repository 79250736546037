import React, { useMemo, useState } from 'react'
import Papa from 'papaparse'
import { TbFileExport, TbLayoutKanban, TbTableExport } from 'react-icons/tb'
import GeneralTab from '../components/tabs/GeneralTab'
import MapTab from '../components/tabs/MapTab'
import { ChartTab } from '../components/tabs/ChartTab'
import DataTable from 'react-data-table-component'
import MainNav from 'components/MainNav'
import { useQueries, useQuery } from 'react-query'
import { getDashboardApi, globalSearchApi } from 'services/request/Dashboard'
import { LoadingComponent } from 'components/LoadingComponent'
import {
    Companies,
    CompaniesDisplay,
    GeneralSnapIntelDisplay,
} from 'components/AccordionDisplay/Companies'
import { Events } from 'components/AccordionDisplay/Events'
import { Questions, QuestionsDisplay } from 'components/AccordionDisplay/Questions'
import { Responses, ResponsesDisplay } from 'components/AccordionDisplay/Responses'
import { Users, UsersDisplay } from 'components/AccordionDisplay/Users'
import Default from '../assets/New_Placeholder.png'
import { atom, useAtom, useAtomValue } from 'jotai'
import { authAtom } from 'store/authAtom'
import GeneralSnapIntel from './SubPages/GeneralSnapIntel'

export const searchAtom = atom('')

function Dashboard() {
    const [tab, setTab] = useState(0)
    const [selectedImage, setSelectedImage] = useState(null)
    const [viewChange, setViewChange] = useState(true)
    const [search, setSearch] = useAtom(searchAtom)
    const [noData, setNoData] = useState(true)
    const { user } = useAtomValue(authAtom)
    const [companiesContainer, setCompaniesContainer] = useState()
    const [questionsContainer, setQuestionsContainer] = useState()
    const [usersContainer, setUsersContainer] = useState()
    const [responsesContainer, setResponsesContainer] = useState()

    const { data, isLoading } = useQuery(['getDashboardApi'], getDashboardApi, {
        onSuccess: (res) => {
            setDataCon(res.result[0])
            setNoData(false)
        },
    })

    const globalSearch = useQueries([
        {
            queryKey: ['GlobalSearchApi', search],
            queryFn: () => globalSearchApi(search),
        },
    ])

    const globalSearchResult = useMemo(
        () => (globalSearch[0].data ? globalSearch[0].data?.result : []),
        [globalSearch]
    )

    const { result } = useMemo(() => data ?? [], [data])

    const [filteredData, setFilteredData] = useState(result)
    const [dataCon, setDataCon] = useState([])

    const columns = [
        {
            name: 'Event',
            sort: true,
            selector: (row) => row.event_name,
            cell: (row) => (
                <>
                    {viewChange ? (
                        <div className="w-full h-fit flex justify-start items-start gap-x-2 min-h-[200px]">
                            <div className="h-full w-1/3 grow p-2 ">
                                {row.responses && row.responses.length > 0 ? (
                                    <div>
                                        {row.responses.some(
                                            (response) =>
                                                response?.attachments.length > 0 &&
                                                response?.attachments[0].type === 'image'
                                        ) ? (
                                            row.responses
                                                .filter((response) =>
                                                    response.attachments.some(
                                                        (attachment) => attachment.type === 'image'
                                                    )
                                                )
                                                .slice(0, 1)
                                                .map((response, index) => {
                                                    const imageAttachment =
                                                        response.attachments.find(
                                                            (attachment) =>
                                                                attachment.type === 'image'
                                                        )

                                                    if (imageAttachment) {
                                                        return (
                                                            <img
                                                                key={index}
                                                                src={imageAttachment.filename}
                                                                alt={imageAttachment.filename}
                                                                className="cursor-pointer transition-all duration-300 h-[200px] max-w-[300px] rounded-md hover:opacity-70 object-cover w-full hover:object-scale-down"
                                                            />
                                                        )
                                                    }

                                                    return null
                                                })
                                        ) : (
                                            <div>
                                                <img
                                                    src={Default}
                                                    className="cursor-pointer transition-all duration-300 h-[200px] max-w-[300px] rounded-md  object-cover w-full "
                                                    alt="Image Data"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <img
                                            src={Default}
                                            className="cursor-pointer transition-all duration-300 h-[200px] max-w-[300px] rounded-md  object-cover w-full "
                                            alt="Image Data"
                                        />
                                    </div>
                                )}

                                {/* {row.responses && row.responses.length > 0 ? (
                                    <div className="max-h-[200px]">
                                        {row.responses?.attachments &&
                                        row.responses?.attachments.length > 0 ? (
                                            row.responses.attachments[0].map((attachment, index) =>
                                                attachment.type === 'image' ? (
                                                    <img
                                                        key={index}
                                                        src={attachment.filename}
                                                        alt={attachment.filename}
                                                        className="cursor-pointer transition-all duration-300 max-h-[200px] max-w-[300px] rounded-md hover:opacity-70 object-none w-full hover:object-scale-down"
                                                    />
                                                ) : null
                                            )
                                        ) : (
                                            <div>
                                                <img
                                                    src={Default}
                                                    className="rounded-md"
                                                    alt="Image Data"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <img
                                            src={Default}
                                            className="rounded-md"
                                            alt="Image Data"
                                        />
                                    </div>
                                )} */}
                            </div>

                            <div className="w-2/3 p-2">
                                <span
                                    onClick={() => setDataCon(row)}
                                    className="cursor-pointer font-bold text-main text-lg"
                                >
                                    {row?.event_name}
                                </span>
                                <p>
                                    {!viewChange ? (
                                        <div>{row.created_at}</div>
                                    ) : (
                                        <div>
                                            <b>Date: </b> {row.created_at}
                                        </div>
                                    )}
                                </p>
                                {viewChange ? (
                                    <div className="truncate-multiline">
                                        <div className="mt-3">{row.description}</div>
                                    </div>
                                ) : (
                                    <p
                                        className="cursor-pointer hover:text-blue-600"
                                        onClick={() => setDataCon(row)}
                                    >
                                        View Description
                                    </p>
                                )}
                            </div>
                        </div>
                    ) : (
                        <p onClick={() => setDataCon(row)} className="cursor-pointer font-bold">
                            {row?.event_name}
                        </p>
                    )}
                </>
            ),
        },
    ]

    const handleSearchChange = (value) => {
        setSearch(value)
        const newFilteredData = result.filter(
            (user) =>
                value.trim() === '' ||
                columns.some((column) => {
                    if (typeof column.selector === 'function') {
                        const selectedValue = column.selector(user)
                        if (typeof selectedValue === 'string') {
                            return selectedValue.toLowerCase().includes(value.toLowerCase())
                        }
                    }
                    return false
                })
        )
        setFilteredData(newFilteredData)

        if (newFilteredData?.length > 0) {
            setDataCon(newFilteredData[0])
        }
    }

    const exportData = (data, fileName) => {
        const csv = Papa.unparse(data)
        const exportedDataBlob = new Blob([csv], {
            type: 'text/csv;charset=utf-8;',
        })
        const downloadLink = document.createElement('a')
        downloadLink.href = window.URL.createObjectURL(exportedDataBlob)
        downloadLink.download = fileName
        downloadLink.click()
    }

    const exportAllData = () => {
        exportData(result, 'all_data.csv')
    }

    const exportFilteredData = () => {
        exportData(filteredData, 'filtered_data.csv')
    }

    return (
        <div className="flex flex-col overflow-x-hidden">
            {selectedImage && (
                <div className="fixed top-0 left-0 w-screen h-screen z-20 flex items-center justify-center bg-black bg-opacity-70">
                    <img
                        className="max-h-80 max-w-80"
                        src={selectedImage}
                        alt="Event Images"
                        onClick={() => setSelectedImage(null)}
                    />
                </div>
            )}

            <div className="opacity-50 fixed w-full h-full bg-dashboard bg-[length:600px_300px] bg-center -z-10 brightness-[.8]"></div>
            <div className="w-full h-fit flex flex-col justify-center items-center pt-2">
                <div className="w-2/3 md:w-11/12 sm:w-11/12">
                    <p className="w-full font-bold text-lg">Global Search</p>
                    <div className="w-full h-fit flex gap-2">
                        <input
                            onChange={(e) => handleSearchChange(e.target.value)}
                            type="text"
                            value={search}
                            className="border w-full border-gray-400 h-8 outline-main rounded-lg px-4 py-6 flex bg-white bg-opacity-60 "
                            placeholder="Search : Events, General SnapIntel, Questions, Responses, Users..."
                        />
                        {/* <NavLink
                            target="__blank"
                            to={`https://www.google.com/search?q=${search}`}
                            className="transition-all duration-300 w-2/12 bg-white shadow-md rounded-md py-1 flex justify-center items-center gap-2 text-sm hover:bg-main hover:text-white"
                        >
                            <span>
                                <BsBrowserChrome />
                            </span>
                            <button className="text-left flex">Browser Search</button>
                        </NavLink> */}
                    </div>
                </div>
            </div>
            <div className="w-full flex py-4 pl-4 pr-8 gap-x-4 md:flex-col sm:flex-col">
                <div className="w-1/2 h-full rounded-lg custom-scroll sm:w-full md:w-full sm:mb-10 md:mb-10">
                    <div className="h-fit w-full grow flex md:flex-col sm:flex-col">
                        <MainNav />
                    </div>

                    <div className="w-full flex justify-end items-end gap-2 ">
                        <div className="flex w-1/3 items-center justify-end  gap-2">
                            <button
                                className="  text-white text-lg bg-main px-3 py-2 rounded-md flex items-center gap-2 whitespace-nowrap"
                                onClick={() => setViewChange(!viewChange)}
                            >
                                <TbLayoutKanban />
                                <span className="text-sm">View</span>
                            </button>
                            <button
                                className="  text-white text-lg bg-main px-3 py-2 rounded-md flex items-center gap-2 whitespace-nowrap"
                                onClick={exportAllData}
                            >
                                <TbFileExport />
                                <span className="text-sm">Export All</span>
                            </button>
                            <button
                                className={`${
                                    search?.length === 0 ? 'hidden' : ''
                                }  text-white text-lg bg-main px-3 py-2 rounded-md flex items-center gap-2 whitespace-nowrap`}
                                disabled={search?.length === 0}
                                onClick={exportFilteredData}
                            >
                                <TbTableExport />
                                <span className="text-sm">Export Filtered</span>
                            </button>
                        </div>
                    </div>

                    <div className="h-fit flex flex-col w-full overflow-y-auto gap-y-2 mt-4 rounded-sm overflow-hidden">
                        {search?.length === 0 ? (
                            <div className={viewChange ? 'Card-Styling' : ''}>
                                <DataTable
                                    progressComponent={<LoadingComponent title={'Entries'} />}
                                    progressPending={isLoading}
                                    data={filteredData ? filteredData : result}
                                    columns={columns}
                                    // pagination
                                    fixedHeader={viewChange ? true : true}
                                    fixedHeaderScrollHeight="65vh"
                                />
                            </div>
                        ) : (
                            <div className="flex flex-col gap-2">
                                <Events
                                    searchEvent={globalSearchResult.events}
                                    setTabs={setTab}
                                    setDataCon={setDataCon}
                                    isLoading={isLoading}
                                />
                                <Companies
                                    searchCompanies={globalSearchResult.general_snapintel}
                                    setTabs={setTab}
                                    setCompaniesContainer={setCompaniesContainer}
                                />
                                <Questions
                                    searchQuestions={globalSearchResult.questions}
                                    setTabs={setTab}
                                    setQuestionsContainer={setQuestionsContainer}
                                />
                                <Responses
                                    searchResponses={globalSearchResult.responses}
                                    setTabs={setTab}
                                    search={search}
                                    setResponsesContainer={setResponsesContainer}
                                />
                                <Users
                                    searchUsers={globalSearchResult.users}
                                    setTabs={setTab}
                                    setUsersContainer={setUsersContainer}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="w-1/2 h-fit rounded-lg mb-10 shadow-md md:w-full sm:w-full overflow-hidden">
                    {tab < 3 && (
                        <div className="flex w-full gap-1 text-xs">
                            <div className="w-fit">
                                <button
                                    onClick={() => setTab(0)}
                                    className={
                                        tab === 0
                                            ? ' transition-all duration-500 min-w-[80px] bg-main text-white w-fit shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                            : 'bg-white w-fit min-w-[80px] shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                    }
                                >
                                    General
                                </button>
                            </div>
                            <div className="w-fit">
                                <button
                                    onClick={() => setTab(1)}
                                    className={
                                        tab === 1
                                            ? ' transition-all duration-500 min-w-[80px] bg-main text-white w-fit shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                            : 'bg-white w-fit min-w-[80px] shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                    }
                                >
                                    Map
                                </button>
                            </div>
                            <div className="w-fit">
                                <button
                                    onClick={() => setTab(2)}
                                    className={
                                        tab === 2
                                            ? ' transition-all duration-500 min-w-[80px] bg-main text-white w-fit shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                            : 'bg-white w-fit min-w-[80px] shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                    }
                                >
                                    Analytics
                                </button>
                            </div>
                        </div>
                    )}

                    {tab === 0 ? (
                        <GeneralTab
                            data={dataCon ? dataCon : result[0]}
                            noData={noData}
                            isLoading={isLoading}
                        />
                    ) : tab === 1 ? (
                        <MapTab dataContainer={dataCon ? dataCon : result[0]} />
                    ) : tab === 2 ? (
                        <ChartTab dataContainer={dataCon ? dataCon : result[0]} />
                    ) : tab === 3 ? (
                        user?.role === 'admin' ? (
                            <CompaniesDisplay
                                dataContainer={companiesContainer}
                                defaultQuestions={globalSearchResult?.default_questions}
                            />
                        ) : (
                            <GeneralSnapIntelDisplay
                                selectedQuestion={companiesContainer}
                                defaultQuestions={globalSearchResult?.default_questions}
                            />
                        )
                    ) : tab === 4 ? (
                        <QuestionsDisplay dataContainer={questionsContainer} />
                    ) : tab === 5 ? (
                        <ResponsesDisplay dataContainer={responsesContainer} />
                    ) : (
                        <UsersDisplay dataContainer={usersContainer} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Dashboard
