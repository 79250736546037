import React, { useState } from 'react'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'

import PDFIcon from '../../assets/pdf.png'
import { NavLink } from 'react-router-dom'
import ImageView from 'components/ImageView'

export const GeneralSnapIntel = ({ responses, questions }) => {
    const [collapse, setCollapse] = useState(true)
    const [selectedImage, setSelectedImage] = useState()

    const makeUrlsClickable = (text) => {
        if (!text) {
            return { __html: '' } // Return an empty string or handle the null case accordingly
        }

        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g

        // Replace matched URLs with clickable <a> tags
        const replacedText = text.replace(
            urlRegex,
            (url) =>
                `<a class="hover:text-blue-600 hover:underline break-all font-bold" href="${url}" target="_blank"><br/> <br/> ${url} <br/> <br/></a>`
        )

        return { __html: replacedText }
    }

    return (
        <div className="w-full shadow-md rounded-md">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            <div className="w-full bg-main py-2 px-2 flex justify-between items-center rounded-t-md">
                <p className="font-semibold text-white">General SnapIntel Conversation</p>
            </div>
            {questions.length > 0 ? (
                <div className="w-full h-fit max-h-[350px] overflow-y-auto px-5 flex flex-col gap-3 py-5 bg-gray-200 rounded-b-md">
                    {questions.map((question) => (
                        <div key={question.id} className="flex w-full flex-col gap-3">
                            <div className="flex w-full items-center gap-2 px-2">
                                <div className="text-xs bg-main bg-opacity-30 p-2 rounded-r-md rounded-tl-md shadow-sm">
                                    {question.question}
                                </div>
                            </div>
                            <div
                                onClick={() => setCollapse(!collapse)}
                                className="cursor-pointer pl-5 flex gap-2 items-center text-xs hover:text-[14px]"
                            >
                                <span
                                    className="rotate-90 text-md -mt-2 cursor-pointer"
                                    onClick={() => setCollapse(!collapse)}
                                >
                                    <MdOutlineSubdirectoryArrowLeft />
                                </span>
                                <span className=" ">{collapse ? 'Hide' : 'Show'} Responses</span>
                            </div>
                            {collapse && (
                                <React.Fragment>
                                    {responses
                                        .filter((response) => question.id === response.question_id)
                                        .map((response, index) => (
                                            <div
                                                key={index}
                                                className="flex w-full items-center justify-end gap-2 px-2"
                                            >
                                                <div className="">
                                                    <div className="text-xs py-1 font-semibold text-end">
                                                        {response.user.fullname}
                                                    </div>
                                                    <div className="text-xs w-fit text-left bg-white bg-opacity-30 p-2 rounded-l-md rounded-tr-md shadow-sm justify-end gap-2 flex flex-col items-end">
                                                        <div key={index}>
                                                            <p
                                                                className="whitespace-break-spaces"
                                                                dangerouslySetInnerHTML={makeUrlsClickable(
                                                                    response.answer
                                                                )}
                                                            />
                                                        </div>
                                                        {response.attachments &&
                                                            response.attachments.length > 0 && (
                                                                <React.Fragment>
                                                                    {response.attachments.map(
                                                                        (attachment) => (
                                                                            <div
                                                                                className="flex items-end justify-end"
                                                                                key={attachment.id}
                                                                            >
                                                                                {attachment.type ===
                                                                                'image' ? (
                                                                                    <img
                                                                                        src={
                                                                                            attachment.filename
                                                                                        }
                                                                                        alt="Attachment"
                                                                                        className="h-[150px] cursor-pointer hover:scale-105 transition-all duration-300"
                                                                                        onClick={() =>
                                                                                            setSelectedImage(
                                                                                                attachment.filename
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                ) : attachment.type ===
                                                                                  'file' ? (
                                                                                    <NavLink
                                                                                        to={
                                                                                            attachment.filename
                                                                                        }
                                                                                        target="__blank"
                                                                                        className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                    >
                                                                                        <img
                                                                                            className="w-8"
                                                                                            src={
                                                                                                PDFIcon
                                                                                            }
                                                                                            alt="TEXT_FILE"
                                                                                        />
                                                                                        <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                            {
                                                                                                attachment.filename
                                                                                            }
                                                                                        </p>
                                                                                    </NavLink>
                                                                                ) : null}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </React.Fragment>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="min-h-[300px] text-2xl text-gray-400 w-full flex justify-center items-center font-semibold">
                    Select a question
                </div>
            )}
        </div>
    )
}
