import React, { Fragment, useEffect, useRef, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { FaFilter } from 'react-icons/fa'
import { Checkbox } from './Checkbox'
import { atom, useAtom } from 'jotai'

export const checkboxesValueAtom = atom({
    events: true,
    genSnapIntel: true,
    questions: true,
    response: true,
    user: true,
})

export const CheckboxFilter = ({ onCheckboxChange }) => {
    const [toggle, setToggle] = useState(false)
    const [toggledValues, setToggleValues] = useAtom(checkboxesValueAtom)
    const checkboxRef = useRef(null)

    const handleCheckboxChange = (value, name) => {
        setToggleValues({ ...toggledValues, [name]: value })
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
                setToggle(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <div className="flex flex-col justify-start items-end z-[20]" ref={checkboxRef}>
            <div
                onClick={() => setToggle(!toggle)}
                className="transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex border border-gray-400 rounded-md py-4 px-4 bg-white shadow-inner text-main bg-opacity-50"
            >
                {' '}
                <div className="flex">
                    <FaFilter /> <BiChevronDown />
                </div>
            </div>
            {toggle && (
                <div className="animate_fade_up absolute mt-14 transition-all gap-2 duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col border border-gray-400 rounded-md h-fit py-4 px-4 bg-white shadow-inner text-main">
                    <Checkbox
                        name="events"
                        isChecked={toggledValues.events}
                        title={'Events'}
                        setValue={handleCheckboxChange}
                    />
                    <Checkbox
                        name="genSnapIntel"
                        isChecked={toggledValues.genSnapIntel}
                        value="SnapIntel"
                        title={'Gen. SnapIntel'}
                        setValue={handleCheckboxChange}
                    />
                    <Checkbox
                        name="questions"
                        isChecked={toggledValues.questions}
                        value="Questions"
                        title={'Questions'}
                        setValue={handleCheckboxChange}
                    />
                    <Checkbox
                        name="response"
                        isChecked={toggledValues.response}
                        value="Response"
                        title={'Response'}
                        setValue={handleCheckboxChange}
                    />
                    <Checkbox
                        name="user"
                        isChecked={toggledValues.user}
                        value="Users"
                        title={'Users'}
                        setValue={handleCheckboxChange}
                    />
                </div>
            )}
        </div>
    )
}
