import React, { Fragment, useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaFile, FaWindowMinimize } from 'react-icons/fa'
import PDFIcon from '../../assets/pdf.png'
import { Link, NavLink } from 'react-router-dom'
import { DownloadProgress } from 'components/DownloadingComponent'
import ImageView from 'components/ImageView'
import { GoFile } from 'react-icons/go'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'

export const AnalyticsExport = ({ data, dataQuest, dataReact, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])

    const [selectedImage, setSelectedImage] = useState()
    const [kiqsType, setKiqsType] = useState(0)
    const [itemHolder, setItemHolder] = useState(data[0] ?? [])
    const [displayChat, setDisplayChat] = useState(true)
    const [displayKiqs, setDisplayKiqs] = useState(true)
    const [displaySnap, setDisplaySnap] = useState(true)
    const [displayUser, setDisplayUser] = useState(true)
    const [displayQuest, setDisplayQuest] = useState(true)
    const [displayReact, setDisplayReact] = useState(true)
    const [dataContainer, setDataContainer] = useState(dataQuest)
    const [selectedItem, setSelectedItem] = useState()

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    return (
        <div className=" top-0 bottom-0  right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex items-center justify-between ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                        >
                            Download PDF
                        </button>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                // fileName={`Export Spaces and Events - ${data?.event_name}`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                {selectedImage != null && (
                    <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
                )}

                <div className=" items-center mb-7 flex flex-col gap-2 p-2 w-[440px] sm:w-full md:w-full ">
                    <div className="w-full text-white rounded-md h-fit bg-main flex items-center justify-between p-2">
                        <p className=" font-semibold text-lg italic">Users with Most Response</p>
                        <span
                            onClick={() => setDisplayUser(!displayUser)}
                            className="font-normal mb-1 cursor-pointer hover:scale-110"
                        >
                            <FaWindowMinimize style={{ color: '#fff' }} />
                        </span>
                    </div>
                    {!displayUser && (
                        <p className="text-[.6rem] w-full text-center italic">
                            User With Most Responses is Hidden
                        </p>
                    )}
                    {displayUser && (
                        <div className="w-full items-center justify-center flex flex-col">
                            <div className="h-fit overflow-y-auto overflow-x-hidden items-center w-full  px-5 py-2 flex flex-col gap-2 ">
                                {data &&
                                    data.map((item, itemIndex) => (
                                        <div
                                            onClick={() => setItemHolder(item)}
                                            className="animate_fade_up text-sm flex items-center justify-between shadow-md rounded-md w-[80%] py-2 px-2 bg-[#e7e7e7] hover:bg-[#cfcfcf] cursor-pointer"
                                        >
                                            <span className="w-2/3 overflow-x-auto">
                                                <p
                                                    className={`${
                                                        item?.fullname === itemHolder?.fullname &&
                                                        'font-semibold underline'
                                                    } text-sm`}
                                                >
                                                    {item.fullname}
                                                </p>
                                                <p className="text-xs font-semibold text-main">
                                                    {item.companybelong.firstname}
                                                </p>
                                            </span>
                                            <span className="w-fit px-5 flex justify-end border-l border-main ">
                                                <p>{item?.responses_count}</p>
                                            </span>
                                        </div>
                                    ))}
                            </div>

                            <div className="w-[440px] sm:w-full md:w-full">
                                <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    <span className="flex">CHATROOM </span>
                                    <span
                                        onClick={() => setDisplayChat(!displayChat)}
                                        className="font-normal cursor-pointer hover:scale-110"
                                    >
                                        <FaWindowMinimize style={{ color: '#fff' }} />
                                    </span>
                                </p>
                                {!displayChat && (
                                    <p className="text-[.6rem] w-full text-center italic">
                                        Chatroom is Hidden - Response/s Count -{' '}
                                        {itemHolder?.chatroom_responses.length}
                                    </p>
                                )}
                                {displayChat && (
                                    <>
                                        {
                                            // kiqsType == 0 &&
                                            itemHolder?.chatroom_responses &&
                                                itemHolder?.chatroom_responses.map(
                                                    (convo, convoIndex) => (
                                                        <div className="animate_fade_up w-full text-xs rounded-md bg-gray-200 mb-1 shadow-sm px-5 py-3 break-words">
                                                            <p
                                                                className="text-sm text-justify break-words"
                                                                style={{
                                                                    whiteSpace: 'pre-line',
                                                                }}
                                                            >
                                                                {convo.answer &&
                                                                    makeUrlsClickable(
                                                                        convo.answer
                                                                    ).map((part, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {part}
                                                                        </React.Fragment>
                                                                    ))}
                                                            </p>
                                                            <div className="w-full">
                                                                {convo.attachments &&
                                                                    convo.attachments.map(
                                                                        (attach) =>
                                                                            attach.type ==
                                                                            'image' ? (
                                                                                <img
                                                                                    className="h-28 aspect-auto object-cover flex w-28 hover:object-contain "
                                                                                    src={
                                                                                        attach.filename
                                                                                    }
                                                                                    alt={attach}
                                                                                />
                                                                            ) : (
                                                                                <div className="h-28 w-full py-5 flex break-all text-xs">
                                                                                    <img
                                                                                        alt="PDF Icon"
                                                                                        className="h-full w-fit"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                    <div className="w-full">
                                                                                        <p className="font-bold text-darkgrey">
                                                                                            Filename
                                                                                            :
                                                                                        </p>
                                                                                        <Link
                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                            to={
                                                                                                attach.filename
                                                                                            }
                                                                                            target="__blank"
                                                                                        >
                                                                                            {
                                                                                                attach.filename
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                        }
                                    </>
                                )}
                            </div>
                            <div className="w-[440px] sm:w-full md:w-full">
                                <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    <span className="flex">MY KIQS </span>
                                    <span
                                        onClick={() => setDisplayKiqs(!displayKiqs)}
                                        className="font-normal cursor-pointer hover:scale-110"
                                    >
                                        <FaWindowMinimize style={{ color: '#fff' }} />
                                    </span>
                                </p>
                                {!displayKiqs && (
                                    <p className="text-[.6rem] w-full text-center italic">
                                        My Kiqs is Hidden - Response/s Count -{' '}
                                        {itemHolder?.mykiqs_responses.length}
                                    </p>
                                )}
                                {displayKiqs && (
                                    <>
                                        {
                                            // kiqsType == 1 &&
                                            itemHolder?.mykiqs_responses &&
                                                itemHolder?.mykiqs_responses.map(
                                                    (convo, convoIndex) => (
                                                        <div
                                                            key={convoIndex}
                                                            className="animate_fade_up mb-1 w-full text-xs rounded-md bg-gray-200 shadow-sm px-5 py-3 break-words"
                                                        >
                                                            <p
                                                                className="text-sm text-justify break-words"
                                                                style={{
                                                                    whiteSpace: 'pre-line',
                                                                }}
                                                            >
                                                                {convo.answer &&
                                                                    makeUrlsClickable(
                                                                        convo.answer
                                                                    ).map((part, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {part}
                                                                        </React.Fragment>
                                                                    ))}
                                                            </p>
                                                            <div className="w-full">
                                                                {convo.attachments &&
                                                                    convo.attachments.map(
                                                                        (attach) =>
                                                                            attach.type ==
                                                                            'image' ? (
                                                                                <img
                                                                                    className="h-28 aspect-auto object-cover flex w-28 hover:object-contain "
                                                                                    src={
                                                                                        attach.filename
                                                                                    }
                                                                                    alt={attach}
                                                                                />
                                                                            ) : (
                                                                                <div className="h-28 w-full break-all py-5 flex text-xs">
                                                                                    <img
                                                                                        alt="PDF Icon"
                                                                                        className="h-full w-fit"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                    <div className="w-full">
                                                                                        <p className="font-bold text-darkgrey">
                                                                                            Filename
                                                                                            :
                                                                                        </p>
                                                                                        <Link
                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                            to={
                                                                                                attach.filename
                                                                                            }
                                                                                            target="__blank"
                                                                                        >
                                                                                            {
                                                                                                attach.filename
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                        }
                                    </>
                                )}
                            </div>
                            <div className="w-[440px] sm:w-full md:w-full">
                                <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    <span className="flex">SNAPINTEL </span>
                                    <span
                                        onClick={() => setDisplaySnap(!displaySnap)}
                                        className="font-normal cursor-pointer hover:scale-110"
                                    >
                                        <FaWindowMinimize style={{ color: '#fff' }} />
                                    </span>
                                </p>
                                {!displaySnap && (
                                    <p className="text-[.6rem] w-full text-center italic">
                                        SnapIntel is Hidden - Response/s Count -{' '}
                                        {itemHolder?.snapintel_responses.length}
                                    </p>
                                )}
                                {displaySnap && (
                                    <>
                                        {
                                            // kiqsType == 2 &&
                                            itemHolder?.snapintel_responses &&
                                                itemHolder?.snapintel_responses.map(
                                                    (convo, convoIndex) => (
                                                        <div
                                                            key={convoIndex}
                                                            className="animate_fade_up w-full mb-1 text-xs rounded-md bg-gray-200 shadow-sm px-5 py-3 break-words"
                                                        >
                                                            <p
                                                                className="text-sm text-justify break-words"
                                                                style={{
                                                                    whiteSpace: 'pre-line',
                                                                }}
                                                            >
                                                                {convo.answer &&
                                                                    makeUrlsClickable(
                                                                        convo.answer
                                                                    ).map((part, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {part}
                                                                        </React.Fragment>
                                                                    ))}
                                                            </p>
                                                            <div className="w-full">
                                                                {convo.attachments &&
                                                                    convo.attachments.map(
                                                                        (attach) =>
                                                                            attach.type ==
                                                                            'image' ? (
                                                                                <img
                                                                                    className="h-28 aspect-auto object-cover flex w-28 hover:object-contain "
                                                                                    src={
                                                                                        attach.filename
                                                                                    }
                                                                                    alt={attach}
                                                                                />
                                                                            ) : (
                                                                                <div className="h-28 break-all w-full py-5 flex text-xs">
                                                                                    <img
                                                                                        alt="PDF Icon"
                                                                                        className="h-full w-fit"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                    <div className="w-full">
                                                                                        <p className="font-bold text-darkgrey">
                                                                                            Filename
                                                                                            :
                                                                                        </p>
                                                                                        <Link
                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                            to={
                                                                                                attach.filename
                                                                                            }
                                                                                            target="__blank"
                                                                                        >
                                                                                            {
                                                                                                attach.filename
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className=" items-center mb-7 flex flex-col gap-2 p-2 w-[440px] sm:w-full md:w-full ">
                    <div className="w-full text-white rounded-md h-fit bg-main flex items-center justify-between p-2">
                        <p className=" font-semibold text-lg italic">Questions with Most Replies</p>
                        <span
                            onClick={() => setDisplayQuest(!displayQuest)}
                            className="font-normal mb-1 cursor-pointer hover:scale-110"
                        >
                            <FaWindowMinimize style={{ color: '#fff' }} />
                        </span>
                    </div>
                    {!displayQuest && (
                        <p className="text-[.6rem] w-full text-center italic">
                            Questions With Most Replies is Hidden
                        </p>
                    )}
                    {displayQuest && (
                        <>
                            <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                <span className="flex">CHATROOM </span>
                            </p>
                            {dataContainer?.chatroom?.questions &&
                                dataContainer?.chatroom?.questions.map((chat_questions, index) => (
                                    <>
                                        <p className="text-[.7em] transition-all duration-300 px-4 py-2 my- w-full text-white text-left bg-main text-left rounded-md shadow-md  ">
                                            {chat_questions?.question}
                                        </p>
                                        {chat_questions &&
                                            chat_questions?.responses?.length > 0 && (
                                                <div className="flex flex-col w-full px-6 gap-2">
                                                    {chat_questions?.responses.map((response) => (
                                                        <>
                                                            <p className="  w-full self-end text-[.7em] bg-white border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                                <span className="flex gap-2 mb-2 items-start">
                                                                    <p className="text-main font-bold">
                                                                        {response?.user?.fullname}
                                                                    </p>
                                                                </span>
                                                                <p
                                                                    className="text-sm text-justify break-words"
                                                                    style={{
                                                                        whiteSpace: 'pre-line',
                                                                    }}
                                                                >
                                                                    {response.answer &&
                                                                        makeUrlsClickable(
                                                                            response.answer
                                                                        ).map((part, index) => (
                                                                            <React.Fragment
                                                                                key={index}
                                                                            >
                                                                                {part}
                                                                            </React.Fragment>
                                                                        ))}
                                                                </p>

                                                                {response?.attachments &&
                                                                    response?.attachments.length >
                                                                        0 && (
                                                                        <Fragment>
                                                                            {response?.attachments.map(
                                                                                (attachment) => (
                                                                                    <div
                                                                                        className="flex items-start justify-start w-full"
                                                                                        key={
                                                                                            attachment.id
                                                                                        }
                                                                                    >
                                                                                        {attachment.type ===
                                                                                        'image' ? (
                                                                                            <div>
                                                                                                <img
                                                                                                    alt={
                                                                                                        attachment.filename
                                                                                                    }
                                                                                                    src={
                                                                                                        attachment.filename
                                                                                                    }
                                                                                                    className="max-h-60"
                                                                                                />
                                                                                                <Link
                                                                                                    to={
                                                                                                        attachment.filename
                                                                                                    }
                                                                                                    key={
                                                                                                        attachment.id
                                                                                                    }
                                                                                                    target="_blank"
                                                                                                    className="flex mt-2"
                                                                                                >
                                                                                                    Image
                                                                                                    Link
                                                                                                    :
                                                                                                    &nbsp;
                                                                                                    <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                        Click
                                                                                                        to
                                                                                                        view
                                                                                                    </p>
                                                                                                </Link>
                                                                                            </div>
                                                                                        ) : attachment.type ===
                                                                                          'file' ? (
                                                                                            <Link
                                                                                                to={
                                                                                                    attachment.filename
                                                                                                }
                                                                                                target="__blank"
                                                                                                className=" flex flex-col items-start justify-center"
                                                                                            >
                                                                                                PDF
                                                                                                Link
                                                                                                :{' '}
                                                                                                <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold bg-gray-100 p-2 rounded-md">
                                                                                                    <span className="flex items-center gap-2">
                                                                                                        <img
                                                                                                            alt="PDF Display"
                                                                                                            className="h-4 w-4"
                                                                                                            src={
                                                                                                                PDFIcon
                                                                                                            }
                                                                                                        />
                                                                                                        <p className="truncate">
                                                                                                            View
                                                                                                            PDF
                                                                                                        </p>
                                                                                                    </span>
                                                                                                    <span className="text-main ml-4">
                                                                                                        <GoFile
                                                                                                            style={{
                                                                                                                color: '#7cc576',
                                                                                                            }}
                                                                                                        />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </Link>
                                                                                        ) : null}
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </Fragment>
                                                                    )}
                                                            </p>
                                                            {response &&
                                                                response?.responses?.length > 0 && (
                                                                    <>
                                                                        <div className="flex gap-2 self-end justify-end items-center mt-1 w-full mb-1">
                                                                            {' '}
                                                                            <div
                                                                                style={{
                                                                                    color: '#777777',
                                                                                }}
                                                                                className="rotate-90 -z-0 text-left w-fit text-[.8rem] -mt-1"
                                                                            >
                                                                                <MdOutlineSubdirectoryArrowLeft />{' '}
                                                                            </div>
                                                                            <div className="w-fit text-[.7rem]">
                                                                                Replied to
                                                                            </div>
                                                                        </div>{' '}
                                                                        {response?.responses &&
                                                                            response?.responses.map(
                                                                                (
                                                                                    response,
                                                                                    index
                                                                                ) => (
                                                                                    <div className="w-full flex flex-col justify-end items-end">
                                                                                        <p className=" w-[80%] self-end text-[.7em] bg-green-100 border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                                                            <span className="flex gap-2 mb-2 items-center">
                                                                                                <p className="text-main font-bold">
                                                                                                    {
                                                                                                        response
                                                                                                            ?.user
                                                                                                            ?.fullname
                                                                                                    }
                                                                                                </p>
                                                                                            </span>
                                                                                            <p
                                                                                                className="text-sm text-justify break-words"
                                                                                                style={{
                                                                                                    whiteSpace:
                                                                                                        'pre-line',
                                                                                                }}
                                                                                            >
                                                                                                {response.answer &&
                                                                                                    makeUrlsClickable(
                                                                                                        response.answer
                                                                                                    ).map(
                                                                                                        (
                                                                                                            part,
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <React.Fragment
                                                                                                                key={
                                                                                                                    index
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    part
                                                                                                                }
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    )}
                                                                                            </p>

                                                                                            {response?.attachments &&
                                                                                                response
                                                                                                    ?.attachments
                                                                                                    .length >
                                                                                                    0 && (
                                                                                                    <Fragment>
                                                                                                        {response?.attachments.map(
                                                                                                            (
                                                                                                                attachment
                                                                                                            ) => (
                                                                                                                <div
                                                                                                                    className="flex break-all items-start justify-start w-full"
                                                                                                                    key={
                                                                                                                        attachment.id
                                                                                                                    }
                                                                                                                >
                                                                                                                    {attachment.type ===
                                                                                                                    'image' ? (
                                                                                                                        <div>
                                                                                                                            <img
                                                                                                                                alt={
                                                                                                                                    attachment.filename
                                                                                                                                }
                                                                                                                                src={
                                                                                                                                    attachment.filename
                                                                                                                                }
                                                                                                                                className="max-h-60"
                                                                                                                            />
                                                                                                                            <Link
                                                                                                                                to={
                                                                                                                                    attachment.filename
                                                                                                                                }
                                                                                                                                key={
                                                                                                                                    attachment.id
                                                                                                                                }
                                                                                                                                target="_blank"
                                                                                                                                className="flex mt-2"
                                                                                                                            >
                                                                                                                                Image
                                                                                                                                Link
                                                                                                                                :
                                                                                                                                &nbsp;
                                                                                                                                <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                                    Click
                                                                                                                                    to
                                                                                                                                    view
                                                                                                                                </p>
                                                                                                                            </Link>
                                                                                                                        </div>
                                                                                                                    ) : attachment.type ===
                                                                                                                      'file' ? (
                                                                                                                        <Link
                                                                                                                            to={
                                                                                                                                attachment.filename
                                                                                                                            }
                                                                                                                            target="__blank"
                                                                                                                            className=" flex flex-col items-start justify-center"
                                                                                                                        >
                                                                                                                            PDF
                                                                                                                            Link
                                                                                                                            :{' '}
                                                                                                                            <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold bg-gray-100 p-2 rounded-md">
                                                                                                                                <span className="flex items-center gap-2">
                                                                                                                                    <img
                                                                                                                                        alt="PDF Display"
                                                                                                                                        className="h-4 w-4"
                                                                                                                                        src={
                                                                                                                                            PDFIcon
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                    <p className="truncate">
                                                                                                                                        View
                                                                                                                                        PDF
                                                                                                                                    </p>
                                                                                                                                </span>
                                                                                                                                <span className="text-main ml-4">
                                                                                                                                    <GoFile
                                                                                                                                        style={{
                                                                                                                                            color: '#7cc576',
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </Link>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            )
                                                                                                        )}
                                                                                                    </Fragment>
                                                                                                )}
                                                                                        </p>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                    </>
                                                                )}
                                                        </>
                                                    ))}
                                                </div>
                                            )}
                                    </>
                                ))}
                            <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                <span className="flex">MY KIQS </span>
                            </p>
                            {dataContainer?.mykiqs?.questions &&
                                dataContainer?.mykiqs?.questions.map((kiqs_questions, index) => (
                                    <>
                                        <div
                                            className={`hover:scale-105 transition-all duration-150 break-normal bg-main text-sm flex items-center justify-between shadow-md rounded-md w-fit py-2 px-2 cursor-pointer ${
                                                selectedItem == index &&
                                                'bg-main text-white font-semibold'
                                            }`}
                                        >
                                            {kiqs_questions.question}
                                        </div>
                                        {kiqs_questions &&
                                            kiqs_questions?.responses?.length > 0 && (
                                                <div>
                                                    {/* <p> Replies : </p> */}
                                                    <div className="flex flex-col w-full ml-5 px-6 gap-2">
                                                        {kiqs_questions?.responses.map(
                                                            (responses) => (
                                                                <>
                                                                    <div className="border rounded-md pt-2 pl-2">
                                                                        <div className="">
                                                                            {/* <span className=" font-bold">
                                                                                        {
                                                                                            responses
                                                                                                ?.user
                                                                                                ?.fullname
                                                                                        }
                                                                                        -
                                                                                    </span> */}

                                                                            <span>
                                                                                {' '}
                                                                                {
                                                                                    responses.created_at
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className=" flex flex-col w-fit bg-opacity-30 p-2 break-all rounded-l-md rounded-tr-md shadow-sm leading-4 gap-1"
                                                                            key={responses.id}
                                                                        >
                                                                            {/* {responses.answer} */}
                                                                            <p
                                                                                className="text-sm text-justify break-words"
                                                                                style={{
                                                                                    whiteSpace:
                                                                                        'pre-line',
                                                                                }}
                                                                            >
                                                                                {responses.answer &&
                                                                                    makeUrlsClickable(
                                                                                        responses.answer
                                                                                    ).map(
                                                                                        (
                                                                                            part,
                                                                                            index
                                                                                        ) => (
                                                                                            <React.Fragment
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    part
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    )}
                                                                            </p>

                                                                            {responses.attachments &&
                                                                                responses
                                                                                    .attachments
                                                                                    ?.length >
                                                                                    0 && (
                                                                                    <React.Fragment>
                                                                                        {responses.attachments.map(
                                                                                            (
                                                                                                attachment
                                                                                            ) => (
                                                                                                <div
                                                                                                    className="flex "
                                                                                                    key={
                                                                                                        attachment.id
                                                                                                    }
                                                                                                >
                                                                                                    {attachment.type ===
                                                                                                    'image' ? (
                                                                                                        <div>
                                                                                                            <img
                                                                                                                alt={
                                                                                                                    attachment.filename
                                                                                                                }
                                                                                                                src={
                                                                                                                    attachment.filename
                                                                                                                }
                                                                                                                className="max-h-60"
                                                                                                            />
                                                                                                            <Link
                                                                                                                to={
                                                                                                                    attachment.filename
                                                                                                                }
                                                                                                                target="_blank"
                                                                                                                key={
                                                                                                                    attachment.id
                                                                                                                }
                                                                                                                className="font-bold flex"
                                                                                                            >
                                                                                                                Image
                                                                                                                Link
                                                                                                                :{' '}
                                                                                                                <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                    Click
                                                                                                                    to
                                                                                                                    view
                                                                                                                </p>
                                                                                                            </Link>
                                                                                                        </div>
                                                                                                    ) : attachment.type ===
                                                                                                      'file' ? (
                                                                                                        <Link
                                                                                                            to={
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                            target="_blank"
                                                                                                            className="font-bold "
                                                                                                        >
                                                                                                            PDF
                                                                                                            Link
                                                                                                            :{' '}
                                                                                                            <span className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                                                                <span>
                                                                                                                    <FaFile />
                                                                                                                </span>
                                                                                                                Go
                                                                                                                to
                                                                                                                link
                                                                                                            </span>
                                                                                                        </Link>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                    </>
                                ))}
                            <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                <span className="flex">SNAPINTEL </span>
                            </p>
                            {dataContainer?.snapintel?.questions &&
                                dataContainer?.snapintel?.questions.map(
                                    (snapintel_questions, index) => (
                                        <>
                                            <div
                                                className={`hover:scale-105 transition-all duration-150 bg-main break-normal text-sm flex items-center justify-between shadow-md rounded-md w-fit py-2 px-2 cursor-pointer ${
                                                    selectedItem == index &&
                                                    'bg-main text-white font-semibold'
                                                }`}
                                            >
                                                {snapintel_questions.question}
                                            </div>
                                            {snapintel_questions &&
                                                snapintel_questions?.responses?.length > 0 && (
                                                    <div>
                                                        {/* <p> Replies : </p> */}
                                                        <div className="flex flex-col w-full ml-5 px-6 gap-2">
                                                            {snapintel_questions?.responses.map(
                                                                (responses) => (
                                                                    <>
                                                                        <div className="border rounded-md pt-2 pl-2">
                                                                            <div className="">
                                                                                {/* <span className=" font-bold">
                                                                                        {
                                                                                            responses
                                                                                                ?.user
                                                                                                ?.fullname
                                                                                        }
                                                                                        -
                                                                                    </span> */}

                                                                                <span>
                                                                                    {' '}
                                                                                    {
                                                                                        responses.created_at
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                className=" flex flex-col w-full bg-opacity-30 p-2 break-all rounded-l-md rounded-tr-md shadow-sm leading-4 gap-1"
                                                                                key={responses.id}
                                                                            >
                                                                                {/* {responses.answer} */}
                                                                                <p
                                                                                    className="text-sm text-justify break-words"
                                                                                    style={{
                                                                                        whiteSpace:
                                                                                            'pre-line',
                                                                                    }}
                                                                                >
                                                                                    {responses.answer &&
                                                                                        makeUrlsClickable(
                                                                                            responses.answer
                                                                                        ).map(
                                                                                            (
                                                                                                part,
                                                                                                index
                                                                                            ) => (
                                                                                                <React.Fragment
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        part
                                                                                                    }
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        )}
                                                                                </p>

                                                                                {responses.attachments &&
                                                                                    responses
                                                                                        .attachments
                                                                                        ?.length >
                                                                                        0 && (
                                                                                        <React.Fragment>
                                                                                            {responses.attachments.map(
                                                                                                (
                                                                                                    attachment
                                                                                                ) => (
                                                                                                    <div
                                                                                                        className="flex "
                                                                                                        key={
                                                                                                            attachment.id
                                                                                                        }
                                                                                                    >
                                                                                                        {attachment.type ===
                                                                                                        'image' ? (
                                                                                                            <div>
                                                                                                                <img
                                                                                                                    alt={
                                                                                                                        attachment.filename
                                                                                                                    }
                                                                                                                    src={
                                                                                                                        attachment.filename
                                                                                                                    }
                                                                                                                    className="max-h-60"
                                                                                                                />
                                                                                                                <Link
                                                                                                                    to={
                                                                                                                        attachment.filename
                                                                                                                    }
                                                                                                                    target="_blank"
                                                                                                                    key={
                                                                                                                        attachment.id
                                                                                                                    }
                                                                                                                    className="font-bold flex"
                                                                                                                >
                                                                                                                    Image
                                                                                                                    Link
                                                                                                                    :{' '}
                                                                                                                    <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                        Click
                                                                                                                        to
                                                                                                                        view
                                                                                                                    </p>
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                        ) : attachment.type ===
                                                                                                          'file' ? (
                                                                                                            <Link
                                                                                                                to={
                                                                                                                    attachment.filename
                                                                                                                }
                                                                                                                target="_blank"
                                                                                                                className="font-bold "
                                                                                                            >
                                                                                                                PDF
                                                                                                                Link
                                                                                                                :{' '}
                                                                                                                <span className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                                                                    <span>
                                                                                                                        <FaFile />
                                                                                                                    </span>
                                                                                                                    Go
                                                                                                                    to
                                                                                                                    link
                                                                                                                </span>
                                                                                                            </Link>
                                                                                                        ) : null}
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                        </>
                                    )
                                )}
                        </>
                    )}
                </div>
                <div className=" items-center mb-7 flex flex-col gap-2 p-2 w-[440px] sm:w-full md:w-full ">
                    <div className="w-full text-white rounded-md h-fit bg-main flex items-center justify-between p-2">
                        <p className=" font-semibold text-lg italic">
                            {' '}
                            Most Liked and Disliked Responses
                        </p>
                        <span
                            onClick={() => setDisplayReact(!displayReact)}
                            className="font-normal mb-1 cursor-pointer hover:scale-110"
                        >
                            <FaWindowMinimize style={{ color: '#fff' }} />
                        </span>
                    </div>
                    {!displayReact && (
                        <p className="text-[.6rem] w-full text-center italic">
                            Most Liked and Disliked Responses is Hidden
                        </p>
                    )}
                    {displayReact && (
                        <div className="w-full h-5/6  flex gap-2 px-4 pb-5 flex-col">
                            <div className="w-full h-full rounded-md border border-opacity-50 ">
                                <div className="w-full h-fit p-2 flex text-center font-bold items-center justify-center text-main border-b border-opacity-50">
                                    {' '}
                                    <span className="-scale-x-100 ">
                                        <BiSolidLike />{' '}
                                    </span>
                                    Most Likes
                                </div>
                                <div className="w-full h-fit overflow-y-auto p-5 flex flex-col gap-4">
                                    {dataReact?.most_liked &&
                                        dataReact?.most_liked.map((like) => (
                                            <div
                                                className="flex flex-col w-full items-end justify-end"
                                                key={like.id}
                                            >
                                                <span className="text-xs font-bold mb-2">
                                                    {like?.user?.fullname}
                                                </span>
                                                <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-[#e1e1e1] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                    {/* {like.answer} */}
                                                    <p
                                                        className="text-sm text-justify break-words"
                                                        style={{
                                                            whiteSpace: 'pre-line',
                                                        }}
                                                    >
                                                        {like.answer &&
                                                            makeUrlsClickable(like.answer).map(
                                                                (part, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {part}
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </p>
                                                    {like.attachments &&
                                                        like.attachments.length > 0 && (
                                                            <React.Fragment>
                                                                {like.attachments.map(
                                                                    (attachment) => (
                                                                        <div
                                                                            className="flex items-end justify-end w-full"
                                                                            key={attachment.id}
                                                                        >
                                                                            {attachment.type ===
                                                                            'image' ? (
                                                                                <img
                                                                                    className=" transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                                    src={
                                                                                        attachment.filename
                                                                                    }
                                                                                    alt="Attachment"
                                                                                    onClick={() =>
                                                                                        setSelectedImage(
                                                                                            attachment.filename
                                                                                        )
                                                                                    }
                                                                                    // className="h-[150px]"
                                                                                />
                                                                            ) : attachment.type ===
                                                                              'file' ? (
                                                                                <NavLink
                                                                                    to={
                                                                                        attachment.filename
                                                                                    }
                                                                                    target="__blank"
                                                                                    className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                >
                                                                                    <img
                                                                                        className="w-8"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                        alt="TEXT_FILE"
                                                                                    />
                                                                                    <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                                        {
                                                                                            attachment.filename
                                                                                        }
                                                                                    </p>
                                                                                </NavLink>
                                                                            ) : null}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    <div className="w-full flex gap-4 justify-between">
                                                        {like.type === 0 && (
                                                            <div className="flex gap-2">
                                                                <div className="flex">
                                                                    <span className="text-main">
                                                                        <BiSolidLike />
                                                                    </span>
                                                                    <span>{like.likes_count}</span>
                                                                </div>
                                                                <div className="flex">
                                                                    <span className="text-grey">
                                                                        <BiSolidDislike />
                                                                    </span>
                                                                    <span>
                                                                        {like.dislikes_count}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div>
                                                            <span>{like.created_at}</span>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="w-full h-full rounded-md mb-4 border border-opacity-50 ">
                                <div className="w-full h-fit p-2 flex text-center font-bold items-center justify-center text-darkgrey border-b border-opacity-50">
                                    {' '}
                                    <span className="-scale-x-100 ">
                                        <BiSolidDislike />{' '}
                                    </span>
                                    Most Dislikes
                                </div>
                                <div className="w-full h-fit overflow-y-auto p-5 flex flex-col gap-4">
                                    {dataReact?.most_disliked &&
                                        dataReact?.most_disliked.map((dislike) => (
                                            <div
                                                className="flex flex-col w-full items-end justify-end"
                                                key={dislike.id}
                                            >
                                                <span className="text-xs font-bold mb-2">
                                                    {dislike?.user?.fullname}
                                                </span>
                                                <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-[#e1e1e1] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                    {/* {dislike.answer} */}
                                                    <p
                                                        className="text-sm text-justify break-words"
                                                        style={{
                                                            whiteSpace: 'pre-line',
                                                        }}
                                                    >
                                                        {dislike.answer &&
                                                            makeUrlsClickable(dislike.answer).map(
                                                                (part, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {part}
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </p>
                                                    {dislike.attachments &&
                                                        dislike.attachments.length > 0 && (
                                                            <React.Fragment>
                                                                {dislike.attachments.map(
                                                                    (attachment) => (
                                                                        <div
                                                                            className="flex items-end justify-end w-full"
                                                                            key={attachment.id}
                                                                        >
                                                                            {attachment.type ===
                                                                            'image' ? (
                                                                                <img
                                                                                    className=" transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                                    src={
                                                                                        attachment.filename
                                                                                    }
                                                                                    alt="Attachment"
                                                                                    onClick={() =>
                                                                                        setSelectedImage(
                                                                                            attachment.filename
                                                                                        )
                                                                                    }
                                                                                    // className="h-[150px]"
                                                                                />
                                                                            ) : attachment.type ===
                                                                              'file' ? (
                                                                                <NavLink
                                                                                    to={
                                                                                        attachment.filename
                                                                                    }
                                                                                    target="__blank"
                                                                                    className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                >
                                                                                    <img
                                                                                        className="w-8"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                        alt="TEXT_FILE"
                                                                                    />
                                                                                    <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                                        {
                                                                                            attachment.filename
                                                                                        }
                                                                                    </p>
                                                                                </NavLink>
                                                                            ) : null}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    <div className="w-full flex gap-4 justify-between">
                                                        {dislike.type === 0 && (
                                                            <div className="flex gap-2">
                                                                <div className="flex">
                                                                    <span className="text-main">
                                                                        <BiSolidLike />
                                                                    </span>
                                                                    <span>
                                                                        {dislike.likes_count}
                                                                    </span>
                                                                </div>
                                                                <div className="flex">
                                                                    <span className="text-grey">
                                                                        <BiSolidDislike />
                                                                    </span>
                                                                    <span>
                                                                        {dislike.dislikes_count}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div>
                                                            <span>{dislike.created_at}</span>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </PDFExport>
        </div>
    )
}

export default AnalyticsExport
