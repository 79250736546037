import { axiosInstance } from '../../axios'

export const getCompaniesApi = async (id) => {
    const response = await axiosInstance.post(process.env.REACT_APP_DASHBOARD_COMPANIES_LIST, {
        id: id,
    })

    return response.data
}

export const getCompaniesQuestionApi = async (id) => {
    const response = await axiosInstance.post(
        process.env.REACT_APP_DASHBOARD_COMPANIES_QUESTION_LIST,
        { event_id: id }
    )

    return response.data
}

export const getCompaniesResponsesApi = async (questionId, eventId, type_num) => {
    const response = await axiosInstance.post(
        process.env.REACT_APP_DASHBOARD_COMPANIES_RESPONSES_LIST,
        { question_id: questionId, event_id: eventId, type: type_num }
    )

    return response.data
}

export const getPDFCompaniesApi = async (data) => {
    const response = await axiosInstance.post('/api/v1/events/convo_export', {
        event_id: data.event.event_id,
        type: data.type,
    })
    return response.data
}

export const getAllPDFCompaniesApi = async (data) => {
    const response = await axiosInstance.post('api/v1/companies/get_event_responses', {
        event_id: data.event_id,
    })
    return response.data
}
