import { useAtomValue } from 'jotai'
import { Navigate, Outlet } from 'react-router-dom'
import { authAtom } from 'store/authAtom'

export const Container = () => {
    const auth = useAtomValue(authAtom)
    if (!auth) {
        return <Outlet />
    }
    return <Navigate to={'/dashboard'} />
}
