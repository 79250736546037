import React, { useEffect, useState } from 'react'
import Logo from '../assets/NexusBrain.png'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { loginSchema } from 'services/request/Auth/schema'
import { useAtomValue } from 'jotai'
import { authAtom } from 'store/authAtom'
import { useNavigate } from 'react-router-dom'
function Login() {
    const auth = useAtomValue(authAtom)
    const navigate = useNavigate()
    const token = localStorage.getItem('token') ?? ''

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(loginSchema),
        mode: 'onBlur',
    })

    useEffect(() => {
        const params = {
            client_id: process.env.REACT_APP_LOCAL_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_LOCAL_REDIRECT_URL + '/oauth/callback',
            response_type: 'code',
        }

        if (!token) {
            const navigateToParams = new URLSearchParams(params)
            window.location.replace(
                `${
                    process.env.REACT_APP_LOCAL_AUTH_SERVER
                }/oauth/authorize?${navigateToParams.toString()}`
            )
            return
        }

        return navigate('/dashboard')
    }, [auth])

    const LoaderAuthenticate = () => {}

    return (
        <>
            {/* <div className="w-screen h-screen">
                <div className="opacity-50 fixed w-full h-full  filter bg-dashboard bg-[length:600px_300px] bg-center -z-10 "></div>
                <div className="w-full h-full flex justify-center items-center ">
                    <div className="w-2/3 h-2/3 flex  bg-white shadow-lg bg-opacity-40 backdrop-blur-[1px] rounded-md py-2 items-center sm:flex-col sm:justify-center">
                        <div className="w-1/2  flex-col justify-center items-center flex pt-16 gap-12 sm:pt-0 sm:gap-0 sm:pb-2">
                            <img className="w-28 sm:p-2 sm:py-4 " src={Logo} alt="Logo" />
                            <div className="text-center ">
                                <h1 className="text-2xl text-[#333] sm:text-lg ">Welcome to</h1>
                                <h1 className="text-4xl font-bold  text-[#333] sm:text-xl">
                                    NEXUSBRAiN
                                </h1>
                            </div>
                        </div>
                        <div className="w-1/2 flex flex-col justify-center items-center border-l-[2px] border-[333] sm:border-l-0 sm:border-t-[2px] sm:w-4/5 ">
                            <div className="flex w-full flex-col justify-center items-center gap-2 pt-8 sm:-space-y-12 sm:pt-0">
                                <div className="text-left text-[#333] font-bold w-2/3 text-4xl py-2 sm:text-center sm:text-[22px] sm:invisible">
                                    Login
                                </div>
                                {LoaderAuthenticate()}
                                <form
                                    className="w-full h-full flex flex-col justify-center items-center gap-2 "
                                    // 2
                                >
                                    <Controller
                                        control={control}
                                        name="email"
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputField
                                                    onChange={onChange}
                                                    inputContainerClassName="w-2/3 sm:w-full"
                                                    name={name}
                                                    value={value}
                                                    label={'Email Address'}
                                                    placeholder="mail@email.com"
                                                    inputClassName="px-2 py-2 outline-main    rounded-md w-full"
                                                    error={error?.message}
                                                />
                                            )
                                        }}
                                    />

                                    <Controller
                                        control={control}
                                        name="password"
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputField
                                                    onChange={onChange}
                                                    inputContainerClassName="w-2/3 sm:w-full"
                                                    error={error?.message}
                                                    name={name}
                                                    value={value}
                                                    placeholder="********"
                                                    label={'Password'}
                                                    inputClassName="px-2 py-2 outline-main  rounded-md w-full "
                                                    type="password"
                                                />
                                            )
                                        }}
                                    />
                                    <div className="w-full flex justify-center items-center pt-4 sm:pt-2">
                                        <input
                                            type="submit"
                                            value="Sign in"
                                            className="px-2 tracking-wide py-2 cursor-pointer text-sm border-main border-[2px] bg-main text-white rounded-full w-2/3 outline-white"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Login
