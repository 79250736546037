import React, { useState } from 'react'
import logo from '../assets/NexusBrain.png'
import { BiLogOut } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import { logoutUser } from 'services/request/Auth'
import { useAtomValue } from 'jotai'
import { authAtom } from 'store/authAtom'
import { twMerge } from 'tailwind-merge'
import { FaUser } from 'react-icons/fa'
import { extractNameInitials } from 'utlilites/getInitials'
import { FaArrowRight } from 'react-icons/fa6'
import { CiLogout } from 'react-icons/ci'

function Header({ children }) {
    const auth = useAtomValue(authAtom)
    const [open, setOpen] = useState(false)

    const toggleDropdown = () => {
        setOpen(!open)
    }

    const handleLinkClick = (title) => {
        document.title = title
    }

    return (
        <div className="w-screen min-h-screen h-fit z-40">
            <div className="min-w-[100%] w-fit h-[50px] sticky top-0 rounded-b-lg shadow-md flex justify-between bg-main items-center  text-white z-40">
                {/* <div className='w-1/3 pl-7'>Logo</div> */}
                <div className="flex h-full justify-center items-center">
                    <NavLink
                        to="/dashboard"
                        onClick={() => handleLinkClick('NexusBrain Dashboard')}
                    >
                        <div className="flex px-4 items-center">
                            {/* <div className="w-fit h-full"> */}
                            <img
                                className="sm:hidden h-8 w-8 aspect-square brightness-200"
                                src={logo}
                                alt="NexusBrain"
                            />
                            {/* </div> */}
                            <div className="font-bold text-white ml-2 ">
                                <p>NexusBrain</p>
                                <p className="text-[.5rem] -mt-2 md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:hidden">
                                    Go To Dashboard
                                </p>
                            </div>
                        </div>
                    </NavLink>

                    <NavLink
                        className="sm:hidden"
                        to="/dashboard"
                        onClick={() => handleLinkClick('NexusBrain Dashboard')}
                    >
                        {({ isActive }) => (
                            <div
                                className={` font-semibold flex px-4 items-center ${
                                    isActive && 'underline'
                                } `}
                            >
                                <p className="flex gap-2 h-full justify-center items-center text-sm hover:underline-offset-1 hover:underline cursor-pointer sm:pl-4">
                                    Dashboard
                                </p>
                            </div>
                        )}
                    </NavLink>
                </div>

                <div className="w-2/3 flex justify-end items-center gap-5 pr-[1%] ">
                    <button
                        className="font-bold sm:text-[.7rem] leading-3 px-5 py-2  p-2 text-sm text-white flex items-center justify-center "
                        onClick={() =>
                            window.location.replace(process.env.REACT_APP_NEXUSBRAIN_WEB_URL)
                        }
                    >
                        <p className="leading-3">Go to NexusBrain Console</p>
                        <FaArrowRight className="text-[13px] ml-1" />
                    </button>
                    <div className="relative">
                        <div className="flex items-center  overflow-hidden rounded-full bg-white">
                            <button
                                onClick={() => toggleDropdown()}
                                className=" text-[#333] p-3 w-[35px] h-[35px] flex justify-center items-center font-bold  rounded-full capitalize"
                            >
                                {extractNameInitials(
                                    `${auth?.user?.firstname} ${
                                        auth?.user?.lastname === null ? '' : auth?.user?.lastname
                                    }`
                                )}
                            </button>
                        </div>

                        <div
                            className={twMerge(
                                'bg-white overflow-hidden rounded-sm  shadow-lg text-black absolute right-0 mt-1 text-lg',
                                open ? 'block' : 'hidden'
                            )}
                        >
                            <ul className=" capitalize">
                                <div className="flex  items-center p-4">
                                    <div className="p-3">
                                        <FaUser className="text-main" />
                                    </div>
                                    <div className="leading-none">
                                        <li className="font-bold p-2  truncate  w-[200px]">
                                            {`${auth?.user?.firstname}`}
                                        </li>
                                        <hr />
                                        <li className="text-[12px] px-2 py-1 text-main  capitalize">
                                            {auth?.user?.role}
                                        </li>
                                    </div>
                                </div>

                                <li className="">
                                    <button
                                        className="font-bold w-full  border-r px-5 py-2 border-main  bg-main p-2 text-white text-sm "
                                        onClick={() => logoutUser()}
                                    >
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="flex items-center "></div>
                </div>
            </div>
            {children}
        </div>
    )
}

export default Header
