import React, { useState } from 'react'
import Header from './Header'
import { FaFileExport } from 'react-icons/fa'
import { TbLayoutKanban } from 'react-icons/tb'

import { TextInput } from '@tremor/react'

import CompanyList from '../components/data/companies.json'
import Chatroom from './ChatSelection/QuestionChatroom'
import Kiqs from './ChatSelection/CompaniesChat'
import SnapIntel from './ChatSelection/CompaniesGeneralDiscussion'
import { Link } from 'react-router-dom'

const SelectedCard = ({ state }) => {
    const [tab, setTab] = useState(0)
    const [chatSelection, setChatSelections] = useState(null)
    const selectedObject = state

    return (
        <div className=" flex flex-col w-screen h-screen overflow-x-hidden">
            <span className="">
                {' '}
                <Header />
            </span>
            <div className="opacity-50 fixed w-full h-full bg-dashboard bg-[length:600px_300px] bg-center -z-10 brightness-[.8]"></div>
            <div className="w-full flex gap-5 p-5 md:flex-col sm:flex-col">
                <div className="w-1/2 h-full rounded-lg custom-scroll sm:w-full md:w-full sm:mb-10 md:mb-10">
                    <div className="h-fit w-full flex gap-2 md:flex-col sm:flex-col mb-5">
                        <div className="flex w-full gap-2 ">
                            <Link
                                to="/projects"
                                target="_blank"
                                className="transition-all duration-300 w-1/2 md:w-1/2 sm:w-1/2 hover:w-[58%] bg-projects bg-no-repeat bg-right bg-contain bg-main h-[70px] rounded-lg shadow-md font-semibold text-white text-left pl-5 flex flex-col leading- justify-center"
                            >
                                <button className="flex flex-col">
                                    <span>Projects</span>
                                    <span className="text-gray-700 font-bold text-2xl leading-4">
                                        202
                                    </span>
                                </button>
                            </Link>
                            <Link
                                to="/products"
                                target="_blank"
                                className="transition-all duration-300 w-1/2 md:w-1/2 sm:w-1/2 hover:w-[58%] bg-products bg-no-repeat bg-right bg-contain bg-main h-[70px] rounded-lg shadow-md font-semibold text-white text-left pl-5 flex flex-col leading- justify-center"
                            >
                                <button className="flex flex-col">
                                    <span>Products</span>
                                    <span className="text-gray-700 font-bold text-2xl leading-4">
                                        32
                                    </span>
                                </button>
                            </Link>
                        </div>
                        <div className="flex w-full gap-2">
                            <Link
                                to="/intel_count"
                                target="_blank"
                                className="transition-all duration-300 w-1/2 md:w-1/2 sm:w-1/2 hover:w-[58%] bg-intelcount bg-no-repeat bg-right bg-contain bg-main h-[70px] rounded-lg shadow-md font-semibold text-white text-left pl-5 flex flex-col leading- justify-center"
                            >
                                <button className="flex flex-col">
                                    <span>Intel Count</span>
                                    <span className="text-gray-700 font-bold text-2xl leading-4">
                                        105
                                    </span>
                                </button>
                            </Link>
                            <Link
                                to="/analysts"
                                target="_blank"
                                className="transition-all duration-300 w-1/2 md:w-1/2 sm:w-1/2 hover:w-[58%] bg-analysts bg-no-repeat bg-right bg-contain bg-main h-[70px] rounded-lg shadow-md font-semibold text-white text-left pl-5 flex flex-col leading- justify-center"
                            >
                                <button className="flex flex-col">
                                    <span>Analysts</span>
                                    <span className="text-gray-700 font-bold text-2xl leading-4">
                                        423
                                    </span>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="h-fit w-full flex justify-end items-center gap-2 mb-5">
                        <button className="bg-main scale-100 p-2 rounded-md text-white flex gap-2">
                            <p className="text-xs">Export | </p>
                            <FaFileExport />
                        </button>
                        <button className="bg-main scale-100 p-2 rounded-md text-white flex gap-2">
                            <p className="text-xs">View | </p>
                            <TbLayoutKanban />
                        </button>
                        <input
                            type="text"
                            className="h-12 rounded-sm w-1/3 text-sm px-2"
                            placeholder="Search"
                        />
                    </div>

                    <div className=" h-fit pt-5 px-3 w-full rounded-md ">
                        <div className="flex text-xs">
                            <div className="w-full flex justify-between">
                                <button
                                    onClick={() => setChatSelections(null)}
                                    className={
                                        tab === 5
                                            ? 'whitespace-nowrap transition-all duration-500 bg-main text-white w-1/6 shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                            : 'whitespace-nowrap bg-white w-fit shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                    }
                                >
                                    Select a company
                                </button>
                                <div>
                                    <button
                                        onClick={() => setChatSelections(0)}
                                        // disabled={chatSelection === null && true}
                                        className={
                                            tab === 5
                                                ? 'whitespace-nowrap transition-all duration-500 bg-main text-white w-1/6 shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                                : 'whitespace-nowrap bg-white w-fit min-w-[80px] shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                        }
                                    >
                                        Chatroom KIQs
                                    </button>
                                    <button
                                        onClick={() => setChatSelections(1)}
                                        // disabled={chatSelection === null && true}
                                        className={
                                            tab === 5
                                                ? 'whitespace-nowrap transition-all duration-500 bg-main text-white w-1/6 shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                                : 'whitespace-nowrap bg-white w-fit min-w-[80px] shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                        }
                                    >
                                        My KIQs
                                    </button>
                                    <button
                                        onClick={() => setChatSelections(2)}
                                        // disabled={chatSelection === null && true}
                                        className={
                                            tab === 5
                                                ? 'whitespace-nowrap transition-all duration-500 bg-main text-white w-1/6 shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                                : 'whitespace-nowrap bg-white w-fit min-w-[80px] shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                        }
                                    >
                                        SnapIntel
                                    </button>
                                </div>
                                <button
                                    onClick={() => setChatSelections(null)}
                                    className="bg-white w-1/6 shadow-sm px-2 rounded-tl-md rounded-tr-xl"
                                >
                                    Go back
                                </button>
                            </div>
                        </div>
                        <div className="w-full flex bg-white justify-center items-center px-5 py-4">
                            <p className="whitespace-nowrap text-main text-md h-full font-bold">
                                EVENT Name
                            </p>
                            <div className="w-full justify-end flex items-end pt-2 pr-2">
                                <div className="w-1/3 h-[80ppx]">
                                    <TextInput placeholder="Search..." />
                                </div>
                            </div>
                        </div>
                        <div className="w-full min-h-[200px] h-fit flex flex-col ">
                            {chatSelection === 0 ? (
                                <div className="w-full h-full flex grow">
                                    <Chatroom />
                                </div>
                            ) : chatSelection === 1 ? (
                                <div className="w-full h-full flex grow">
                                    <Kiqs />
                                </div>
                            ) : chatSelection === 2 ? (
                                <div className="w-full h-full flex grow">
                                    <SnapIntel />
                                </div>
                            ) : (
                                <>
                                    <div className="w-full bg-main text-white text-center font-semibold py-2">
                                        Companies
                                    </div>
                                    <div className="h-[350px] max-h-[50vh] w-full flex overflow-y-auto rounded-b-md justify-evenly p-5 gap-3 bg-gray-200 inner-shadow flex-wrap">
                                        {CompanyList &&
                                            CompanyList.map((company) => (
                                                <button className="transition-all duration-300 w-[150px]  bg-company bg-no-repeat bg-right bg-cover bg-main h-[70px] rounded-lg shadow-md font-semibold text-left pl-5 flex flex-col justify-center text-xs">
                                                    {company.company}
                                                </button>
                                            ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="w-1/2 min-h-[91%] h-fit rounded-lg  shadow-md md:w-full sm:w-full">
                    <div className="flex text-xs">
                        <div className="w-full">
                            <button
                                onClick={() => setTab(0)}
                                className={
                                    tab === 0
                                        ? ' transition-all duration-500 bg-main text-white w-1/6 shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                        : 'bg-white w-1/6 shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                }
                            >
                                General
                            </button>
                            <button
                                onClick={() => setTab(1)}
                                className={
                                    tab === 1
                                        ? ' transition-all duration-500 bg-main text-white w-1/6 shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                        : 'bg-white w-1/6 shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                }
                            >
                                Map
                            </button>
                            <button
                                onClick={() => setTab(2)}
                                className={
                                    tab === 2
                                        ? ' transition-all duration-500 bg-main text-white w-1/6 shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                        : 'bg-white w-1/6 shadow-sm px-2 py-1 rounded-tl-md rounded-tr-xl'
                                }
                            >
                                Chart
                            </button>
                        </div>
                        <button className="bg-white w-1/6 shadow-sm px-2 rounded-tl-md rounded-tr-xl">
                            View All
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectedCard
