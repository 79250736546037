import { LoadingComponent } from 'components/LoadingComponent'
import React, { Fragment } from 'react'

const EventQuestionsComponent = ({
    selectedEvent,
    kiqsSelected,
    arrayRes,
    eventQuestions,
    handleQuestionSelect,
}) => {
    if (kiqsSelected === 'General Discussion') {
        return null
    }
    return (
        <div className="w-1/4 bg-white min-h-full sm:w-full h-[100%] overflow-x-hidden overflow-y-auto">
            <div className="w-full justify-between text-sm flex flex-col px-5 pt-2 pb-2 border-b border-main rounded-md overflow-hidden">
                <p className="w-full font-semibold text-main">
                    {selectedEvent[0] === 0 ? (
                        'Select an Event '
                    ) : (
                        <div>
                            <p>Questions of </p>
                            <span className="font-bold">{selectedEvent.event_name}</span>
                        </div>
                    )}
                </p>
                <p className="w-full font-semibold text-darkgrey">{kiqsSelected}</p>
            </div>
            {arrayRes[1].isLoading ? (
                <span className="text-xs">
                    <LoadingComponent title={'Questions'} />
                </span>
            ) : (
                <div className="min-w-[220px] md:h-fit sm:h-fit">
                    <Fragment>
                        {kiqsSelected === 'Chatroom' ? (
                            eventQuestions?.chatroom?.length == 0 ||
                            eventQuestions?.chatroom == null ? (
                                <span className="w-full font-bold text-main flex justify-center items-center text-center">
                                    No Record
                                </span>
                            ) : (
                                eventQuestions?.chatroom &&
                                eventQuestions?.chatroom.map((question, questionIndex) => (
                                    <li
                                        tabIndex="0"
                                        onClick={() => handleQuestionSelect(question)}
                                        className="hover:bg-main focus:bg-main hover:text-white cursor-pointer text-xs border-b p-2 list-decimal"
                                        key={questionIndex}
                                    >
                                        {question.question}
                                    </li>
                                ))
                            )
                        ) : kiqsSelected === 'My KIQS' ? (
                            <Fragment>
                                {eventQuestions?.mykiqs?.length == 0 ||
                                eventQuestions?.mykiqs == null ? (
                                    <span className="w-full font-bold text-main flex justify-center items-center text-center">
                                        No Record
                                    </span>
                                ) : (
                                    eventQuestions?.mykiqs &&
                                    eventQuestions?.mykiqs.map((question, questionIndex) => (
                                        <li
                                            tabIndex="1"
                                            onClick={() => handleQuestionSelect(question)}
                                            className="hover:bg-main focus:bg-main hover:text-white cursor-pointer text-xs border-b p-2 list-decimal"
                                            key={questionIndex}
                                        >
                                            {question.question}
                                        </li>
                                    ))
                                )}
                            </Fragment>
                        ) : kiqsSelected === 'SnapIntel' ? (
                            <Fragment>
                                {eventQuestions?.snapintel?.length == 0 ||
                                eventQuestions?.snapintel == null ? (
                                    <span className="w-full font-bold text-main flex justify-center items-center text-center">
                                        No Record
                                    </span>
                                ) : (
                                    eventQuestions?.snapintel &&
                                    eventQuestions?.snapintel
                                        .slice(0, 7)
                                        .map((question, questionIndex) => (
                                            <li
                                                tabIndex="1"
                                                onClick={() => handleQuestionSelect(question)}
                                                className="hover:bg-main focus:bg-main hover:text-white cursor-pointer text-xs border-b p-2 list-decimal"
                                                key={questionIndex}
                                            >
                                                {question.question}
                                            </li>
                                        ))
                                )}
                            </Fragment>
                        ) : null}
                    </Fragment>
                </div>
            )}
        </div>
    )
}

export default EventQuestionsComponent
