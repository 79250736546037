import React from 'react'
import NoDataImg from '../../assets/nodata.jpg'

export const NoData = () => {
    return (
        <div className="w-full h-fit flex flex-col justify-center text-center items-center py-10">
            <img className="h-full max-h-[150px] " src={NoDataImg} alt="No Record" />

            <div>
                <p className="text-2xl text-main font-bold">No Record</p>
            </div>
        </div>
    )
}
