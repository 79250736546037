import React, { Fragment, useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaWindowMinimize } from 'react-icons/fa'
import PDFIcon from '../../assets/pdf.png'
import { Link } from 'react-router-dom'
import { DownloadProgress } from 'components/DownloadingComponent'
import ImageView from 'components/ImageView'

export const UserMostExport = ({ data, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])

    const [selectedImage, setSelectedImage] = useState()
    const [kiqsType, setKiqsType] = useState(0)
    const [itemHolder, setItemHolder] = useState(data[0] ?? [])
    const [displayChat, setDisplayChat] = useState(true)
    const [displayKiqs, setDisplayKiqs] = useState(true)
    const [displaySnap, setDisplaySnap] = useState(true)

    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    return (
        <div className=" top-0 bottom-0  right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex items-center justify-between ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                        >
                            Download PDF
                        </button>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport
                // fileName={`Export Spaces and Events - ${data?.event_name}`}
                ref={pdfExportComponent}
                paperSize="A4"
            >
                {selectedImage != null && (
                    <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
                )}

                <div className="w-full items-center mb-7 flex flex-col gap-2 p-2 ">
                    <div className="w-full h-fit flex items-center justify-center pt-4">
                        <p className="text-main font-semibold text-lg italic">
                            Users with Most Response
                        </p>
                    </div>
                    <div className="w-full items-center justify-center flex flex-col">
                        <div className="h-fit overflow-y-auto overflow-x-hidden items-center w-full  px-5 py-2 flex flex-col gap-2 ">
                            {data &&
                                data.map((item, itemIndex) => (
                                    <div
                                        onClick={() => setItemHolder(item)}
                                        className="animate_fade_up text-sm flex items-center justify-between shadow-md rounded-md w-[80%] py-2 px-2 bg-[#e7e7e7] hover:bg-[#cfcfcf] cursor-pointer"
                                    >
                                        <span className="w-2/3 overflow-x-auto">
                                            <p
                                                className={`${
                                                    item?.fullname === itemHolder?.fullname &&
                                                    'font-semibold underline'
                                                } text-sm`}
                                            >
                                                {item.fullname}
                                            </p>
                                            <p className="text-xs font-semibold text-main">
                                                {item.companybelong.firstname}
                                            </p>
                                        </span>
                                        <span className="w-fit px-5 flex justify-end border-l border-main ">
                                            <p>{item?.responses_count}</p>
                                        </span>
                                    </div>
                                ))}
                        </div>

                        <div className="w-[440px] sm:w-full md:w-full">
                            <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                <span className="flex">CHATROOM </span>
                                <span
                                    onClick={() => setDisplayChat(!displayChat)}
                                    className="font-normal cursor-pointer hover:scale-110"
                                >
                                    <FaWindowMinimize style={{ color: '#fff' }} />
                                </span>
                            </p>
                            {!displayChat && (
                                <p className="text-[.6rem] w-full text-center italic">
                                    Chatroom is Hidden - Response/s Count -{' '}
                                    {itemHolder?.chatroom_responses.length}
                                </p>
                            )}
                            {displayChat && (
                                <>
                                    {
                                        // kiqsType == 0 &&
                                        itemHolder?.chatroom_responses &&
                                            itemHolder?.chatroom_responses.map(
                                                (convo, convoIndex) => (
                                                    <div className="animate_fade_up w-full text-xs rounded-md bg-gray-200 mb-1 shadow-sm px-5 py-3 break-words">
                                                        <p
                                                            className="text-sm text-justify break-words"
                                                            style={{
                                                                whiteSpace: 'pre-line',
                                                            }}
                                                        >
                                                            {convo.answer &&
                                                                makeUrlsClickable(convo.answer).map(
                                                                    (part, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {part}
                                                                        </React.Fragment>
                                                                    )
                                                                )}
                                                        </p>
                                                        <div className="w-full">
                                                            {convo.attachments &&
                                                                convo.attachments.map((attach) =>
                                                                    attach.type == 'image' ? (
                                                                        <img
                                                                            className="h-28 aspect-auto object-cover flex w-28 hover:object-contain "
                                                                            src={attach.filename}
                                                                            alt={attach}
                                                                        />
                                                                    ) : (
                                                                        <div className="h-28 w-full py-5 flex break-all text-xs">
                                                                            <img
                                                                                alt="PDF Icon"
                                                                                className="h-full w-fit"
                                                                                src={PDFIcon}
                                                                            />
                                                                            <div className="w-full">
                                                                                <p className="font-bold text-darkgrey">
                                                                                    Filename :
                                                                                </p>
                                                                                <Link
                                                                                    className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                    to={
                                                                                        attach.filename
                                                                                    }
                                                                                    target="__blank"
                                                                                >
                                                                                    {
                                                                                        attach.filename
                                                                                    }
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                )
                                            )
                                    }
                                </>
                            )}
                        </div>
                        <div className="w-[440px] sm:w-full md:w-full">
                            <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                <span className="flex">MY KIQS </span>
                                <span
                                    onClick={() => setDisplayKiqs(!displayKiqs)}
                                    className="font-normal cursor-pointer hover:scale-110"
                                >
                                    <FaWindowMinimize style={{ color: '#fff' }} />
                                </span>
                            </p>
                            {!displayKiqs && (
                                <p className="text-[.6rem] w-full text-center italic">
                                    My Kiqs is Hidden - Response/s Count -{' '}
                                    {itemHolder?.mykiqs_responses.length}
                                </p>
                            )}
                            {displayKiqs && (
                                <>
                                    {
                                        // kiqsType == 1 &&
                                        itemHolder?.mykiqs_responses &&
                                            itemHolder?.mykiqs_responses.map(
                                                (convo, convoIndex) => (
                                                    <div
                                                        key={convoIndex}
                                                        className="animate_fade_up mb-1 w-full text-xs rounded-md bg-gray-200 shadow-sm px-5 py-3 break-words"
                                                    >
                                                        <p
                                                            className="text-sm text-justify break-words"
                                                            style={{
                                                                whiteSpace: 'pre-line',
                                                            }}
                                                        >
                                                            {convo.answer &&
                                                                makeUrlsClickable(convo.answer).map(
                                                                    (part, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {part}
                                                                        </React.Fragment>
                                                                    )
                                                                )}
                                                        </p>
                                                        <div className="w-full">
                                                            {convo.attachments &&
                                                                convo.attachments.map((attach) =>
                                                                    attach.type == 'image' ? (
                                                                        <img
                                                                            className="h-28 aspect-auto object-cover flex w-28 hover:object-contain "
                                                                            src={attach.filename}
                                                                            alt={attach}
                                                                        />
                                                                    ) : (
                                                                        <div className="h-28 w-full break-all py-5 flex text-xs">
                                                                            <img
                                                                                alt="PDF Icon"
                                                                                className="h-full w-fit"
                                                                                src={PDFIcon}
                                                                            />
                                                                            <div className="w-full">
                                                                                <p className="font-bold text-darkgrey">
                                                                                    Filename :
                                                                                </p>
                                                                                <Link
                                                                                    className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                    to={
                                                                                        attach.filename
                                                                                    }
                                                                                    target="__blank"
                                                                                >
                                                                                    {
                                                                                        attach.filename
                                                                                    }
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                )
                                            )
                                    }
                                </>
                            )}
                        </div>
                        <div className="w-[440px] sm:w-full md:w-full">
                            <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                <span className="flex">SNAPINTEL </span>
                                <span
                                    onClick={() => setDisplaySnap(!displaySnap)}
                                    className="font-normal cursor-pointer hover:scale-110"
                                >
                                    <FaWindowMinimize style={{ color: '#fff' }} />
                                </span>
                            </p>
                            {!displaySnap && (
                                <p className="text-[.6rem] w-full text-center italic">
                                    SnapIntel is Hidden - Response/s Count -{' '}
                                    {itemHolder?.snapintel_responses.length}
                                </p>
                            )}
                            {displaySnap && (
                                <>
                                    {
                                        // kiqsType == 2 &&
                                        itemHolder?.snapintel_responses &&
                                            itemHolder?.snapintel_responses.map(
                                                (convo, convoIndex) => (
                                                    <div
                                                        key={convoIndex}
                                                        className="animate_fade_up w-full mb-1 text-xs rounded-md bg-gray-200 shadow-sm px-5 py-3 break-words"
                                                    >
                                                        <p
                                                            className="text-sm text-justify break-words"
                                                            style={{
                                                                whiteSpace: 'pre-line',
                                                            }}
                                                        >
                                                            {convo.answer &&
                                                                makeUrlsClickable(convo.answer).map(
                                                                    (part, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {part}
                                                                        </React.Fragment>
                                                                    )
                                                                )}
                                                        </p>
                                                        <div className="w-full">
                                                            {convo.attachments &&
                                                                convo.attachments.map((attach) =>
                                                                    attach.type == 'image' ? (
                                                                        <img
                                                                            className="h-28 aspect-auto object-cover flex w-28 hover:object-contain "
                                                                            src={attach.filename}
                                                                            alt={attach}
                                                                        />
                                                                    ) : (
                                                                        <div className="h-28 break-all w-full py-5 flex text-xs">
                                                                            <img
                                                                                alt="PDF Icon"
                                                                                className="h-full w-fit"
                                                                                src={PDFIcon}
                                                                            />
                                                                            <div className="w-full">
                                                                                <p className="font-bold text-darkgrey">
                                                                                    Filename :
                                                                                </p>
                                                                                <Link
                                                                                    className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                    to={
                                                                                        attach.filename
                                                                                    }
                                                                                    target="__blank"
                                                                                >
                                                                                    {
                                                                                        attach.filename
                                                                                    }
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                )
                                            )
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

// MainEventsExport.propTypes = {
//     isLoading: PropTypes.bool.isRequired,
//     pieChartData: PropTypes.any,
//     chartTitle: PropTypes.string.isRequired,
//     noRecordMessage: PropTypes.string.isRequired,
//     chartType: PropTypes.string.isRequired,
//     chartData: PropTypes.array.isRequired,
//     chartOptions: PropTypes.object.isRequired,
//     handleModal: PropTypes.func.isRequired,
// }

export default UserMostExport
